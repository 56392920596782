import React, { useContext } from 'react';
import { Channel } from 'stream-chat-react';

import { channelConfiguration } from '../../util';
import ChatFeedView from '../ChatFeedView';
import ChatFeedContainer from '../ChatFeedContainer';
import ChatEmptyView from '../ChatEmptyView';
import ChatContext from '../../../../context/ChatContext';

import CustomTriggerProvider from '../CustomTriggerProvider';
import MultipleChannelList from './MultipleChannelList';
import { MultipleChannelViewContainer } from './styles';

const MultipleChannelView = () => {
  const { onMarkChannelAsRead } = useContext(ChatContext);

  return (
    <MultipleChannelViewContainer>
      <MultipleChannelList />
      <ChatFeedContainer>
        <Channel
          EmptyPlaceholder={<ChatEmptyView />}
          maxNumberOfFiles={channelConfiguration.maxFileUploadLimit}
          multipleUploads
          doMarkReadRequest={onMarkChannelAsRead}
          TriggerProvider={CustomTriggerProvider}
        >
          <ChatFeedView />
        </Channel>
      </ChatFeedContainer>
    </MultipleChannelViewContainer>
  );
};

export default MultipleChannelView;
