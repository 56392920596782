import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import format from 'string-template';

import {
  Card,
  HeaderContainer,
  SubTitle,
  Title,
  TitleContainer,
  Container,
} from '../../../../../../../components/v2/Card';
import Product from '../../../../../../../Model/Product';
import Table from '../../../../../../../components/v2/Table';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import { formatCurrencyCents } from '../../../../../../../utils/formatters';
import AddPackageModal from '../AddPackageModal';
import { StyledAddIcon } from '../AddPackageModal/styles';
import { PrimaryButton } from '../../../../../../../components/Button/ActionButtons';
import ConfirmationModal from '../../../../../../../components/v2/ConfirmationModal';
import LoadingOverlay from '../../../../../../components/LoadingOverlay';
import useToast from '../../../../../../hooks/useToast';
import {
  ArchivedChip,
  ButtonContainer,
  StyledArchiveIcon,
  StyledButton,
  StyledChip,
  TitleRow,
} from './styles';
import texts from './texts.json';

const ProductView = ({ product, showArchived }) => {
  const [showAddPackageModal, setShowAddPackageModal] = useState(false);
  const [packageCollection, setPackageCollection] = useState({ docs: [] });
  const [isLoading, setIsLoading] = useState(true);
  const isComponentMountedRef = useComponentMounted();
  const [actionItem, setActionItem] = useState(null); // { item: Product | Package, isProduct: boolean}
  const { showToast } = useToast();

  useEffect(() => {
    const fetchPackages = async () => {
      setIsLoading(true);
      const packageCol = await product.getPackages();
      if (isComponentMountedRef.current) {
        setPackageCollection(packageCol);
        setIsLoading(false);
      }
    };
    fetchPackages();
  }, [isComponentMountedRef, product]);

  const onArchive = async () => {
    setIsLoading(true);
    const isArchival = !actionItem.item.isArchived;
    if (isArchival) {
      await actionItem.item.archive();
    } else {
      await actionItem.item.unarchive();
    }
    if (isComponentMountedRef.current) {
      setActionItem(null);
      setIsLoading(false);
    }
    showToast(format(isArchival ? texts.archiveSuccess : texts.unarchiveSuccess,
      { itemName: actionItem.isProduct ? texts.product : texts.package }));
  };

  return (
    <Card>
      {(!!actionItem) && (
        <ConfirmationModal
          open
          onClose={() => setActionItem(null)}
          onConfirm={onArchive}
          title={format(actionItem.item.isArchived ? texts.unarchiveItem : texts.archiveItem,
            { itemName: actionItem.isProduct ? texts.product : texts.package })}
          description={format(actionItem.item.isArchived ? texts.unArchiveDesc : texts.archiveDesc,
            { itemName: actionItem.item.name })}
        />
      )}
      <HeaderContainer>
        <TitleContainer>
          <TitleRow>
            <Title>
              {product.name}
              {product.isArchived && <ArchivedChip label={texts.archived} />}
              {product.isVideoFeedBackEnabled && <StyledChip label={texts.workoutFeedback} />}
              {product.isMealPlanEnabled && <StyledChip label={texts.mealPlan} />}
            </Title>
            <ButtonContainer>
              <PrimaryButton
                onClick={() => setActionItem({ item: product, isProduct: true })}
                icon={<StyledArchiveIcon />}
                variant="info"
                size="small"
              >
                {product.isArchived ? texts.unarchive : texts.archive}
              </PrimaryButton>
              <StyledButton onClick={() => setShowAddPackageModal(true)}>
                <StyledAddIcon />
                {texts.addPackage}
              </StyledButton>
            </ButtonContainer>
          </TitleRow>
          <SubTitle>{product.description}</SubTitle>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <Table
          enableSearch={false}
          rows={packageCollection.docs.slice().sort((a, b) => a.data.name.localeCompare(b.data.name))}
          filterFunction={(pkg) => showArchived || !pkg.isArchived}
          pageSize={4}
          loading={isLoading}
          columns={[
            {
              field: 'name',
              headerName: texts.headers.name,
              flex: 10,
            },
            {
              field: 'initialPaymentInCents',
              headerName: texts.headers.initial,
              flex: 5,
              renderCell: ({ row: { initialPaymentInCents, currency } }) => (
                formatCurrencyCents(
                  initialPaymentInCents,
                  currency,
                  { minimumFractionDigits: 2 },
                )
              ),
            },
            {
              field: 'initialTerm',
              headerName: texts.headers.term,
              flex: 5,
              renderCell: ({ row: { initialTerm } }) => (
                initialTerm ? format(texts.monthText, { months: initialTerm }) : '-'),
            },
            {
              field: 'minSubscriptionMonths',
              headerName: texts.headers.commitment,
              flex: 5,
              renderCell: ({ row: { minSubscriptionMonths } }) => (
                minSubscriptionMonths ? format(texts.monthText, { months: minSubscriptionMonths }) : '-'
              ),
            },
            {
              field: 'monthlyPaymentInCents',
              headerName: texts.headers.monthly,
              flex: 5,
              renderCell: ({ row: { monthlyPaymentInCents, currency } }) => (
                formatCurrencyCents(
                  monthlyPaymentInCents,
                  currency,
                  { minimumFractionDigits: 2 },
                )
              ),
            },
            {
              field: 'actions',
              headerName: texts.headers.actions,
              flex: 5,
              renderCell: ({ row: pkg }) => (
                <PrimaryButton
                  onClick={() => setActionItem({ item: pkg })}
                  icon={<StyledArchiveIcon />}
                  variant="info"
                  size="small"
                >
                  {pkg.isArchived ? texts.unarchive : texts.archive}
                </PrimaryButton>
              ),
            },
          ]}
        />
      </Container>
      {showAddPackageModal && (
        <AddPackageModal
          open={showAddPackageModal}
          onClose={() => setShowAddPackageModal(false)}
          product={product}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </Card>
  );
};

ProductView.propTypes = {
  product: PropTypes.instanceOf(Product).isRequired,
  showArchived: PropTypes.bool.isRequired,
};

export default compose(
  observer,
)(ProductView);
