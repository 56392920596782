import React from 'react';

import {
  Container,
  EmptyChat,
} from './styles';

const ChatEmptyView = () => (
  <Container>
    <EmptyChat />
  </Container>
);

export default ChatEmptyView;
