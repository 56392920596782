import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TagCategory } from '../../../../../Model/ExerciseTag';
import Modal from '../../../../../../components/v2/Modal';
import InputLabel from '../../../../../../components/v2/InputLabel';
import { StyledTextField } from '../../../../../../components/Inputs';
import { ReactComponent as CreateIcon } from '../../../../../../assets/icons/v2/creation-plus-circle.svg';
import { ReactComponent as SaveIcon } from '../../../../../../assets/icons/v2/save-alt.svg';
import { PrimaryButton, CancelButton } from '../../../../../../components/Button/ActionButtons';

import {
  InputContainer,
  StyledSelect,
} from './styles';
import texts from './texts';

const TagsModal = ({
  open,
  onClose,
  onSave,
  selectedTag,
}) => {
  const [tagName, setTagName] = useState(selectedTag?.tag || '');
  const [tagCategory, setTagCategory] = useState(selectedTag?.category || '');

  const categoryOptions = Object.entries(TagCategory)
    .reduce((acc, [key, value]) => {
      if (value !== TagCategory.PLATFORM) {
        acc.push({
          value,
          label: texts.tagCategoryOption[value],
          key,
        });
      }
      return acc;
    }, []);

  const isEditing = !!selectedTag;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={isEditing ? texts.editTag : texts.createNewTag}
      actionButtons={(
        <>
          <PrimaryButton
            onClick={() => onSave({ tagName, tagCategory })}
            icon={isEditing ? <SaveIcon /> : <CreateIcon />}
            disabled={!tagName || !tagCategory}
          >
            {isEditing ? texts.saveChanges : texts.addNewTag}
          </PrimaryButton>
          <CancelButton onClick={onClose}>{texts.cancel}</CancelButton>
        </>
      )}
    >
      <InputContainer>
        <InputLabel>{texts.tagName}</InputLabel>
        <StyledTextField
          value={tagName}
          onChange={(event) => setTagName(event.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>{texts.tagCategory}</InputLabel>
        <StyledSelect
          options={categoryOptions}
          onChange={(event) => setTagCategory(event.target.value)}
          value={tagCategory}
        />
      </InputContainer>
    </Modal>
  );
};

TagsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  selectedTag: PropTypes.object,
};

TagsModal.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  selectedTag: null,
};

export default TagsModal;
