import React from 'react';
import PropTypes from 'prop-types';

import DialogRoundedModal, { ModalBody } from '../../../../../components/DialogRoundedModal';
import RecipesTable from '../../../../components/RecipesTable';

import AddRecipeAction from './components/AddRecipeAction';
import texts from './texts.json';

const AddRecipeModal = ({
  isOpen,
  onClose,
  onAddRecipe,
  selectedBucketId,
  mealTimes,
}) => {
  const addRecipe = async (bucketId, recipe) => {
    onAddRecipe(bucketId, recipe);
    onClose();
  };

  const bulkAddRecipe = async (bucketId, recipes) => {
    recipes?.forEach((recipe) => onAddRecipe(bucketId, recipe));
    onClose();
  };

  return (
    <DialogRoundedModal
      open={isOpen}
      onClose={onClose}
      title={texts.title}
      description={texts.description}
      fullScreen
    >
      <ModalBody>
        <RecipesTable
          ActionsCellComponent={AddRecipeAction}
          extraActionsCellProps={{ addRecipe, mealTimes, selectedBucketId }}
          bulkActionComponent={AddRecipeAction}
          bulkActionComponentProps={{
            addRecipe: bulkAddRecipe,
            mealTimes,
            selectedBucketId,
            variant: 'square',
          }}
          bulkActionsEnabled
          actionHeader={texts.actionHeader}
          actionCellWidth={10}
        />
      </ModalBody>
    </DialogRoundedModal>
  );
};

AddRecipeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddRecipe: PropTypes.func.isRequired,
  mealTimes: PropTypes.array.isRequired,
  selectedBucketId: PropTypes.string,
};

AddRecipeModal.defaultProps = {
  selectedBucketId: null,
};

export default AddRecipeModal;
