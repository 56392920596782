import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import format from 'string-template';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import {
  HeaderRow,
  TitleContainer,
  Title,
} from '../../../../../components/v2/Header';
import {
  SectionContainer,
  SectionHeaderContainer,
  SectionTitle,
  SectionFooterContainer,
} from '../../../../../components/v2/Section';
import {
  SaveButton,
  CancelButton,
} from '../../../../../components/Button/ActionButtons';
import Workout, { VisibilityType } from '../../../../../Model/Workout';
import WorkoutAssignment from '../../../../../Model/WorkoutAssignment';
import UserContext from '../../../../../context/UserContext';
import useLogger from '../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../utils/log';
import useToast from '../../../../hooks/useToast';
import useUpdateInteraction from '../../../../hooks/useUpdateInteraction';
import ExercisesTable from '../../Exercises/ExercisesTable';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { ActionType } from '../utils';
import Activity from '../../../../../Model/Activity';
import {
  initialValues as startValues,
  validationSchema,
} from './validation';
import fieldName from './formFields';
import { estimateDuration } from './utils';
import {
  Container,
  StyledForm,
  StyledSectionCompartment,
  ContentContainer,
  ExercisesTableContainer,
  StyledFormikInput,
} from './styles';
import texts from './texts';
import Activities from './Activities';

const WorkoutEditor = ({
  workout,
  workoutAssignment,
  action,
  onClose,
}) => {
  const [isWorkoutSubmitting, setIsWorkoutSubmitting] = useState(false);
  const [showExercisesTable, setShowExercisesTable] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [preparedActivities, setPreparedActivities] = useState(
    workout?.activities?.map((activityItem) => new Activity(activityItem))
    || workoutAssignment?.workoutContent?.activities?.map((activityItem) => new Activity(activityItem))
    || [],
  );
  const [showActivityModal, setShowActivityModal] = useState(false);

  const { updateLastInteraction } = useUpdateInteraction(workoutAssignment?.user);
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { userDoc: { id: coachId } } = useContext(UserContext);
  const { logCoachingActivity } = useLogger();

  const docData = workout?.data || workoutAssignment?.workoutContent || {};

  const handleSubmit = useCallback(async (values, actions) => {
    setIsWorkoutSubmitting(true);
    if (action === ActionType.UPDATE) {
      await workout.updateFields({
        [fieldName.NAME]: values[fieldName.NAME],
        [fieldName.NOTE]: values[fieldName.NOTE] || '',
        activities: preparedActivities.map((activity) => activity.jsonActivity),
        estimatedDuration: estimateDuration(preparedActivities),
      });
      showToast(format(texts.workoutUpdated, { workoutName: values.name }));
      logCoachingActivity(CoachingActivity.UPDATED_WORKOUT, { workoutId: workout.id });
    } else if (action === ActionType.CREATE) {
      const workoutId = await Workout.addWorkout(
        {
          ...values,
          coach: coachId,
          createdAt: Date.now(),
          activities: preparedActivities.map((activity) => activity.jsonActivity),
          visibility: VisibilityType.PRIVATE,
          estimatedDuration: estimateDuration(preparedActivities),
          isArchived: false,
        },
      );
      showToast(format(texts.workoutUpdated, { workoutName: values.name }));
      logCoachingActivity(CoachingActivity.CREATED_WORKOUT, { workoutId });
    } else if (action === ActionType.UPDATE_WORKOUT_ASSIGNMENT) {
      await workoutAssignment.updateFields({
        workoutContent: {
          [fieldName.NAME]: values[fieldName.NAME],
          [fieldName.NOTE]: values[fieldName.NOTE],
          activities: preparedActivities.map((activity) => activity.jsonActivity),
          estimatedDuration: estimateDuration(preparedActivities),
        },
      });
      // Update user's last interaction with the logged in user info
      await updateLastInteraction();
      showToast(format(texts.workoutAssignmentUpdated, { workoutName: values.name }));
      logCoachingActivity(CoachingActivity.UPDATED_ASSIGNED_WORKOUT, {
        workoutId: workoutAssignment.workout,
        clientId: workoutAssignment.user,
      });
    }

    if (isComponentMountedRef.current) {
      setIsWorkoutSubmitting(false);
      actions.setSubmitting(false);
      onClose();
    }
  }, [
    action,
    showToast,
    isComponentMountedRef,
    workout,
    coachId,
    onClose,
    preparedActivities,
    workoutAssignment,
    updateLastInteraction,
    logCoachingActivity,
  ]);

  const sectionTitle = useMemo(() => {
    if (action === ActionType.UPDATE) {
      return texts.editWorkout;
    }
    if (action === ActionType.CREATE) {
      return texts.addWorkout;
    }
    return texts.editWorkoutAssignment;
  }, [action]);

  const buttonTitle = useMemo(() => {
    if (action === ActionType.UPDATE) {
      return texts.submitButton.saveWorkout;
    }
    if (action === ActionType.CREATE) {
      return texts.saveNewButton;
    }
    return texts.submitButton.saveWorkoutAssignment;
  }, [action]);

  const onExerciseAddClick = (exercise) => {
    setSelectedExercise(exercise);
    setShowActivityModal(true);
  };

  return (
    <Container>
      <HeaderRow>
        <TitleContainer>
          <Title>
            {texts.title}
          </Title>
        </TitleContainer>
      </HeaderRow>
      <ContentContainer>
        <Formik
          initialValues={docData || startValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <StyledForm className="workout-editor-form">
              <SectionContainer>
                <SectionHeaderContainer>
                  <SectionTitle>{sectionTitle}</SectionTitle>
                </SectionHeaderContainer>
                <StyledSectionCompartment>
                  <StyledFormikInput
                    name={fieldName.NAME}
                    label={`${texts.field[fieldName.NAME].label}:`}
                  />
                  <StyledFormikInput
                    name={fieldName.NOTE}
                    label={`${texts.field[fieldName.NOTE].label}:`}
                    multiline
                    rows={3}
                  />
                </StyledSectionCompartment>
                <StyledSectionCompartment>
                  <Activities
                    activities={preparedActivities}
                    onActivitiesChange={setPreparedActivities}
                    setShowExercisesTable={setShowExercisesTable}
                    selectedExercise={selectedExercise}
                    showActivityModal={showActivityModal}
                    setShowActivityModal={setShowActivityModal}
                  />
                </StyledSectionCompartment>
                <SectionFooterContainer>
                  <SaveButton
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {buttonTitle}
                  </SaveButton>
                  <CancelButton onClick={onClose}>
                    {texts.cancelButton}
                  </CancelButton>
                </SectionFooterContainer>
              </SectionContainer>
            </StyledForm>
          )}
        </Formik>
        {showExercisesTable && (
          <ExercisesTableContainer>
            <ExercisesTable
              isWorkoutDesignerView
              handleActionClick={onExerciseAddClick}
            />
          </ExercisesTableContainer>
        )}
      </ContentContainer>
      <LoadingOverlay
        isLoading={isWorkoutSubmitting}
        loadingText={texts.uploading}
      />
    </Container>
  );
};

WorkoutEditor.propTypes = {
  workout: PropTypes.instanceOf(Workout),
  workoutAssignment: PropTypes.instanceOf(WorkoutAssignment),
  action: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

WorkoutEditor.defaultProps = {
  workout: null,
  workoutAssignment: null,
  onClose: () => { },
};

export default WorkoutEditor;
