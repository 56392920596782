const texts = {
  workoutNotificationTitle: 'Workout Notifications',
  workoutNotificationDescription: 'When enabled you will get an alert when this client needs new workouts',
  enable: 'Enable',
  disable: 'Disable',
  notificationUpdateSuccessMessage: 'Notification setting updated successfully for the user',
  notificationUpdateFailureMessage: 'Error updating notification settings: {error}',
  mealPlanNotificationTitle: 'Meal Plan Notifications',
  mealPlanNotificationDescription: 'When disabled you will stop receiving meal plan notifications for this user',
};

export default texts;
