import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';

import ChatContext from '../../../context/ChatContext';
import NavigationContext from '../../../context/NavigationContext';
import ChatSideBar from '../../../pages/Chat/components/ChatSideBar';
import MessagingBroadcastButton from '../../../pages/Chat/components/MessagingBroadcast';

import text from './text.json';
import {
  Container,
  StyledUnreadCount,
  StyledChatIcon,
  TitleContainer,
  BottomBar,
  ChatContainer,
  StyledArrowIcon,
} from './styles';

const Chatbar = () => {
  const {
    setChatModalOpen,
    totalUnreadCount,
    isChatModalOpen,
  } = useContext(ChatContext);
  const { activePage } = useContext(NavigationContext);

  const handleChatModalToggle = useCallback(() => {
    setChatModalOpen((prev) => !prev);
  }, [
    setChatModalOpen,
  ]);

  useEffect(() => {
    setChatModalOpen(false);
  }, [
    activePage,
    setChatModalOpen,
  ]);

  return (
    <Container>
      {isChatModalOpen && (
        <ChatContainer>
          <ChatSideBar />
        </ChatContainer>
      )}
      <BottomBar>
        <TitleContainer onClick={handleChatModalToggle}>
          <StyledChatIcon />
          {text.Chat}
        </TitleContainer>
        <StyledUnreadCount onClick={handleChatModalToggle} label={`${totalUnreadCount} unread`} />
        <StyledArrowIcon onClick={handleChatModalToggle} $isOpen={isChatModalOpen} />
        <MessagingBroadcastButton />
      </BottomBar>
    </Container>
  );
};

export default Chatbar;
