import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import { IconButton } from '@mui/material';
import {
  HighlightOff as DeleteIcon, Reorder,
} from '@mui/icons-material';
import { Draggable } from 'react-smooth-dnd';

import defaultMealImage from '../../../../../assets/defaultMealImage.svg';
import { RecipeTag } from '../../../../utils/meals';

import { getColorBasedOnDeviation } from './utils';
import {
  Container,
  MealImage,
  MealName,
  StyledIconButton,
  MealCell,
} from './styles';
import texts from './texts';

const mealOptionValues = Object.values(RecipeTag).map((tag) => ({
  value: tag,
  label: texts.meal[tag],
}));

const MealItem = ({
  recipe: {
    id,
    image,
    name,
    totalCalories,
    protein,
    proteinPercentage,
    carbs,
    carbsPercentage,
    fat,
    fatPercentage,
    preparationTime,
    tags,
    allergenTags,

  },
  bucketIndex,
  bucketSummary: {
    calories: targetCalories,
    protein: targetProtein,
    carbs: targetCarbs,
    fat: targetFat,
  },
  handleDelete,
  handleView,
  allowDrag,
  allowDelete,
  servings,
  index,
}) => {
  const imageUrl = image || defaultMealImage;

  const recipeCalsAndMacros = useMemo(() => ({
    calories: parseInt(totalCalories * servings, 10),
    protein: parseInt(protein * servings, 10),
    carbs: parseInt(carbs * servings, 10),
    fat: parseInt(fat * servings, 10),
  }), [
    totalCalories,
    protein,
    carbs,
    fat,
    servings,
  ]);

  const recipeColorCode = useMemo(() => ({
    calories: getColorBasedOnDeviation(recipeCalsAndMacros.calories, targetCalories),
    protein: getColorBasedOnDeviation(recipeCalsAndMacros.protein, targetProtein),
    carbs: getColorBasedOnDeviation(recipeCalsAndMacros.carbs, targetCarbs),
    fat: getColorBasedOnDeviation(recipeCalsAndMacros.fat, targetFat),
  }), [
    recipeCalsAndMacros,
    targetCalories,
    targetProtein,
    targetCarbs,
    targetFat,
  ]);

  return (
    <Draggable key={index}>
      <Container>
        <MealCell>
          {allowDrag && (
            <IconButton className="drag-handle">
              <Reorder />
            </IconButton>
          )}
        </MealCell>
        <MealCell onClick={() => handleView(id, servings)}>
          <MealImage src={imageUrl} />
          <MealName>{name}</MealName>
        </MealCell>
        <MealCell $color={recipeColorCode.calories}>
          {`${recipeCalsAndMacros.calories} ${texts.caloriesUnit}`}
        </MealCell>
        <MealCell $color={recipeColorCode.protein}>
          {`${recipeCalsAndMacros.protein}g (${proteinPercentage}%)`}
        </MealCell>
        <MealCell $color={recipeColorCode.carbs}>
          {`${recipeCalsAndMacros.carbs}g (${carbsPercentage}%)`}
        </MealCell>
        <MealCell $color={recipeColorCode.fat}>
          {`${recipeCalsAndMacros.fat}g (${fatPercentage}%)`}
        </MealCell>
        <MealCell>
          {allergenTags.length ? allergenTags.join(', ') : texts.empty}
        </MealCell>
        <MealCell>
          {format(texts.prepTime, { preparationTime })}
        </MealCell>
        <MealCell>
          {tags
            .map((tag) => {
              const meal = mealOptionValues.find((option) => option.value === tag);
              return meal ? meal.label : '';
            })
            .join(', ')}
        </MealCell>
        <MealCell>
          {allowDelete && (
          <StyledIconButton size="small" onClick={() => handleDelete(id, bucketIndex)}>
            <DeleteIcon fontSize="inherit" />
          </StyledIconButton>
          )}
        </MealCell>
      </Container>
    </Draggable>

  );
};

MealItem.propTypes = {
  recipe: PropTypes.object.isRequired,
  bucketIndex: PropTypes.number.isRequired,
  bucketSummary: PropTypes.object.isRequired,
  allowDelete: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func,
  handleView: PropTypes.func,
  servings: PropTypes.number,
  index: PropTypes.number.isRequired,
  allowDrag: PropTypes.bool,
};

MealItem.defaultProps = {
  handleDelete: () => {},
  handleView: () => {},
  servings: 1,
  allowDrag: false,
};

export default MealItem;
