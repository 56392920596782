import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/material';

import { PrimaryButton } from '../../../components/Button/ActionButtons';
import ChatContext from '../../context/ChatContext';
import QuickChatContainer from '../../pages/Chat/pages/QuickChat/QuickChatContainer';

import variants from './variants';
import {
  Container,
  ChatButton,
  StyledPopper,
  StyledChatLightIcon,
  StyledChatIcon,
  ChatIconButton,
} from './styles';
import texts from './texts.json';

const ChatButtonComponent = ({
  userId,
  text,
  iconButton,
  smallButton,
  popperPlacement,
  iconVariant,
}) => {
  const [popperAnchor, setPopperAnchor] = useState(null);

  const { onQuickChatUserSelected, quickChatUser } = useContext(ChatContext);

  const handleQuickChatClick = useCallback((event) => {
    onQuickChatUserSelected(userId);
    setPopperAnchor(event.currentTarget);
  }, [
    onQuickChatUserSelected,
    userId,
  ]);

  const handleQuickChatClose = useCallback(() => {
    onQuickChatUserSelected('');
  }, [
    onQuickChatUserSelected,
  ]);

  useEffect(() => {
    if (!quickChatUser) {
      setPopperAnchor(null);
    }
  }, [
    quickChatUser,
  ]);

  const shouldRenderPopper = false;

  return (
    <Container>
      {!!iconButton && (
        <ChatIconButton onClick={handleQuickChatClick}>
          <StyledChatLightIcon variant={iconVariant} />
        </ChatIconButton>
      )}
      {(!iconButton && !!smallButton) && (
        <PrimaryButton
          onClick={handleQuickChatClick}
          icon={<StyledChatIcon variant={variants.SECONDARY} />}
          variant="warning"
          size="small"
        >
          {texts.caption}
        </PrimaryButton>
      )}
      {(!iconButton && !smallButton) && (
        <ChatButton onClick={handleQuickChatClick}>
          <StyledChatIcon variant={iconVariant} />
          {text}
        </ChatButton>
      )}
      {shouldRenderPopper && (
        <StyledPopper
          open={shouldRenderPopper}
          anchorEl={popperAnchor}
          placement={popperPlacement}
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'viewport',
                padding: 8,
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={handleQuickChatClose}>
            <QuickChatContainer />
          </ClickAwayListener>
        </StyledPopper>
      )}
    </Container>
  );
};

ChatButtonComponent.propTypes = {
  userId: PropTypes.string.isRequired,
  text: PropTypes.string,
  popperPlacement: PropTypes.string,
  iconButton: PropTypes.bool,
  smallButton: PropTypes.bool,
  iconVariant: PropTypes.string,
};

ChatButtonComponent.defaultProps = {
  text: '',
  popperPlacement: 'left-start',
  iconButton: false,
  smallButton: false,
  iconVariant: variants.PRIMARY,
};

export default ChatButtonComponent;
