import React, {
  useState,
  useContext,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import Draggable from 'react-draggable';

import ChatContext from '../../context/ChatContext';
import QAPContext from '../../../context/QAPContext';
import {
  Header,
  StyledTabs,
  Tabcontent,
  CloseButton,
  StyledCloseIcon,
  TabContainer,
  Handle,
  StyledPopper,
  StyledResizableBox,
} from './styles';
import UserInfoSection from './UserInfoSection';
import {
  quickAccessTabsConfig,
  getDeviceType,
  dynamicSizes,
} from './utils';

const deviceType = getDeviceType();

const sizes = {
  MIN_WIDTH: dynamicSizes[deviceType].MIN_WIDTH,
  MIN_HEIGHT: dynamicSizes[deviceType].MIN_HEIGHT,
  MAX_WIDTH: dynamicSizes[deviceType].MAX_WIDTH,
  MAX_HEIGHT: dynamicSizes[deviceType].MAX_HEIGHT,
};

const DELAY_TIME = 1000;

const ClientQuickInfoModal = () => {
  const {
    closeQAP,
    selectedUserId,
    selectedTab: defaultSelectedTab,
    isOpen,
  } = useContext(QAPContext);

  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
  const { onQuickChatUserSelected } = useContext(ChatContext);

  const tabContent = useMemo(() => {
    const { component: TabContent } = quickAccessTabsConfig[selectedTab];
    return (
      <TabContent clientId={selectedUserId} />
    );
  }, [
    selectedUserId,
    selectedTab,
  ]);

  const [width, setWidth] = useState(sizes.MAX_WIDTH);
  const [height, setHeight] = useState(sizes.MAX_HEIGHT);
  const resizableRef = useRef(null);
  const tabContentRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tabContentRef.current) {
        const totalHeight = tabContentRef.current.scrollHeight + tabContentRef.current.offsetTop;
        setHeight(totalHeight > sizes.MAX_HEIGHT ? sizes.MAX_HEIGHT : totalHeight);
      }
    // We wait a brief period to see if the tab content is rendered properly, before calculating the height
    }, DELAY_TIME);
    return () => clearTimeout(timer);
  }, [selectedTab]);

  const onResize = (event, { size }) => {
    setWidth(size.width);
    setHeight(size.height);
  };

  return (
    <Draggable handle="#quickAccessPanel">
      <StyledPopper
        sx={{ zIndex: 1300 }}
        open={isOpen}
        onClose={closeQAP}
      >
        <CloseButton
          onClick={closeQAP}
          $fullScreen
        >
          <StyledCloseIcon />
        </CloseButton>
        <StyledResizableBox
          width={width}
          height={height}
          onResize={onResize}
          minConstraints={[sizes.MIN_WIDTH, sizes.MIN_HEIGHT]}
          maxConstraints={[sizes.MAX_WIDTH, sizes.MAX_HEIGHT]}
          handle={<Handle />}
          ref={resizableRef}
        >
          <>
            <Header id="quickAccessPanel">
              <UserInfoSection
                clientId={selectedUserId}
                handleChatButtonClick={() => onQuickChatUserSelected(selectedUserId)}
              />
            </Header>
            <TabContainer>
              <StyledTabs
                tabsConfig={quickAccessTabsConfig}
                selectedTab={selectedTab}
                onSelectTab={setSelectedTab}
              />
            </TabContainer>
            <Tabcontent ref={tabContentRef}>
              {tabContent}
            </Tabcontent>
          </>
        </StyledResizableBox>
      </StyledPopper>
    </Draggable>
  );
};

export default compose(
  observer,
)(ClientQuickInfoModal);
