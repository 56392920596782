import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import LoadingPage from '../../../components/LoadingPage';
import MealPlanContext, { withMealPlanContextReady } from '../../context/MealPlanContext';

import RecipesTable from './RecipesTable';
import { Container } from './styles';
import texts from './texts';

const RecipesTableContainer = ({
  className,
  ActionsCellComponent,
  extraActionsCellProps,
  bulkActionComponent,
  bulkActionComponentProps,
  bulkActionsEnabled,
  actionHeader,
  actionCellWidth,
}) => {
  const { recipesDocs } = useContext(MealPlanContext);

  if (!recipesDocs.length) {
    return (
      <Container>
        <LoadingPage />
      </Container>
    );
  }

  return (
    <Container className={className}>
      <RecipesTable
        rows={recipesDocs}
        ActionsCellComponent={ActionsCellComponent}
        extraActionsCellProps={extraActionsCellProps}
        bulkActionComponent={bulkActionComponent}
        bulkActionComponentProps={bulkActionComponentProps}
        bulkActionsEnabled={bulkActionsEnabled}
        actionHeader={actionHeader}
        actionCellWidth={actionCellWidth}
      />
    </Container>
  );
};

RecipesTableContainer.propTypes = {
  ActionsCellComponent: PropTypes.elementType.isRequired,
  extraActionsCellProps: PropTypes.object,
  bulkActionComponent: PropTypes.elementType,
  bulkActionComponentProps: PropTypes.object,
  bulkActionsEnabled: PropTypes.bool,
  className: PropTypes.string,
  actionHeader: PropTypes.string,
  actionCellWidth: PropTypes.number,
};

RecipesTableContainer.defaultProps = {
  extraActionsCellProps: {},
  bulkActionComponent: null,
  bulkActionComponentProps: {},
  bulkActionsEnabled: false,
  className: '',
  actionHeader: texts.headers.action,
  actionCellWidth: 35,
};

export default compose(
  withMealPlanContextReady,
  observer,
)(RecipesTableContainer);
