import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import { useChatContext } from 'stream-chat-react';
import PropTypes from 'prop-types';
import {
  useIonAlert,
} from '@ionic/react';
import format from 'string-template';
import { ClickAwayListener, Popper } from '@mui/material';

import useChannel from '../../hooks/useChannel';
import ChatContext from '../../../../context/ChatContext';
import ChannelPreview from '../ChannelPreview';
import useLogger from '../../../../../hooks/useLogger';
import { ChannelBuckets, ChannelActions } from '../../../../context/ChatContext/buckets';
import ChannelPreviewPopover from './ChannelPreviewPopover';

import texts from './texts';

const MessagingChannelPreview = ({
  channel,
  setActiveChannel,
  unread: unreadCount,
}) => {
  const { logEvent } = useLogger();
  const { channel: activeChannel } = useChatContext();
  const {
    onChannelSelected,
    readOnlyMode,
    selectedBucket,
    setChatListOpen,
  } = useContext(ChatContext);
  const { getChannelName } = useChannel(channel);
  const isActive = !!channel && channel.id === activeChannel?.id;

  const [openHideChannelAlert] = useIonAlert();
  const [anchorEl, setAnchorEl] = useState();
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const onClick = useCallback(() => {
    setActiveChannel(channel);
    onChannelSelected(channel);
    setChatListOpen(false);
  }, [
    channel,
    setActiveChannel,
    onChannelSelected,
    setChatListOpen,
  ]);

  const channelName = getChannelName();

  const showChannel = async () => {
    await channel.updatePartial({
      set: {
        archived: false,
      },
    });
    await channel.show();
    logEvent('chatShown', {
      channelName,
      channelId: channel.id,
    });
  };

  const hideChannel = async () => {
    await channel.updatePartial({
      set: {
        archived: false,
      },
    });
    await channel.hide();
    logEvent('chatHidden', {
      channelName,
      channelId: channel.id,
    });
  };

  const archiveChannel = async () => {
    await channel.updatePartial({
      set: {
        archived: true,
      },
    });
    await channel.hide();
    logEvent('chatArchived', {
      channelName,
      channelId: channel.id,
    });
  };

  const unarchiveChannel = async () => {
    await channel.updatePartial({
      set: {
        archived: false,
      },
    });
    await channel.show();
    logEvent('chatUnarchived', {
      channelName,
      channelId: channel.id,
    });
  };

  const channelActionHandler = {
    [`${ChannelActions.ARCHIVE}`]: archiveChannel,
    [`${ChannelActions.UNARCHIVE}`]: unarchiveChannel,
    [`${ChannelActions.HIDE}`]: hideChannel,
    [`${ChannelActions.SHOW}`]: showChannel,
  };

  const handleAlert = (channelAction) => {
    openHideChannelAlert({
      header: texts.alert[channelAction].header,
      message: format(texts.alert[channelAction].message, { channelName }),
      buttons: [
        texts.alert.cancelButton,
        { text: texts.alert.confirmButton, handler: channelActionHandler[channelAction] },
      ],
    });
  };

  // Filter channels using archived metadata (If current bucket is Archived)
  // This fixes an issue were the channel list won't get updated after unarchiving
  if (selectedBucket === ChannelBuckets.ARCHIVED && channel?.data?.archived !== true) {
    return null;
  }

  const isAdmin = !!channel?.data?.admin;
  const shouldAllowActions = !readOnlyMode;

  const endUserId = channel.id;
  const isOnline = channel.state.members[endUserId]?.user?.online;
  const endUser = channel.state.members[endUserId]?.user || {};
  const {
    image,
  } = endUser;
  const {
    messages,
  } = channel.state;
  const hasLastMessage = messages?.length > 0;
  const lastMessage = hasLastMessage ? messages[messages.length - 1] : {};

  const handleAction = (event) => {
    // This prevents calling the onClick event for the PreviewContainer component
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsPopperOpen((prev) => !prev);
  };

  const handlePopoverAction = (type) => {
    handleAlert(type);
    setAnchorEl(null);
  };

  return (
    <>
      <ChannelPreview
        onClick={onClick}
        image={image}
        name={getChannelName()}
        lastMessage={lastMessage}
        unreadCount={unreadCount}
        isActive={isActive}
        isAdmin={isAdmin}
        shouldAllowActions={shouldAllowActions}
        handleAction={handleAction}
        isUnread={channel.data.markedAsUnread}
        isOnline={isOnline}
      />
      <Popper
        style={{ zIndex: 1000 }}
        open={isPopperOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        placement="left-end"
      >
        <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
          <ChannelPreviewPopover
            selectedBucket={selectedBucket}
            onArchive={() => handlePopoverAction(ChannelActions.ARCHIVE)}
            onUnarchive={() => handlePopoverAction(ChannelActions.UNARCHIVE)}
            onHide={() => handlePopoverAction(ChannelActions.HIDE)}
            onShow={() => handlePopoverAction(ChannelActions.SHOW)}
          />
        </ClickAwayListener>
      </Popper>
    </>
  );
};

MessagingChannelPreview.propTypes = {
  channel: PropTypes.object.isRequired,
  setActiveChannel: PropTypes.func.isRequired,
  unread: PropTypes.number,
};

MessagingChannelPreview.defaultProps = {
  unread: 0,
};

export default MessagingChannelPreview;
