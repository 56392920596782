import format from 'string-template';
import moment from 'moment';
import { SECONDS_PER_MINUTE, TimeDurationFormat } from '../utils/time';

const ActivityTypes = {
  REST: 'REST',
  TIMED: 'TIMED',
  REPETITIONS: 'REPETITIONS',
  CIRCUIT: 'CIRCUIT',
};

const ActivityGoalType = {
  REPETITIONS: 'repetitions',
  DURATION: 'duration',
};

const GOAL_FORMAT_REPETITIONS = '{goalValue} Reps';

const formatGoal = (goalValue, activityGoalType) => {
  if (activityGoalType === ActivityGoalType.DURATION) {
    return moment.duration(goalValue, 'seconds')
      .format(TimeDurationFormat.SHORT_FORMAT, {
        trim: 'both',
        stopTrim: goalValue > SECONDS_PER_MINUTE ? 'm' : 's',
      });
  }
  return format(GOAL_FORMAT_REPETITIONS, {
    goalValue,
  });
};

class Activity {
  /**
   * Constructs a new activity out of a JSON activity definition.
   * @param {Object} jsonActivity The activity in JSON format.
   */
  constructor(jsonActivity) {
    const {
      exerciseId,
      type,
      name,
      note,
      description,
      videoUrl,
      videoPreviewUrl,
      videoPreviewThumbnail,
      restTime,
      restTimeDisplayUnit,
      side,
      repetitions,
      duration,
      durationDisplayUnit,
      tags,
    } = jsonActivity;

    // Required fields
    this.exerciseId = exerciseId;
    this.type = type;
    /*
      Name is required for all activities except for CIRCUIT activities.
      For CIRCUIT activities we do still want to be able to save correctly to
      firebase and that's why we need to initialize with an empty string,
      otherwise firebase does not consider undefined as a valid value.
    */
    this.name = name || '';
    if (this.type === ActivityTypes.REPETITIONS) {
      this.repetitions = repetitions || '';
    }

    if (this.type === ActivityTypes.TIMED || this.type === ActivityTypes.REST) {
      this.duration = duration || '';
      this.durationDisplayUnit = durationDisplayUnit;
    }

    this.tags = tags || [];

    this.note = note || '';

    this.description = description || '';

    this.restTime = restTime || 0;
    this.restTimeDisplayUnit = restTimeDisplayUnit;

    if (this.type !== ActivityTypes.CIRCUIT) {
      this.videoUrl = videoUrl || '';
      this.videoPreviewUrl = videoPreviewUrl || videoUrl || '';
      this.videoPreviewThumbnail = videoPreviewThumbnail || '';
      this.side = side || '';
    }

    if (this.type === ActivityTypes.CIRCUIT) {
      const {
        activities = [],
        rounds = 1,
      } = jsonActivity;
      this.activities = activities.map((jsonSubActivity) => new Activity(jsonSubActivity));
      this.rounds = rounds || 1;
    }
  }

  get goal() {
    if (this.type !== ActivityTypes.CIRCUIT) {
      const goalFieldName = this.type === ActivityTypes.REPETITIONS
        ? ActivityGoalType.REPETITIONS
        : ActivityGoalType.DURATION;

      const goalValue = this[goalFieldName];

      return formatGoal(goalValue, goalFieldName);
    }
    return '';
  }

  get jsonActivity() {
    const jsonActivity = {
      type: this.type,
      ...(this.name && { name: this.name }),
      ...(this.note && { note: this.note }),
    };

    if (this.type !== ActivityTypes.REST && this.type !== ActivityTypes.CIRCUIT) {
      jsonActivity.exerciseId = this.exerciseId;
      jsonActivity.tags = this.tags;
      jsonActivity.side = this.side;
      jsonActivity.note = this.note;
      jsonActivity.description = this.description;
      jsonActivity.videoUrl = this.videoUrl;
      jsonActivity.videoPreviewUrl = this.videoPreviewUrl;
      jsonActivity.videoPreviewThumbnail = this.videoPreviewThumbnail;
      jsonActivity.restTime = this.restTime;
      jsonActivity.restTimeDisplayUnit = this.restTimeDisplayUnit;
    }

    if (this.type === ActivityTypes.REPETITIONS) {
      jsonActivity.repetitions = this.repetitions;
    }

    if (this.type === ActivityTypes.TIMED || this.type === ActivityTypes.REST) {
      jsonActivity.duration = this.duration;
      jsonActivity.durationDisplayUnit = this.durationDisplayUnit;
    }

    if (this.type === ActivityTypes.CIRCUIT) {
      jsonActivity.activities = this.activities.map((subActivity) => subActivity.jsonActivity);
      jsonActivity.rounds = this.rounds;
    }
    // remove all undefined values
    Object.keys(jsonActivity).forEach((key) => jsonActivity[key] === undefined && delete jsonActivity[key]);

    return jsonActivity;
  }
}

export default Activity;
export {
  ActivityTypes,
  ActivityGoalType,
};
