import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Title = styled(Typography)`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-top: 50px;
`;

export {
  Container,
  Title,
  TemplateContainer,
};
