import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Checkbox,
} from '@mui/material';

import { ReactComponent as FilterIcon } from '../../../assets/icons/v2/filter.svg';

import {
  menuStyles,
  StyledInputLabel,
  StyledSelect,
  StyledMenuItem,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledTypography,
} from './styles';

const CollapsibleSelect = ({
  options,
  onChange,
  selectLabel,
  className,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (value, isOptionChecked) => {
    const newSelected = isOptionChecked
      ? [...selectedOptions, value]
      : selectedOptions.filter((item) => item !== value);

    setSelectedOptions(newSelected);
    // Propagate changes to parent component
    onChange(newSelected);
  };

  return (
    <FormControl>
      <StyledInputLabel
        focused={false}
      >
        <FilterIcon />
        {selectLabel}
      </StyledInputLabel>
      <StyledSelect
        className={className}
        multiple
        value={selectedOptions}
        MenuProps={{
          sx: menuStyles,
        }}
      >
        {options.map((option) => (
          <StyledAccordion key={option.category}>
            <StyledAccordionSummary>
              <StyledTypography>{option.category}</StyledTypography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {option.items.map(({ id, label }) => (
                <StyledMenuItem key={id} value={label}>
                  <Checkbox
                    checked={selectedOptions.includes(id)}
                    onChange={(event) => handleCheckboxChange(id, event.target.checked)}
                  />
                  {label}
                </StyledMenuItem>
              ))}
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

CollapsibleSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  selectLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CollapsibleSelect.defaultProps = {
  options: [],
  onChange: () => {},
  className: '',
};

export default CollapsibleSelect;
