import styled from 'styled-components';

import ChatFeedContainer from '../ChatFeedContainer';
import MultipleChannelList from '../MultipleChannelView/MultipleChannelList';

const ChannelViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledMultipleChannelList = styled(MultipleChannelList)`
  width: 100% !important;
`;

const StyledChatFeedContainer = styled(ChatFeedContainer)`
  width: 100%;
`;

export {
  ChannelViewContainer,
  StyledMultipleChannelList,
  StyledChatFeedContainer,
};
