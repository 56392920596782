import styled from 'styled-components';
import { Typography } from '@mui/material';
import colors from '../../../../styles/colors';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;
const Container = styled.div`
  background-color: ${colors.base.alpha};
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  border-radius: 10px;
`;
const SettingContainer = styled.div`
  margin-bottom: 30px;
`;
const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0;
`;
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Title = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
`;

const SubTitle = styled(Text)`
  font-size: 13px !important;
  margin-bottom: 0;
  font-weight: 300;
  color: ${colors.shades.gamma7};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export {
  Container,
  SettingContainer,
  HeaderContainer,
  TitleContainer,
  Title,
  SubTitle,
  ButtonContainer,
};
