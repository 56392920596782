import React, { useContext } from 'react';

import ChatView from '../../components';
import ChatContext from '../../../../context/ChatContext';
import ChatLoadingView from '../../components/ChatLoadingView';

const ChatContainer = React.memo(() => {
  const {
    isChatReady,
  } = useContext(ChatContext);

  return (
    isChatReady
      ? <ChatView />
      : <ChatLoadingView />
  );
});

export default ChatContainer;
