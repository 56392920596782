import { FeatureId } from '../../../../Model/Product';

const texts = {
  enabledFeatures: 'Includes',
  disabledFeatures: 'Excludes',
  [FeatureId.NUTRITION_PLAN]: 'Meal Plan',
  [FeatureId.WORKOUT_VIDEO_FEEDBACK]: 'Video Feedback',
  [FeatureId.ASSISTANT_COACH]: 'Assistant Coach',
};

export default texts;
