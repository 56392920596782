import { ChatSetting } from '../../../../../Model/Coach';

const texts = {
  activityFeed: 'Activity Feed Settings',
  activityFeedDescription: 'Customize your activity feed',
  recentFirst: 'Most Recent First',
  oldestFirst: 'Oldest First',
  activityFeedOrder: 'Activity Feed Order',
  activityArchiveTitle: 'Auto Archive Activity Feed Listing',
  activityFeedOrderSuccess: 'Successfully changed Activity feed loading order to {order}',
  activityFeedOrderFailure: 'Error changing Activity feed loading order to {order}',
  chat: 'Chat Settings',
  chatDescription: 'Customize your chat functionality',
  chatSetting: {
    [ChatSetting.ENABLE_WORKOUT_NOTIFICATIONS]: 'Cloudbot Workout notifications',
    [ChatSetting.ENABLE_SUMMARY_NOTIFICATIONS]: 'Cloudbot Summary notifications',
    [ChatSetting.ENABLE_FEEDBACK_NOTIFICATIONS]: 'Cloudbot Feedback notifications',
  },
  enabled: 'Enabled',
  disabled: 'Disabled',
  chatSettingSaveSuccessful: 'Successfully saved the chat settings',
  chatSettingSaveFailed: 'Error changing the chatbot settings',
  changeArchivalTimeSuccessful: 'Successfully changed Activity feed archival time',
  changeArchivalTimeFailed: 'Error changing Activity feed archival time',
  orderTypes: {
    ASC: 'Oldest first',
    DESC: 'Most recent first',
  },
};

export default texts;
