import { ActivityTypes } from '../../../../../Model/Activity';

// Reps activities always add 80 seconds to a workout duration.
const STANDARD_REPS_DURATION = 80;

const EditorActionType = {
  CIRCUIT: 'CIRCUIT',
  ACTIVITY: 'ACTIVITY',
  REST: 'REST',
};

/**
 * Estimate the duration based on the activities provided. The following chart determines how much time we need to
 * add for each activity we find:
 *  - TIMED activities: the value stored in the duration field + restTime (if it's set for the activity)
 *  - REST activities: the value stored in the duration field.
 *  - REPETITIONS: we always add 80 seconds to the duration + restTime,
 *    no matter how many reps the exercise is configured with.
 *  - CIRCUIT: We sum the contents of the circuit, and the result is multiplied by the number of rounds.
 *
 * @param {array} activities The array with the activities data we'll use to estimate the duration.
 * @returns {number} The estimated duration of the workout.
 */
const estimateDuration = (activities) => {
  let estimatedDuration = 0;

  activities.forEach((activity) => {
    if (activity.activities) {
      const circuitDuration = estimateDuration(activity.activities);
      estimatedDuration += circuitDuration * (activity.rounds || 1);
    } else {
      if (activity.type === ActivityTypes.REST || activity.type === ActivityTypes.TIMED) {
        estimatedDuration += activity.duration;
      } else {
        estimatedDuration += STANDARD_REPS_DURATION;
      }

      if (activity.restTime) {
        estimatedDuration += activity.restTime;
      }
    }
  });

  return estimatedDuration;
};

export {
  estimateDuration,
  EditorActionType,
};
