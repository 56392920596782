import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import format from 'string-template';
import * as Sentry from '@sentry/browser';

import { Autocomplete, Tooltip } from '@mui/material';
import {
  DatePicker,
  TimePicker,
} from '@mui/lab';
import {
  GroupOutlined,
  PostAdd,
  Business,
  ArrowBack,
  ArrowForward,
} from '@mui/icons-material';
import {
  IonAvatar,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
} from '@ionic/react';
import {
  isToday,
  combineDateAndTime,
} from '../../../../../utils/date';
import { MAX_FILE_UPLOAD_SIZE } from '../../util/fileUpload';

import Coach from '../../../../../Model/Coach';
import ChatContext from '../../../../context/ChatContext';

import { storagePaths, pathPlaceholder } from '../../../../../utils/firebasePaths';
import useStorage, { StorageProcessState } from '../../../../../hooks/useStorage';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import UserContext from '../../../../../context/UserContext';

import defaultUser from '../../assets/icon/default-user.svg';
import TabPanel from '../../../../components/TabPanel';
import AttachmentsContainer from '../../../../components/AttachmentsContainer';
import EmojiInput from '../EmojiInput';
import AudioAttachmentsContainer from './components/AudioAttachmentsContainer';
import ScheduledMessagesList from './components/ScheduledBroadcasts';

import {
  Container,
  SendBroadcastButton,
  StyledLabel,
  CloseButton,
  StyledHeader,
  TitleContainer,
  ModalContent,
  SelectedRecipientContainer,
  StyledModal,
  StyledTextField,
  StyledTabs,
  StyledTab,
  DateSelectorContainer,
  DateSelector,
  ScheduleButtonsContainer,
  StyledChatIcon,
  TabContainer,
  RecipientActionsContainer,
  StyledIonLabel,
  SearchBar,
  StyledListContainer,
  StyledChip,
  NavigationContainer,
  StyledButton,
  StyledText,
  MenuContainer,
} from './styles';

import text from './text.json';

const filterTag = {
  ALL: 'ALL',
};

const MessagingBroadcastModal = ({
  isOpen,
  showMessageScheduled,
  onDidDismiss,
  showMessageError,
}) => {
  // Modal states
  const [isReady, setIsReady] = useState(false);
  const [tabSelected, setTabSelected] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  // Broadcast message values states
  const [broadcastMessage, setBroadcastMessage] = useState('');
  const [sendDate, setSendDate] = useState(moment().local());
  const [sendTime, setSendTime] = useState(moment().local());
  const [fileAttachments, setFileAttachments] = useState([]); // Array of Files
  const [audioAttachment, setAudioAttachment] = useState(null); // File
  // Recipients states
  const [filters, setFilters] = useState();
  const [filterTags, setFilterTags] = useState([filterTag.ALL]);
  const [recipients, setRecipients] = useState([]);
  const [filteredRecipients, setFilteredRecipients] = useState([]);
  const [userSearchValue, setUserSearchValue] = useState('');
  const [receipientIndex, setRecipientIndex] = useState(0);

  const RECIPIENTS_PER_PAGE = 5;

  const {
    uploadAttachments,
    deleteAttachments,
  } = useStorage();
  const {
    sendBroadcastMessage,
    scheduleBroadcastMessage,
  } = useContext(ChatContext);
  const isComponentMountedRef = useComponentMounted();
  const { userId } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      const usersListOfCoach = await Coach.getBroadcastMessageUsers(userId);
      const sortedUsersListOfCoach = [...usersListOfCoach].sort((a, b) => (a.name > b.name ? 1 : -1));
      const results = sortedUsersListOfCoach.map((user) => ({
        tags: [...user.tags, ...user.customTags],
        clientData: {
          id: user.id,
          userDoc: user,
          userTags: [...user.tags, ...user.customTags],
        },
      }));
      const tagList = [...new Set(results.flatMap((result) => result.tags))];

      const clientList = results.reduce((userArr, result) => {
        if (result.clientData) {
          return [...userArr, result.clientData];
        }
        return userArr;
      }, []);

      if (isComponentMountedRef.current) {
        setFilterTags([filterTag.ALL, ...tagList]);
        setRecipients(clientList);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isComponentMountedRef,
    userId,
    isReady,
  ]);

  const filterUsersByName = useCallback((users) => {
    const searchText = userSearchValue.toLowerCase();
    const filteredUsers = users.filter((user) => {
      const name = user.userDoc.name.toLowerCase();

      return name.indexOf(searchText) !== -1;
    });

    return filteredUsers;
  }, [
    userSearchValue,
  ]);

  const handleCheckChange = useCallback((checked, id) => {
    if (checked) {
      const newUser = recipients.find((recipient) => recipient.id === id);
      if (newUser) {
        setFilteredRecipients([...filteredRecipients, newUser]);
      }
    } else {
      setFilteredRecipients([...filteredRecipients.filter((recipient) => recipient.id !== id)]);
    }
  }, [
    filteredRecipients,
    recipients,
  ]);

  const filterList = useCallback((_, selectedFilters) => {
    setFilters(selectedFilters);
    if (selectedFilters.includes(filterTag.ALL)) {
      setFilteredRecipients(recipients);
    } else if (selectedFilters.length > 0) {
      setFilteredRecipients([...recipients
        .filter((recipient) => recipient.userTags?.some((tag) => selectedFilters.includes(tag)))]);
    } else {
      setFilteredRecipients([]);
    }
  }, [
    recipients,
  ]);

  const resetFields = useCallback(() => {
    setBroadcastMessage(null);
    setFilters([]);
    setSendDate(moment().local());
    setSendTime(moment().local());
    setFileAttachments([]);
    setAudioAttachment(null);
  }, []);

  const uploadMessageAttachments = useCallback(async () => {
    // We will store firebase storage references in the broadcast document
    const attachmentsRefs = [];

    // If this function is called there is at least one file attachment or an audio attachment
    const allAttachments = audioAttachment ? [...fileAttachments, audioAttachment] : fileAttachments;

    const filesToAdd = allAttachments.map((file) => {
      const lastDotIndex = file.name.lastIndexOf('.');
      return {
        // This is then revoked once the upload finishes (correctly or not)
        dataUrl: URL.createObjectURL(file),
        format: file.name.slice(lastDotIndex + 1),
        fileName: file.name.slice(0, lastDotIndex),
        fileType: file.type,
      };
    });
    const storagePath = format(storagePaths.COACH_BROADCAST_MESSAGES_ATTACHMENTS, {
      [pathPlaceholder.COACH_ID]: userId,
    });

    setIsUploading(true);
    try {
      const result = await uploadAttachments(filesToAdd, storagePath);

      let storageTaskFailed = false;
      result.forEach((res) => {
        if (res.state === StorageProcessState.SUCCESS) {
          attachmentsRefs.push(res.fileRef);
        } else {
          storageTaskFailed = true;
        }
      });
      if (storageTaskFailed) {
        // If some upload fails, we should stop the sending process
        // and delete the files that were uploaded (if there is any)
        if (attachmentsRefs.length) {
          const filesToDelete = attachmentsRefs.map((fileRef) => ({ storagePath: fileRef }));
          await deleteAttachments(filesToDelete);
        }
      }
      setIsUploading(false);
      return { attachmentsRefs, storageTaskFailed };
    } catch (error) {
      setIsUploading(false);
      Sentry.captureException(error, {
        extra: {
          userId,
        },
      });
      // Returning this to handle error messages from the caller function
      return { storageTaskFailed: true };
    }
  }, [
    fileAttachments,
    audioAttachment,
    userId,
    uploadAttachments,
    deleteAttachments,
  ]);

  const getAdditionalFields = useCallback(async () => {
    const additionalFields = {};
    if (fileAttachments.length || audioAttachment) {
      const { storageTaskFailed, attachmentsRefs } = await uploadMessageAttachments();
      if (storageTaskFailed) {
        setFileAttachments([]);
        setAudioAttachment(null);
        return { error: true };
      }
      additionalFields.attachmentsRefs = attachmentsRefs;
    }
    return { additionalFields, error: false };
  }, [
    audioAttachment,
    fileAttachments,
    uploadMessageAttachments,
  ]);

  const handleBroadcastSend = useCallback(async () => {
    const { additionalFields, error } = await getAdditionalFields();
    if (error) {
      showMessageError(text.uploadError);
      return;
    }
    await sendBroadcastMessage(filteredRecipients, broadcastMessage, additionalFields);
    resetFields();
    showMessageScheduled();
    onDidDismiss();
  }, [
    broadcastMessage,
    filteredRecipients,
    resetFields,
    showMessageScheduled,
    showMessageError,
    sendBroadcastMessage,
    getAdditionalFields,
    onDidDismiss,
  ]);

  const handleBroadcastSchedule = useCallback(async () => {
    const { additionalFields, error } = await getAdditionalFields();
    if (error) {
      showMessageError(text.uploadError);
      return;
    }
    // Convert to UTC after adding date and time
    const sendAt = combineDateAndTime(sendDate, sendTime).utc().valueOf();
    await scheduleBroadcastMessage(filteredRecipients, broadcastMessage, sendAt, additionalFields);
    resetFields();
    showMessageScheduled();
    setTabSelected(1);
  }, [
    sendDate,
    sendTime,
    broadcastMessage,
    filteredRecipients,
    resetFields,
    showMessageScheduled,
    showMessageError,
    scheduleBroadcastMessage,
    getAdditionalFields,
  ]);

  const onFilesSelected = useCallback((event) => {
    const { files } = event.target;
    if (files.length) {
      const filesArray = [...files];
      // Stream supports sending files of any type, just check file size
      const validFiles = filesArray.every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
      if (validFiles) {
        setFileAttachments(filesArray);
      } else {
        showMessageError(format(text.fileSizeError, {
          maxFileSize: MAX_FILE_UPLOAD_SIZE / 1000000, // Bytes to MB
        }));
      }
    }
  }, [
    showMessageError,
  ]);

  const onAudioRecorded = useCallback((audioFile) => {
    setAudioAttachment(audioFile);
  }, []);

  const onNavigationClick = useCallback((nameFilteredRecipients, forward = true) => {
    if (forward) {
      setRecipientIndex((prevIndex) => {
        if (nameFilteredRecipients.length > (prevIndex + 1) * RECIPIENTS_PER_PAGE) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    } else {
      setRecipientIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        }
        return prevIndex;
      });
    }
  }, [
  ]);

  const recipientListToRender = useMemo(() => {
    if (!isReady) {
      return <IonItem><IonSpinner /></IonItem>;
    }

    const nameFilteredRecipients = filterUsersByName(recipients);
    if (recipients.length > 0) {
      return (
        <StyledListContainer>
          <MenuContainer>
            <SearchBar
              clearSearch={() => setUserSearchValue('')}
              value={userSearchValue}
              onChange={(e) => setUserSearchValue(e.target.value)}
              placeholder={text.searchUser}
            />
            <NavigationContainer>
              <StyledButton onClick={() => onNavigationClick(nameFilteredRecipients, false)}>
                <ArrowBack fontSize="16" />
              </StyledButton>
              <StyledText>
                {format(text.pageLabel, {
                  pageNumber: receipientIndex + 1,
                  pageCount: Math.ceil(nameFilteredRecipients.length / RECIPIENTS_PER_PAGE),
                })}
              </StyledText>
              <StyledButton onClick={() => onNavigationClick(nameFilteredRecipients)}>
                <ArrowForward fontSize="16" />
              </StyledButton>
            </NavigationContainer>
          </MenuContainer>
          <IonList>
            {nameFilteredRecipients
              .slice(receipientIndex * RECIPIENTS_PER_PAGE, (receipientIndex + 1) * RECIPIENTS_PER_PAGE)
              .map((recipient) => (
                <IonItem key={recipient.id}>
                  <IonAvatar slot="start">
                    <img
                      src={recipient.userDoc.avatarUrl ? recipient.userDoc.avatarUrl : defaultUser}
                      alt={recipient.userDoc.name}
                    />
                  </IonAvatar>
                  <IonLabel>{recipient.userDoc.name}</IonLabel>
                  <IonCheckbox
                    onClick={(e) => handleCheckChange(e.target.checked, recipient.id)}
                    checked={filteredRecipients.map((user) => user.id).includes(recipient.id)}
                    slot="end"
                  />
                </IonItem>
              ))}
          </IonList>
        </StyledListContainer>
      );
    }

    return <IonItem><IonLabel>{text.noActiveClients}</IonLabel></IonItem>;
  }, [
    filteredRecipients,
    handleCheckChange,
    isReady,
    recipients,
    userSearchValue,
    filterUsersByName,
    onNavigationClick,
    receipientIndex,
  ]);

  // If the selected date is today, then the user cannot select a time in the past
  const minTime = useMemo(() => (
    isToday(sendDate) ? moment().local() : null
  ), [
    sendDate,
  ]);

  const onTabChange = useCallback((event, value) => {
    setTabSelected(value);
  }, []);

  const a11yProps = useCallback((index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }), []);

  const removeFileByIndex = useCallback((index) => {
    const resultAttachments = [...fileAttachments];
    resultAttachments.splice(index, 1);
    setFileAttachments(resultAttachments);
  }, [
    fileAttachments,
  ]);

  const removeAudioAttachment = useCallback(() => {
    setAudioAttachment(null);
  }, []);

  const setEmoji = useCallback((e) => {
    setBroadcastMessage((prevText) => `${prevText}${e.emoji}`);
  }, []);

  return (
    <StyledModal
      open={isOpen}
      onClose={onDidDismiss}
    >
      <Container>
        <CloseButton onClick={onDidDismiss} />
        <StyledHeader>
          <GroupOutlined />
          <TitleContainer>{text.broadcastMessageTitle}</TitleContainer>
        </StyledHeader>
        <ModalContent>
          <StyledTabs value={tabSelected} onChange={onTabChange}>
            <StyledTab label={text.newBroadcast} {...a11yProps(0)} icon={<PostAdd />} />
            <StyledTab label={text.scheduledBroadcasts} {...a11yProps(1)} icon={<Business />} />
          </StyledTabs>
          <TabPanel
            index={0}
            value={tabSelected}
          >
            <TabContainer>
              <SelectedRecipientContainer>
                <StyledLabel>{text.toLabel}</StyledLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  filterSelectedOptions
                  value={filters}
                  options={filterTags}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <StyledChip
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))}
                  renderInput={(params) => <StyledTextField {...params} />}
                  renderOption={(props, option) => <div {...props} key={option}>{option}</div>}
                  onChange={filterList}
                />
                {recipientListToRender}
                <StyledLabel>
                  {text.messageLabel}
                  <Tooltip
                    title={text.addClientName}
                    placement="top"
                    arrow
                  >
                    <StyledIonLabel
                      onClick={() => setBroadcastMessage((prevText) => `${prevText}${text.clientNamePlaceholder}`)}
                    >
                      [
                      {text.clientName}
                      ]
                    </StyledIonLabel>
                  </Tooltip>

                </StyledLabel>
                <StyledTextField
                  placeholder={text.enterMessage}
                  value={broadcastMessage}
                  rows={4}
                  multiline
                  onChange={(e) => setBroadcastMessage(e.target.value)}
                />
                <RecipientActionsContainer>
                  <EmojiInput onEmojiClick={setEmoji} />
                </RecipientActionsContainer>
              </SelectedRecipientContainer>
              <AttachmentsContainer
                fileAttachments={fileAttachments}
                onFilesSelected={onFilesSelected}
                onFileRemoved={removeFileByIndex}
                isUploading={isUploading}
              />
              <AudioAttachmentsContainer
                onAudioRecorded={onAudioRecorded}
                onRemove={removeAudioAttachment}
                isUploading={isUploading}
              />
              <ScheduleButtonsContainer>
                <SendBroadcastButton
                  onClick={handleBroadcastSend}
                  disabled={filteredRecipients.length === 0 || !broadcastMessage}
                >
                  <StyledChatIcon />
                  {text.sendBroadcastMessage}
                </SendBroadcastButton>
                <SendBroadcastButton
                  onClick={handleBroadcastSchedule}
                  disabled={filteredRecipients.length === 0 || !broadcastMessage}
                  light
                >
                  <StyledChatIcon light />
                  {text.scheduleBroadcastMessage}
                </SendBroadcastButton>
              </ScheduleButtonsContainer>
              <StyledLabel>{text.scheduleMessageDelivery}</StyledLabel>
              <DateSelectorContainer>
                <DateSelector>
                  <DatePicker
                    label={text.selectDate}
                    value={sendDate}
                    onChange={(newValue) => setSendDate(newValue)}
                    minDate={moment().local()}
                    maxDate={moment().local().add(3, 'months')}
                    renderInput={(params) => (
                      <StyledTextField {...params} />
                    )}
                  />
                </DateSelector>
                <DateSelector>
                  <TimePicker
                    label={text.selectTime}
                    value={sendTime}
                    onChange={(newValue) => setSendTime(newValue)}
                    minTime={minTime}
                    minutesStep={5}
                    renderInput={(params) => (
                      <StyledTextField {...params} />
                    )}
                  />
                </DateSelector>
              </DateSelectorContainer>
            </TabContainer>
          </TabPanel>
          <TabPanel
            index={1}
            value={tabSelected}
          >
            <ScheduledMessagesList />
          </TabPanel>
        </ModalContent>
      </Container>
    </StyledModal>
  );
};

MessagingBroadcastModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDidDismiss: PropTypes.func.isRequired,
  showMessageScheduled: PropTypes.func.isRequired,
  showMessageError: PropTypes.func.isRequired,
};

export default MessagingBroadcastModal;
