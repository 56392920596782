import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import texts from './texts.json';
import { StyledPaper } from './styles';

const ConfirmDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  dialogTexts,
}) => (
  <Dialog
    open={isOpen}
    PaperComponent={StyledPaper}
  >
    <DialogTitle>
      {dialogTexts.title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {dialogTexts.content || ''}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onConfirm}
        color="success"
      >
        {dialogTexts.confirm || texts.confirm}
      </Button>
      <Button
        onClick={onCancel}
        color="warning"
      >
        {dialogTexts.cancel || texts.cancel}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dialogTexts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    confirm: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
};

export default ConfirmDialog;
