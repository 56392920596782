import React, { useContext } from 'react';
import { Chat, Channel } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';

import ChatContext from '../../../../context/ChatContext';
import '../../assets/css/Chat.css';
import ChatState from '../../../../context/ChatContext/states';

import { channelConfiguration } from '../../util';

import CustomTriggerProvider from '../CustomTriggerProvider';
import ChatEmptyView from '../ChatEmptyView';
import ChatFeedView from '../ChatFeedView';
import ChatNotificationView from '../Notification';

import texts from './texts.json';
import {
  ChannelViewContainer,
  StyledMultipleChannelList,
  StyledChatFeedContainer,
} from './styles';

const ChatSideBar = () => {
  const {
    chatClient,
    chatState,
    onMarkChannelAsRead,
    isChatListOpen,
    customActiveChannel,
  } = useContext(ChatContext);
  if (chatState === ChatState.CHAT_ERROR) {
    return <ChatNotificationView notificationMessage={texts.chatViewErrorMessage} />;
  }

  return (
    <Chat client={chatClient} theme="messaging light">
      <ChannelViewContainer>
        {isChatListOpen
          ? <StyledMultipleChannelList />
          : (
            <StyledChatFeedContainer>
              <Channel
                EmptyPlaceholder={<ChatEmptyView />}
                maxNumberOfFiles={channelConfiguration.maxFileUploadLimit}
                multipleUploads
                channel={customActiveChannel}
                doMarkReadRequest={onMarkChannelAsRead}
                TriggerProvider={CustomTriggerProvider}
              >
                <ChatFeedView />
              </Channel>
            </StyledChatFeedContainer>
          )}
      </ChannelViewContainer>
    </Chat>
  );
};

export default ChatSideBar;
