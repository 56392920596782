import { Collection } from 'firestorter';
import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../utils/paths';
import BaseDocument from './BaseDocument';

class MsgTemplate extends BaseDocument {
  get name() {
    return this.data.name;
  }

  get msgBody() {
    return this.data.msgBody;
  }

  get order() {
    return this.data.order;
  }

  get isDefault() {
    return this.data.isDefault;
  }

  get salesStage() {
    return this.data.salesStage;
  }

  get attachmentUrl() {
    return this.data.attachmentUrl;
  }

  get attachmentCategory() {
    return this.data.attachmentCategory;
  }

  get coach() {
    return this.data.coach || '';
  }

  get isEnabled() {
    return this.data.isEnabled ?? true;
  }

  get version() {
    return this.data.version ?? 1;
  }

  get current() {
    return this.data.current;
  }

  get createdByUserName() {
    return this.data?.createdByUserName || '';
  }

  get createdAt() {
    return this.data.createdAt;
  }

  static async getDefaultTemplates() {
    const msgTemplateCollection = new Collection(firestorePaths.DEFAULT_MSG_TEMPLATES, {
      createDocument: (source, options) => new MsgTemplate(source, options),
      query: (query) => query
        .orderBy('order', 'asc'),
    });
    await MsgTemplate.initCollection(msgTemplateCollection);
    return msgTemplateCollection;
  }

  static async getTemplatesByCoach(coachId) {
    const msgTemplateCollection = new Collection(
      format(firestorePaths.COACH_MSG_TEMPLATES, { [pathPlaceholder.COACH_ID]: coachId }),
      {
        createDocument: (source, options) => new MsgTemplate(source, options),
        query: (query) => query
          .orderBy('order', 'asc'),
      },
    );
    await MsgTemplate.initCollection(msgTemplateCollection);
    return msgTemplateCollection;
  }

  static async addNewTemplate(templateData, version) {
    const newCoachMsgTemplate = new Collection(format(firestorePaths.COACH_MSG_TEMPLATES, {
      [pathPlaceholder.COACH_ID]: templateData.coach,
    }));
    await newCoachMsgTemplate.add({
      ...templateData,
      version,
    });
  }
}

export default MsgTemplate;
