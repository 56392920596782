import React, {
  useCallback,
  useState,
  useContext,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import PropTypes from 'prop-types';

import useQuickSearch from '../../../../../hooks/useQuickSearch';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useToast from '../../../../hooks/useToast';
import UserContext from '../../../../../context/UserContext';
import WorkoutContext, { withWorkoutContextReady } from '../../../../context/WorkoutContext';
import LabelCheckbox from '../../../../components/LabelCheckbox';
import Workout, { VisibilityType } from '../../../../../Model/Workout';
import { PrimaryButton } from '../../../../../components/Button/ActionButtons';
import QuickSearchToolbar from '../../../../components/QuickSearchToolbar';
import GenericDataGrid from '../../../../components/GenericDataGrid';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { HeaderRow, TitleContainer, Title } from '../../../../../components/v2/Header';
import { ReactComponent as CreateIcon } from '../../../../../assets/icons/v2/creation-plus-circle.svg';
import { CoachingActivity } from '../../../../../utils/log';
import useLogger from '../../../../../hooks/useLogger';
import { ActionType } from '../utils';
import getColumns from './columns';
import {
  StyledArchiveIcon,
  StyledDuplicateIcon,
  ActionButtonsContainer,
  StyledArrowIcon,
} from './styles';
import texts from './texts.json';

const WorkoutsTable = ({
  handleActionClick,
  selectedWorkout,
  actionPerformed,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const { userId: coachId } = useContext(UserContext);
  const {
    workouts,
    showArchivedWorkouts,
    setWorkoutsBasedOnArchiveFlag,
  } = useContext(WorkoutContext);
  const { logCoachingActivity } = useLogger();

  const renderActionCell = useCallback(({ row }) => (
    <ActionButtonsContainer>
      <PrimaryButton
        icon={<StyledDuplicateIcon />}
        variant="info"
        size="medium"
        onClick={() => handleActionClick(row, ActionType.DUPLICATE)}
      >
        {texts.duplicate}
      </PrimaryButton>
      <PrimaryButton
        icon={<StyledArrowIcon />}
        variant="info"
        size="medium"
        onClick={() => handleActionClick(row, ActionType.UPDATE)}
        disabled={row.visibility === VisibilityType.PUBLIC}
      >
        {texts.edit}
      </PrimaryButton>
      <PrimaryButton
        onClick={() => handleActionClick(row, row.isArchived ? ActionType.UNARCHIVE : ActionType.ARCHIVE)}
        icon={<StyledArchiveIcon />}
        variant="info"
        size="medium"
        disabled={row.visibility === VisibilityType.PUBLIC}
      >
        {row.isArchived ? texts.unarchive : texts.archive}
      </PrimaryButton>
    </ActionButtonsContainer>
  ), [handleActionClick]);

  const handelWorkoutAction = useCallback(async () => {
    setIsLoading(true);
    try {
      if (actionPerformed === ActionType.ARCHIVE || actionPerformed === ActionType.UNARCHIVE) {
        await selectedWorkout.updateFields({ isArchived: actionPerformed === ActionType.ARCHIVE });
        logCoachingActivity(actionPerformed === ActionType.ARCHIVE ? CoachingActivity.ARCHIVED_WORKOUT
          : CoachingActivity.UNARCHIVED_WORKOUT, { workoutId: selectedWorkout.id });
      }
      if (actionPerformed === ActionType.DUPLICATE) {
        const duplicatedWorkoutName = `${selectedWorkout.name} ${texts.copyAppendText}`;
        const newWorkoutData = {
          ...selectedWorkout.data,
          name: duplicatedWorkoutName,
          createdAt: Date.now(),
          visibility: VisibilityType.PRIVATE,
          coach: coachId,
        };

        await Workout.addWorkout(newWorkoutData);
      }

      showToast(format(texts.actionSuccessMsg, {
        actionType: actionPerformed.toLowerCase(),
        workoutName: selectedWorkout.name,
      }));
    } catch (error) {
      showToast(format(texts.actionFailureMessage, {
        actionType: actionPerformed.toLowerCase(),
        workoutName: selectedWorkout.name,
      }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        handleActionClick(null, null);
        setIsLoading(false);
      }
    }
  }, [
    actionPerformed,
    showToast,
    selectedWorkout,
    coachId,
    isComponentMountedRef,
    handleActionClick,
    logCoachingActivity,
  ]);

  const columns = getColumns({
    actionsRenderCell: renderActionCell,
  });

  const {
    filteredRows,
    toolbarProps,
  } = useQuickSearch(workouts, columns);

  const onCloseConfirmModal = () => handleActionClick(null, null);

  return (
    <>
      <HeaderRow>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
        <PrimaryButton
          onClick={() => handleActionClick(null, ActionType.CREATE)}
          icon={<CreateIcon />}
        >
          {texts.newWorkout}
        </PrimaryButton>
      </HeaderRow>
      <GenericDataGrid
        rows={filteredRows}
        columns={columns}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            autoFocus: true,
            placeholder: texts.searchPlaceholder,
            filterTools: [
              {
                Component: LabelCheckbox,
                id: 'archive-filter',
                props: {
                  isChecked: showArchivedWorkouts,
                  description: texts.showArchivedCheckbox,
                  onChange: (isChecked) => setWorkoutsBasedOnArchiveFlag(isChecked),
                },
              },
            ],
          },
        }}
      />
      {!!selectedWorkout && !!actionPerformed && (
        <ConfirmDialog
          isOpen={!!selectedWorkout}
          onConfirm={handelWorkoutAction}
          onCancel={onCloseConfirmModal}
          dialogTexts={{
            title: format(texts.actionConfirmMessage, {
              actionType: actionPerformed.toLowerCase(),
              workoutName: selectedWorkout.name,
            }),
          }}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

WorkoutsTable.propTypes = {
  handleActionClick: PropTypes.func.isRequired,
  selectedWorkout: PropTypes.instanceOf(Workout),
  actionPerformed: PropTypes.string,
};

WorkoutsTable.defaultProps = {
  selectedWorkout: null,
  actionPerformed: null,
};

export default compose(
  withWorkoutContextReady,
  observer,
)(WorkoutsTable);
