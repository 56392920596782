import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  ChatBubbleOutlineOutlined as MessageIcon,
  DraftsOutlined as EmailIcon,
  ChatOutlined as ChatIcon,
  NoteAddOutlined,
} from '@mui/icons-material';

import { getQueryVariable } from '../../../../../utils/queryParams';
import Lead, {
  ContactProvider,
  LeadHistoryType,
  SalesStage,
} from '../../../../Model/Lead';
import { getFormAnswersFromData } from '../../../../utils/formAnswers';
import DialogModal from '../../../../../components/DialogModal';
import ContactInfoItem from '../../../../pages/ClientInfo/components/ContactInfoItem';
import {
  StyledEmailIcon,
  StyledPhoneIcon,
  StyledTimeZoneIcon,
} from '../../../../pages/ClientInfo/components/UserInfoSection/styles';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import SplitButton from '../../../../../components/SplitButton';
import MsgTemplate from '../../../../../Model/MsgTemplate';
import { getNextSalesStage } from '../../../../utils/lead';
import MsgModal from '../MsgModal';
import MsgEndpointContext, {
  withMsgEndpointContextProvider,
} from '../../../../context/MsgEndpointContext';
import ChatModal from '../ChatModal';
import CallLogModal from '../CallLogModal';
import ReasonModal from '../ReasonModal';
import Note from './components/Note';
import {
  Answer,
  ContactInfoSection,
  ModalContent,
  LeadInfoSection,
  Question,
  Title,
  TitleSection,
  ContactButton,
  QuestionsContainer,
  HistorySection,
  MainSection,
  HistoryTitle,
  StyledButton,
  StyledTextField,
  StyledNoteActionContainer,
  HistoryNotesContainer,
  Container,
  CallLogButton,
  ButtonsContainer,
} from './styles';
import texts from './texts.json';

const EMPTY_CELL = '-';

const LeadsModal = ({
  lead,
  isInsideSalesView,
  onClose,
  showModal,
  userId,
  userName,
  coach,
}) => {
  const [selectedMsgProvider, setSelectedMsgProvider] = useState(null);
  const [selectedMsgTemplate, setSelectedMsgTemplate] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [showCallLogModal, setShowCallLogModal] = useState(false);
  const [showRescheduledCallModal, setShowRescheduledCallModal] = useState(false);
  const [showRescheduleViaSDRModal, setShowRescheduleViaSDRModal] = useState(false);
  const [note, setNote] = useState('');
  const [history, setHistory] = useState([]);
  const [msgTemplates, setMsgTemplates] = useState([]);

  const isComponentMountedRef = useComponentMounted();
  const { msgEndpointCollection } = useContext(MsgEndpointContext);

  const showOutreachOptions = useMemo(() => getQueryVariable('isSuperUser') === 'true', []);

  useEffect(() => {
    const loadMsgTemplates = async () => {
      if (coach) {
        const templates = await MsgTemplate.getTemplatesByCoach(coach.id);
        if (isComponentMountedRef.current) {
          setMsgTemplates(templates.docs);
        }
      }
    };
    loadMsgTemplates();
  }, [
    isComponentMountedRef,
    coach,
  ]);

  const nextMsgTemplate = useMemo(() => {
    const nextSalesStage = getNextSalesStage(lead.salesStage);
    let nextTemplate = null;
    if (nextSalesStage) {
      nextTemplate = msgTemplates.find((template) => template.salesStage === nextSalesStage);
    }
    // if there is no msg template for next sales stage, we should use 3rd msg template as default one
    // this is because we don't have msg template for some sales stages (e.g. 1st cold call)
    if (!nextTemplate) {
      nextTemplate = msgTemplates.find((template) => template.salesStage === SalesStage.THIRD_MESSAGE);
    }
    return nextTemplate;
  }, [lead.salesStage, msgTemplates]);

  useEffect(() => {
    const loadHistory = async () => {
      if (lead) {
        setHistory([]);
        const historyData = await lead?.getHistory();
        if (isComponentMountedRef.current) {
          setHistory(historyData);
        }
      }
    };
    loadHistory();
  }, [isComponentMountedRef, lead]);

  const createNote = async () => {
    await lead.addItemToHistory({
      value: note,
      createdAt: new Date(),
      activityType: LeadHistoryType.NOTE,
      createdBy: userId,
      createdByUserName: userName,
    });
    setNote('');
  };

  const formattedResponses = useMemo(() => getFormAnswersFromData(lead), [lead]);
  const msgActions = useMemo(() => msgTemplates.map((template) => (
    {
      key: template.id,
      label: `Send ${template.name}`,
    }
  )), [msgTemplates]);

  const showMsgModal = useMemo(() => !!selectedMsgProvider || !!selectedMsgTemplate,
    [selectedMsgProvider, selectedMsgTemplate]);

  const onMsgModalClose = useCallback(() => {
    setSelectedMsgProvider(null);
    setSelectedMsgTemplate(null);
  }, []);

  const clientFirstName = useMemo(() => lead?.name?.split(' ')[0], [lead]);

  const onMsgSent = useCallback((msgData) => {
    const {
      msgTemplate,
    } = msgData;
    // update lead sales stage
    if (msgTemplate && msgTemplate.salesStage) {
      lead.setSalesStage(msgTemplate.salesStage, { userId, userName });
    }
    // add lead history event
    lead.addItemToHistory({
      value: {
        ...msgData,
        msgTemplate: msgTemplate?.data || {},
      },
      activityType: LeadHistoryType.MSG,
      createdBy: userId,
      createdByUserName: userName,
    });
  }, [
    lead,
    userId,
    userName,
  ]);

  const { lastContactNo, lastProvider } = useMemo(() => {
    if (lead.lastContactedMsgProvider && lead.phoneNumber) {
      return {
        lastContactNo: lead.phoneNumber,
        lastProvider: lead.lastContactedMsgProvider,
      };
    }
    const lastMsgHistory = history.splice().reverse().find((item) => item.activityType === LeadHistoryType.MSG);
    if (lastMsgHistory) {
      return {
        lastContactNo: lastMsgHistory.value.contactNo,
        lastProvider: lastMsgHistory.value.msgProvider,
      };
    }
    return {};
  }, [history, lead]);

  const onRescheduleViaSDR = useCallback(async ({ reason }) => {
    // Add a new entry to the lead history, so that we can track who sent the lead back to SDR
    await lead.addItemToHistory({
      value: { reason },
      reason,
      createdAt: new Date(),
      activityType: LeadHistoryType.SENT_TO_RESCHEDULE,
      createdBy: userId,
      createdByUserName: userName,
    });

    // The lead will be back IN_DIALOGUE.
    await lead.setSalesStage(SalesStage.IN_DIALOGUE, { userId, userName });
  }, [
    lead,
    userId,
    userName,
  ]);

  return (
    <DialogModal
      open={showModal}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      {showMsgModal && (
        <MsgModal
          open={showMsgModal}
          onClose={onMsgModalClose}
          contactNo={lastContactNo}
          provider={selectedMsgProvider || lastProvider}
          msgTemplate={selectedMsgTemplate}
          msgParams={{ clientName: clientFirstName, coachName: coach.label }}
          onMsgSent={onMsgSent}
          msgEndpoints={msgEndpointCollection?.docs || []}
          sendAsUserId={lead.lastSendAsUserId}
          leadId={lead.id}
        />
      )}
      {showChatModal && (
        <ChatModal
          open={showChatModal}
          onClose={() => setShowChatModal(false)}
          contactNo={lastContactNo}
          name={lead.name}
          sendAsUserId={lead.lastSendAsUserId}
          sendAsUserName={lead.lastSendAs}
          email={lead.email}
          provider={lastProvider}
          onMoreSelected={(provider = ContactProvider.iMessage) => setSelectedMsgProvider(provider)}
          msgEndpoints={msgEndpointCollection?.docs || []}
          onMsgSent={onMsgSent}
          showInputField={showOutreachOptions}
        />
      )}
      {showCallLogModal && (
        <CallLogModal
          showModal={showCallLogModal}
          onClose={() => setShowCallLogModal(false)}
          lead={lead}
          userId={userId}
          userName={userName}
        />
      )}
      {showRescheduledCallModal && (
        <CallLogModal
          showModal={showRescheduledCallModal}
          onClose={() => setShowRescheduledCallModal(false)}
          lead={lead}
          userId={userId}
          userName={userName}
          logRescheduledCall
        />
      )}
      {showRescheduleViaSDRModal && (
        <ReasonModal
          title={texts.rescheduleViaSDR}
          onSave={onRescheduleViaSDR}
          onClose={() => setShowRescheduleViaSDRModal(false)}
          showModal={showRescheduleViaSDRModal}
        />
      )}
      <Container>
        <MainSection>
          <LeadInfoSection>
            <TitleSection>
              <Title>
                {lead.name || EMPTY_CELL}
              </Title>
              {showOutreachOptions && isInsideSalesView && !!msgTemplates?.length && (
                <SplitButton
                  options={msgActions}
                  onClick={(templateIndex) => setSelectedMsgTemplate(msgTemplates[templateIndex])}
                  initialSelectedIndex={msgTemplates.indexOf(nextMsgTemplate)}
                />
              )}
            </TitleSection>
            <ContactInfoSection>
              <ContactInfoItem icon={<StyledPhoneIcon />}>
                {lead?.phoneNumber || EMPTY_CELL}
              </ContactInfoItem>
              <ContactInfoItem icon={<StyledEmailIcon />}>
                {lead?.email || EMPTY_CELL}
              </ContactInfoItem>
              <ContactInfoItem icon={<StyledTimeZoneIcon />}>
                {lead?.timezone || EMPTY_CELL}
              </ContactInfoItem>
              {isInsideSalesView && (
                <ContactInfoItem icon="IS">
                  {lead?.msgServerContactNo || lead?.sendBlueName || EMPTY_CELL}
                </ContactInfoItem>
              )}
            </ContactInfoSection>
          </LeadInfoSection>
          <ModalContent>
            <ButtonsContainer>
              {isInsideSalesView && (
                <>
                  <ContactButton
                    variant="outlined"
                    startIcon={<ChatIcon />}
                    provider="chat"
                    onClick={() => setShowChatModal(true)}
                  >
                    {texts.chat}
                  </ContactButton>
                  {showOutreachOptions && (
                    <ContactButton
                      variant="outlined"
                      startIcon={<MessageIcon />}
                      provider="msg"
                      onClick={() => setSelectedMsgProvider(lastProvider || ContactProvider.iMessage)}
                    >
                      {texts.sendMsg}
                    </ContactButton>
                  )}
                </>
              )}
              {showOutreachOptions && (
                <ContactButton
                  variant="outlined"
                  startIcon={<EmailIcon />}
                  provider="email"
                  href={`mailto:${lead?.email}`}
                >
                  {texts.sendEmail}
                </ContactButton>
              )}
            </ButtonsContainer>

            {isInsideSalesView && lead.salesStage === SalesStage.CALENDLY_SCHEDULED && (
              <ButtonsContainer>
                <ContactButton
                  variant="outlined"
                  provider="sentToReschedule" // TODO: standardize buttons to avoid using provider as type
                  onClick={() => setShowRescheduleViaSDRModal(true)}
                >
                  {texts.rescheduleViaSDR}
                </ContactButton>
                <ContactButton
                  variant="outlined"
                  provider="rescheduledManually" // TODO: standardize buttons to avoid using provider as type
                  onClick={() => setShowRescheduledCallModal(true)}
                >
                  {texts.rescheduledManually}
                </ContactButton>
              </ButtonsContainer>
            )}
            <h3>{texts.leadForm}</h3>
            <ol>
              <QuestionsContainer key="lead-id">
                <Question>{texts.leadId}</Question>
                <Answer>{lead.id}</Answer>
              </QuestionsContainer>
              {formattedResponses && formattedResponses.map(({ question, answer }) => !!answer
                && (
                  <QuestionsContainer key={question}>
                    <Question>{question}</Question>
                    <Answer>{answer}</Answer>
                  </QuestionsContainer>
                ))}
            </ol>
          </ModalContent>
        </MainSection>
        <HistorySection>
          <TitleSection>
            <HistoryTitle>{texts.historyTitle}</HistoryTitle>
            {isInsideSalesView && (
              <CallLogButton
                variant="contained"
                onClick={() => setShowCallLogModal(true)}
              >
                {texts.logACall}
              </CallLogButton>
            )}
          </TitleSection>
          <HistoryNotesContainer>
            {history.map(({
              id,
              activityType,
              value,
              reason,
              createdAt,
              createdByUserName,
              createdBy,
              coachId,
            }) => (
              <Note
                key={id}
                date={createdAt}
                type={activityType}
                value={value}
                reason={reason}
                name={createdByUserName}
                createdUserId={createdBy}
                coachId={coachId}
              />
            ))}
          </HistoryNotesContainer>
          <StyledNoteActionContainer>
            <StyledTextField
              size="medium"
              multiline
              rows={5}
              fullWidth
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <StyledButton
              disabled={!note}
              onClick={createNote}
              startIcon={<NoteAddOutlined />}
            >
              {texts.addNote}
            </StyledButton>
          </StyledNoteActionContainer>
        </HistorySection>
      </Container>
    </DialogModal>
  );
};

LeadsModal.propTypes = {
  lead: PropTypes.instanceOf(Lead).isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  isInsideSalesView: PropTypes.bool,
  coach: PropTypes.object,
};

LeadsModal.defaultProps = {
  isInsideSalesView: false,
  coach: null,
};

export default compose(
  withMsgEndpointContextProvider,
  observer,
)(LeadsModal);
