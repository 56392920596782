import styled from 'styled-components';
import {
  MenuItem,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';

import colors from '../../../styles/colors';
import Select from '../Select';

const menuStyles = {
  '& .MuiPaper-root': {
    background: colors.shades.primary7,
  },
  '& .MuiMenu-list': {
    maxHeight: '250px',
    padding: 0,
  },
};

const StyledSelect = styled(Select)`
  background: ${colors.shades.primary7};
  color: ${colors.shades.secondary8};
  align-items: center;

  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.shades.primary3};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background: ${colors.shades.primary7};
`;

const StyledInputLabel = styled(InputLabel)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.shades.secondary8};
  transform: translate(15px, 11px);
  
  svg {
    margin-right: 5px;
  }
`;

const StyledAccordion = styled(Accordion)`
  background: ${colors.shades.primary7};
  margin: 0;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  background: ${colors.shades.primary3};
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;

const StyledTypography = styled(Typography)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
`;

export {
  menuStyles,
  StyledSelect,
  StyledMenuItem,
  StyledInputLabel,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledTypography,
};
