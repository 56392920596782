import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

class MealPlan extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.MEAL_PLAN}/${id}`, opts);
  }

  get name() {
    return this.data.name;
  }

  get mealTimes() {
    return this.data.mealTimes || [];
  }

  get macroAverages() {
    return this.data.macroAverages;
  }

  get allergenTags() {
    return this.data.allergenTags || [];
  }

  get isArchived() {
    return !!this.data.isArchived;
  }

  get archivedAt() {
    return this.data.archivedAt;
  }

  get createdAt() {
    return this.data.createdAt;
  }

  get type() {
    return this.data.type;
  }

  get recipes() {
    return this.data.recipes || [];
  }

  get numberOfMealPlansToExclude() {
    return this.data.numberOfMealPlansToExclude || 0;
  }

  get lastUpdated() {
    return this.data.lastUpdated;
  }

  async updateMealPlan(data) {
    await this.updateFields(data);
  }

  async archive() {
    return this.updateFields({
      isArchived: true,
      archivedAt: new Date(),
    });
  }

  static async addDoc(data) {
    const mealPlansCollection = new Collection(Collections.MEAL_PLAN);
    const now = new Date();
    const doc = await mealPlansCollection.add({
      ...data,
      isArchived: false,
      createdAt: now,
      lastUpdated: now,
    });
    const mealPlan = new MealPlan(doc.id);
    await mealPlan.init();
    return mealPlan;
  }

  static async getMealPlan(mealPlanId) {
    const mealPlanDoc = new MealPlan(mealPlanId);
    await mealPlanDoc.init();
    return mealPlanDoc.exists ? mealPlanDoc : null;
  }

  static async getMealPlansByCoach(coach, fetchArchivedPlans = false) {
    const mealPlansCollection = new Collection(Collections.MEAL_PLAN, {
      createDocument: ({ id }, opts) => new MealPlan(id, { ...opts, disableObserverCountRef: true }),
      query: (ref) => {
        let query = ref.where('coach', '==', coach);
        if (!fetchArchivedPlans) {
          query = query.where('isArchived', '==', false);
        }
        return query;
      },
    });
    await mealPlansCollection.fetch();
    return mealPlansCollection;
  }
}

const MealPlanType = {
  PREDEFINED: 'PREDEFINED',
  LIVE: 'LIVE',
};

export default MealPlan;
export { MealPlanType };
