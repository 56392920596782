/* eslint-disable max-len */
import CollectionName from './collections';

const pathPlaceholder = {
  USER_ID: 'userId',
  COACH_ID: 'coachId',
  GAMEPLAY_SESSION_ID: 'gameplaySessionId',
  ACTIVITY_SESSION_ID: 'activitySessionId',
  WORKOUT_HISTORY_FRAME: 'workoutHistoryFrame',
  CUSTOM_WORKOUT_ID: 'customWorkoutId',
  FILE_NAME: 'fileName',
  COACH_NOTIFICATION: 'coachNotification',
  GAMEPLAY_SESSION_VIDEO_REQUEST: 'gameplaySessionVideoRequest',
  TRACKED_ACTIVITY_ID: 'trackedActivityId',
  RECIPE_ID: 'recipeId',
};

const firestorePaths = {
  USER: `/${CollectionName.USER}`,
  USER_CONFIG: `/${CollectionName.CONFIG}`,
  GAMEPLAY_SESSION_COLLECTION: `/${CollectionName.GAMEPLAY_SESSION}`,
  USER_DEVICE: `/${CollectionName.USER_DEVICE}`,
  USER_ACTIVITY_HISTORY: `/${CollectionName.USER_ACTIVITY_HISTORY}`,
  EXERCISE: `/${CollectionName.EXERCISE}`,
  COACH: `/${CollectionName.COACH}`,
  TRACKED_ACTIVITY_SUMMARY: `/${CollectionName.TRACKED_ACTIVITY_SUMMARY}`,
  WORKOUT: `/${CollectionName.WORKOUT}`,
  WORKOUT_ASSIGNMENT: `/${CollectionName.WORKOUT_ASSIGNMENT}`,
  STREAK: `/${CollectionName.STREAK}`,
  TRACKED_ACTIVITY: `/${CollectionName.TRACKED_ACTIVITY}`,
  REFERRAL_CODE: `/${CollectionName.REFERRAL_CODE}`,
  APP_CUSTOMIZATION: `/${CollectionName.APP_CUSTOMIZATION}`,
  CHANGELOG: `/${CollectionName.CHANGELOG}`,
  MESSAGE_SUGGESTION: `/${CollectionName.MESSAGE_SUGGESTION}`,
  USER_CONTRACT: `/${CollectionName.USER_CONTRACT}`,
  STRIPE: `/${CollectionName.INTEGRATIONS}/stripe`,
};

firestorePaths.GAMEPLAY_SESSION_DOC = `${firestorePaths.GAMEPLAY_SESSION_COLLECTION}/{${pathPlaceholder.GAMEPLAY_SESSION_ID}}`;
firestorePaths.USER_DOC = `${firestorePaths.USER}/{${pathPlaceholder.USER_ID}}`;
firestorePaths.USER_CONFIG_DOC = `${firestorePaths.USER_CONFIG}/{${pathPlaceholder.USER_ID}}`;
firestorePaths.COACH_DOC = `${firestorePaths.COACH}/{${pathPlaceholder.COACH_ID}}`;
firestorePaths.ACTIVITY_SESSION_COLLECTION = `${firestorePaths.GAMEPLAY_SESSION_DOC}/ActivitySessions`;
firestorePaths.ACTIVITY_SESSION_DOC = `${firestorePaths.ACTIVITY_SESSION_COLLECTION}/{${pathPlaceholder.ACTIVITY_SESSION_ID}}`;
firestorePaths.WORKOUT_FRAMES_COLLECTION = `${firestorePaths.ACTIVITY_SESSION_DOC}/WorkoutFrames`;
firestorePaths.TRACKED_ACTIVITY_DOC = `${firestorePaths.TRACKED_ACTIVITY}/{${pathPlaceholder.TRACKED_ACTIVITY_ID}}`;
firestorePaths.STRIPE_PAYOUT_RECONCILIATION = `${firestorePaths.STRIPE}/payoutReconciliation`;

const storagePaths = {
  USER: 'user',
  BROADCAST_MESSAGES_ATTACHMENTS: 'broadcastMessagesAttachments',
  RECIPE: 'recipe',
  COACH: 'coaches',
  FORM: 'form',
};

storagePaths.USER_COLLECTION = `${storagePaths.USER}/{${pathPlaceholder.USER_ID}}`;
storagePaths.GAMEPLAY_SESSION_COLLECTION = `${storagePaths.USER_COLLECTION}/GameplaySessions`;
storagePaths.GAMEPLAY_SESSION_DOC = `${storagePaths.GAMEPLAY_SESSION_COLLECTION}/{${pathPlaceholder.GAMEPLAY_SESSION_ID}}`;
storagePaths.ACTIVITY_SESSION_COLLECTION = `${storagePaths.GAMEPLAY_SESSION_DOC}/ActivitySessions`;
storagePaths.ACTIVITY_SESSION_DOC = `${storagePaths.ACTIVITY_SESSION_COLLECTION}/{${pathPlaceholder.ACTIVITY_SESSION_ID}}`;
storagePaths.WORKOUT_HISTORY_FRAME = `${storagePaths.ACTIVITY_SESSION_DOC}/{${pathPlaceholder.WORKOUT_HISTORY_FRAME}}.jpg`;
storagePaths.CUSTOM_WORKOUT_COLLECTION = `${storagePaths.USER_COLLECTION}/customWorkout`;
storagePaths.CUSTOM_WORKOUT_DOC = `${storagePaths.USER_COLLECTION}/customWorkout/{${pathPlaceholder.CUSTOM_WORKOUT_ID}}`;
storagePaths.CUSTOM_WORKOUT_ATTACHEMENT = `${storagePaths.CUSTOM_WORKOUT_DOC}/{${pathPlaceholder.FILE_NAME}}`;
storagePaths.TRACKED_ACTIVITY_COLLECTION = `${storagePaths.USER_COLLECTION}/trackedActivity`;
storagePaths.TRACKED_ACTIVITY_DOC = `${storagePaths.TRACKED_ACTIVITY_COLLECTION}/{${pathPlaceholder.TRACKED_ACTIVITY_ID}}`;
storagePaths.TRACKED_ACTIVITY_ATTACHEMENT = `${storagePaths.TRACKED_ACTIVITY_DOC}/{${pathPlaceholder.FILE_NAME}}`;
storagePaths.COACH_BROADCAST_MESSAGES_ATTACHMENTS = `${storagePaths.BROADCAST_MESSAGES_ATTACHMENTS}/{${pathPlaceholder.COACH_ID}}`;
storagePaths.RECIPE_COLLECTION = `${storagePaths.RECIPE}/{${pathPlaceholder.RECIPE_ID}}`;
storagePaths.COACH = `${storagePaths.COACH}/{${pathPlaceholder.COACH_ID}}`;
storagePaths.IS_MSG_ATTACHMENTS = `${storagePaths.COACH}/isMsgAttachments`;
storagePaths.COACH_WELCOME_MESSAGE_ATTACHMENTS = `${storagePaths.COACH}/welcomeMessageAttachments`;
storagePaths.COACH_FORM_COLLECTION = `${storagePaths.FORM}/{${pathPlaceholder.COACH_ID}}`;

export {
  firestorePaths,
  storagePaths,
  pathPlaceholder,
};
