import NutritionalHistory from './NutritionalHistory/NutritionalHistory';
import WorkoutHistory from './WorkoutHistory';
import NotificationSettings from './NotificationSettings/NotificationSettings';
import {
  MealPlanIcon,
  NotesIcon,
  WorkoutHistoryIcon,
  WorkoutIcon,
  SettingsIcon,
} from './styles';
import texts from './texts.json';
import WorkoutCalendar from './WorkoutCalendar';
import Notes from './Notes';

const quickAccessTabs = {
  NOTES: 'NOTES',
  WORKOUT_CALENDAR: 'WORKOUT_CALENDAR',
  WORKOUT_HISTORY: 'WORKOUT_HISTORY',
  NUTRITIONAL_HISTORY: 'NUTRITIONAL_HISTORY',
  CLIENT_SETTINGS: 'CLIENT_SETTINGS',
};

const quickAccessTabsConfig = {
  [quickAccessTabs.NOTES]: {
    label: texts.tabLabels[quickAccessTabs.NOTES],
    icon: NotesIcon,
    component: Notes,
  },
  [quickAccessTabs.WORKOUT_CALENDAR]: {
    label: texts.tabLabels[quickAccessTabs.WORKOUT_CALENDAR],
    icon: WorkoutIcon,
    component: WorkoutCalendar,
  },
  [quickAccessTabs.WORKOUT_HISTORY]: {
    label: texts.tabLabels[quickAccessTabs.WORKOUT_HISTORY],
    icon: WorkoutHistoryIcon,
    component: WorkoutHistory,
  },
  [quickAccessTabs.NUTRITIONAL_HISTORY]: {
    label: texts.tabLabels[quickAccessTabs.NUTRITIONAL_HISTORY],
    icon: MealPlanIcon,
    component: NutritionalHistory,
  },
  [quickAccessTabs.CLIENT_SETTINGS]: {
    label: texts.tabLabels[quickAccessTabs.CLIENT_SETTINGS],
    icon: SettingsIcon,
    component: NotificationSettings,
  },
};

const dynamicSizes = {
  MEDIUM_TABLET: {
    MIN_WIDTH: 561,
    MIN_HEIGHT: 225,
    MAX_WIDTH: 576,
    MAX_HEIGHT: 400,
  },
  TABLET: {
    MIN_WIDTH: 561,
    MIN_HEIGHT: 250,
    MAX_WIDTH: 706,
    MAX_HEIGHT: 524,
  },
  DESKTOP: {
    MIN_WIDTH: 785,
    MIN_HEIGHT: 400,
    MAX_WIDTH: 1201,
    MAX_HEIGHT: 672,
  },
};

function getDeviceType() {
  const width = window.innerWidth;
  if (width <= 640) {
    return 'MEDIUM_TABLET';
  }
  if (width <= 768) {
    return 'TABLET';
  }
  return 'DESKTOP';
}

export {
  quickAccessTabs,
  quickAccessTabsConfig,
  getDeviceType,
  dynamicSizes,
};
