import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TextField } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { forEachSeries } from 'p-iteration';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import WorkoutAssignment from '../../../../Model/WorkoutAssignment';
import Program from '../../../../Model/Program';
import Workout from '../../../../Model/Workout';
import DialogRoundedModal from '../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../components/Button/ActionButtons';
import { CoachingActivity } from '../../../../utils/log';
import useLogger from '../../../../hooks/useLogger';
import ExternalCoachContext from '../../../context/ExternalCoachContext';
import useUserDoc from '../../../hooks/useUserDoc';
import useUpdateInteraction from '../../../hooks/useUpdateInteraction';
import useToast from '../../../hooks/useToast';
import LoadingOverlay from '../../LoadingOverlay';

import {
  DialogBody,
  StyledAutocomplete,
  StyledOption,
  ColumnContainer,
  RowContainer,
  StyledHelperText,
  CheckBoxContainer,
  StyledCheckBox,
  RepeatSettingContainer,
  StyledInputLabel,
  StyledInput,
} from './styles';
import texts from './texts.json';

const DEFAULT_REPETITIONS = 1;

const ProgramAssignmentModal = ({
  showModal,
  onClose,
  clientId,
  selectedDate,
}) => {
  const [programCollection, setProgramCollection] = useState({ docs: [] });
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [isKeepWindowOpen, setIsKeepWindowOpen] = useState(false);
  const [programRepetitions, setProgramRepetitions] = useState(DEFAULT_REPETITIONS);

  const { externalCoachDoc: { id: coachId } } = useContext(ExternalCoachContext);
  const {
    isReady: isClientDocReady,
    userDoc: clientDoc,
  } = useUserDoc(clientId);
  const { updateLastInteraction } = useUpdateInteraction(clientId);
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  useEffect(() => {
    const loadPrograms = async () => {
      const coachProgramCol = await Program.getProgramsByCoach(coachId);
      if (isComponentMountedRef.current) {
        setProgramCollection(coachProgramCol);
        setShowLoading(false);
      }
    };
    loadPrograms();
  }, [coachId, isComponentMountedRef]);

  const programOptions = useMemo(() => programCollection.docs
    .map((program) => ({
      id: program.id,
      label: program.name,
      program,
    })), [programCollection]);

  const onAssignProgram = useCallback(async () => {
    setShowLoading(true);

    const workoutDocsCache = {};
    await forEachSeries(selectedProgram.program.workouts, async ({ workoutRef }, index) => {
      if (workoutRef) {
        if (!workoutDocsCache[workoutRef]) {
          const workout = await Workout.getWorkoutById(workoutRef);
          workoutDocsCache[workoutRef] = workout;
        }
        // Iterate for the amount of repetitions and add the workout in respective days
        let iteration = 0;
        const assignments = [];
        while (iteration < programRepetitions) {
          const day = selectedProgram.program.workouts.length * iteration + index;
          const promise = WorkoutAssignment.assignWorkout(clientDoc, workoutDocsCache[workoutRef], {
            startDate: moment.utc(selectedDate).startOf('day').add(day, 'day'),
            endDate: moment.utc(selectedDate).endOf('day').add(day, 'day'),
            coachId,
            programId: selectedProgram.program.id,
          });
          assignments.push(promise);
          iteration += 1;
        }
        await Promise.all(assignments);
      }
    });
    // Update user's last interaction with the logged in user info
    await updateLastInteraction();
    logCoachingActivity(CoachingActivity.ASSIGNED_PROGRAM, {
      programId: selectedProgram.program.id,
      clientId: clientDoc.id,
    });

    if (isComponentMountedRef.current) {
      showToast(texts.programAssigned);
      setShowLoading(false);
      setSelectedProgram(null);
      if (!isKeepWindowOpen) {
        onClose();
      }
    }
  }, [
    coachId,
    isComponentMountedRef,
    clientDoc,
    updateLastInteraction,
    isKeepWindowOpen,
    onClose,
    programRepetitions,
    selectedDate,
    selectedProgram,
    showToast,
    logCoachingActivity,
  ]);

  return (
    <DialogRoundedModal
      title={texts.title}
      description={selectedDate}
      fullWidth
      maxWidth="sm"
      actionButtons={(
        <ColumnContainer>
          <CheckBoxContainer>
            <StyledCheckBox
              checked={isKeepWindowOpen}
              onChange={() => setIsKeepWindowOpen((prev) => !prev)}
              size="small"
            />
            <StyledHelperText>{texts.keepWindowOpen}</StyledHelperText>
          </CheckBoxContainer>
          <RowContainer>
            <SaveButton
              disabled={!selectedProgram || !(programRepetitions > 0)}
              icon={<CheckCircleOutline />}
              onClick={onAssignProgram}
            >
              {texts.assignProgram}
            </SaveButton>
            <CancelButton onClick={onClose}>{texts.cancel}</CancelButton>
          </RowContainer>
        </ColumnContainer>
      )}
      open={showModal}
      onClose={onClose}
    >
      <DialogBody>
        <StyledAutocomplete
          disablePortal
          value={selectedProgram}
          options={programOptions}
          renderInput={(params) => <TextField {...params} label={texts.selectProgram} />}
          onChange={(event, value) => setSelectedProgram(value)}
          renderOption={(props, {
            id,
            label,
          }) => (
            <StyledOption {...props} key={id}>
              {label}
            </StyledOption>
          )}
        />
        <div>
          <StyledInputLabel>{texts.repeat}</StyledInputLabel>
          <RepeatSettingContainer>
            <StyledInput
              value={programRepetitions}
              onChange={(event) => setProgramRepetitions(event.target.value)}
              type="number"
              required
            />
            <StyledHelperText>{texts.repeatHelperText}</StyledHelperText>
          </RepeatSettingContainer>
        </div>
      </DialogBody>
      <LoadingOverlay isLoading={showLoading || !isClientDocReady} />
    </DialogRoundedModal>
  );
};

ProgramAssignmentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(ProgramAssignmentModal);
