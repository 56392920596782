import styled from 'styled-components';

import { WarningTag } from '../../../../../components/Tags';

const TagList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;

const ExerciseName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 3px;
`;

export {
  TagList,
  WarningTag as ExerciseTag,
  ExerciseName,
  Thumbnail,
};
