import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import {
  ActionButtonContainer,
  StyledTrashIcon,
  StyledCopyIcon,
  StyledUpdateIcon,
} from './styles';
import texts from './texts.json';

const ActionButtons = ({
  onRemove,
  onDuplicate,
  onUpdate,
}) => (

  <ActionButtonContainer>
    <Tooltip title={texts.update}>
      <IconButton onClick={onUpdate}>
        <StyledUpdateIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={texts.delete}>
      <IconButton onClick={onRemove}>
        <StyledTrashIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={texts.duplicate}>
      <IconButton onClick={onDuplicate}>
        <StyledCopyIcon />
      </IconButton>
    </Tooltip>
  </ActionButtonContainer>
);

ActionButtons.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ActionButtons;
