import { useContext, useCallback } from 'react';
import posthog from 'posthog-js';

import config from '../config';
import CustomerIOContext from '../context/CustomerIOContext';
import { isInAppBrowserEnabled } from '../utils/inAppBrowser';
import { isDev } from '../utils/environment';
import { userTraits } from '../utils/traits';
import { EventType } from '../utils/log';
import ExternalCoachContext from '../CoachTools/context/ExternalCoachContext';
import useSessionStore from './useSessionStore';

const globalProps = {
  executionContext: isInAppBrowserEnabled ? 'inAppBrowser' : 'web',
};

const {
  posthog: { apiHost, apiKey },
} = config;

posthog.init(apiKey, { api_host: apiHost });

const useLogger = () => {
  const {
    track: customerIOTrackEvent,
    page: customerIOTrackPage,
  } = useContext(CustomerIOContext);

  const { externalCoachDoc } = useContext(ExternalCoachContext);
  const { userId } = useSessionStore();

  const logEvent = useCallback((eventType, eventProperties = {}, sampleRate = 1.0) => {
    // We log events to Segment
    const props = {
      ...eventProperties,
      ...globalProps,
      ...userTraits,
    };

    if (isDev) {
      // eslint-disable-next-line no-console
      console.debug(eventType, props);
    }

    if (Math.random() < sampleRate) {
      if (window.analytics) {
        window.analytics.track(eventType, props);
      }
      customerIOTrackEvent(eventType, props);
      posthog.capture(eventType, props);
    }
  }, [
    customerIOTrackEvent,
  ]);

  const logCoachingActivity = useCallback((action, eventProperties = {}) => {
    logEvent(EventType.COACHING_ACTIVITY, {
      actionTaken: action,
      coachId: externalCoachDoc.id,
      actionedBy: userId,
      ...eventProperties,
    });
  }, [externalCoachDoc, logEvent, userId]);

  const logPage = useCallback((pageName, eventProperties = {}) => {
    const page = pageName || window.location.pathname.split('/')[1];
    const props = {
      ...eventProperties,
      ...globalProps,
    };

    window.analytics.page(page, props);
    customerIOTrackPage(page, props);
    posthog.capture('$pageview', props);
  }, [
    customerIOTrackPage,
  ]);

  return {
    logEvent,
    logPage,
    logCoachingActivity,
  };
};

export default useLogger;
