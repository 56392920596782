import React from 'react';
import {
  EmojiPeople as EmojiPeopleIcon,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  AssignmentInd as CoachOnboardingIcon,
  RestaurantMenu as RestaurantMenuIcon,
  List as RecipeListIcon,
  Timeline as AnalyticsIcon,
  TrackChanges as TrackChangesIcon,
  Receipt as ReceiptIcon,
  HistoryEdu as FormBuilderIcon,
  AccountCircle as ContractManagerIcon,
  Feed as ReusableContractsIcon,
  RequestQuote as CommissionsIcon,
  EmailRounded as EmailIcon,
} from '@mui/icons-material';

import texts from './texts.json';
import {
  ActivityFeedIcon,
  ChatIcon,
  MealPlannerIcon,
  YourClientIcon,
  WorkoutsManagerIcon,
  LeadIcon,
  ConfigurationIcon,
} from './styles';

const pages = {
  MEAL_PLANNER: 'MEAL_PLANNER',
  ACTIVITY_FEED: 'ACTIVITY_FEED',
  CHAT: 'CHAT',
  WORKOUTS_MANAGER: 'WORKOUTS_MANAGER',
  LEADS: 'LEADS',
  CONFIG: 'CONFIG',
  CRX_DASHBOARD: 'CRX_DASHBOARD',
  CRX_COACH_ONBOARDING: 'CRX_COACH_ONBOARDING',
  CRX_COMMISSIONS: 'CRX_COMMISSIONS',
  NUTRITION_PLANS: 'NUTRITION_PLANS',
  RECIPES: 'RECIPES',
  RECIPE_EDITOR: 'RECIPE_EDITOR',
  INSIDE_SALES_LEADS: 'INSIDE_SALES_LEADS',
  INSIDE_SALES: 'INSIDE_SALES',
  INSIDE_SALES_COMMISSIONS: 'INSIDE_SALES_COMMISSIONS',
  INSIDE_SALES_CONTRACTS_MANAGER: 'CONTRACTS_MANAGER',
  MSG_TEMPLATES: 'MSG_TEMPLATES',
  MANAGE_CLIENTS: 'MANAGE_CLIENTS',
  ANALYTICS: 'ANALYTICS',
  FORMS: 'FORMS',
  REUSABLE_CONTRACTS: 'REUSABLE_CONTRACTS',
  AC_DASHBOARD: 'AC_DASHBOARD',
  COMMISSIONS: 'COMMISSIONS',
  EMAIL_TEMPLATES: 'EMAIL_TEMPLATES',
};

const pagesConfig = {
  [pages.MEAL_PLANNER]: {
    title: texts.mealPlanner,
    icon: <MealPlannerIcon />,
    path: 'mealPlanner',
  },
  [pages.ACTIVITY_FEED]: {
    title: texts.activityFeed,
    icon: <ActivityFeedIcon />,
    path: 'feed',
  },
  [pages.CHAT]: {
    title: texts.chat,
    icon: <ChatIcon />,
    path: 'chat',
  },
  [pages.WORKOUTS_MANAGER]: {
    title: texts.workoutsManager,
    icon: <WorkoutsManagerIcon />,
    path: 'workoutsManager',
  },
  [pages.LEADS]: {
    title: texts.leads,
    icon: <LeadIcon />,
    path: 'leads',
  },
  [pages.CONFIG]: {
    title: texts.configuration,
    icon: <ConfigurationIcon />,
    path: 'config',
  },
  [pages.CRX_DASHBOARD]: {
    title: texts.crxDashboard,
    icon: <DashboardIcon />,
    path: 'dashboard',
  },
  [pages.CRX_COACH_ONBOARDING]: {
    title: texts.crxCoachOnboarding,
    icon: <CoachOnboardingIcon />,
    path: 'coachOnboarding',
  },
  [pages.CRX_COMMISSIONS]: {
    title: texts.crxCommissions,
    icon: <ReceiptIcon />,
    path: 'crxCommissions',
  },
  [pages.RECIPES]: {
    title: texts.recipes,
    icon: <RecipeListIcon />,
    path: 'recipes',
  },
  [pages.RECIPE_EDITOR]: {
    title: texts.recipeEditor,
    icon: <RestaurantMenuIcon />,
    path: 'recipeEditor',
  },
  [pages.INSIDE_SALES_LEADS]: {
    title: texts.INSIDE_SALES_LEADS,
    icon: <EmojiPeopleIcon />,
    path: 'leads',
  },
  [pages.INSIDE_SALES]: {
    title: texts.INSIDE_SALES,
    icon: <TrackChangesIcon />,
    path: 'sales',
  },
  [pages.INSIDE_SALES_COMMISSIONS]: {
    title: texts.INSIDE_SALES_COMMISSIONS,
    icon: <ReceiptIcon />,
    path: 'isCommissions',
  },
  [pages.MANAGE_CLIENTS]: {
    title: texts.yourClients,
    icon: <YourClientIcon />,
    path: 'manageClients',
  },
  [pages.ANALYTICS]: {
    title: texts.analytics,
    icon: <AnalyticsIcon />,
    path: 'analytics',
  },
  [pages.MSG_TEMPLATES]: {
    title: texts.msgTemplates,
    icon: <SettingsIcon />,
    path: 'msgTemplates',
  },
  [pages.EMAIL_TEMPLATES]: {
    title: texts.emailTemplates,
    icon: <EmailIcon />,
    path: 'emailTemplates',
  },
  [pages.INSIDE_SALES_CONTRACTS_MANAGER]: {
    title: texts.INSIDE_SALES_CONTRACTS_MANAGER,
    icon: <ContractManagerIcon />,
    path: 'isContractsManager',
  },
  [pages.FORMS]: {
    title: texts.forms,
    icon: <FormBuilderIcon />,
    path: 'forms',
  },
  [pages.REUSABLE_CONTRACTS]: {
    title: texts.reusableContracts,
    icon: <ReusableContractsIcon />,
    path: 'reusableContracts',
  },
  [pages.AC_DASHBOARD]: {
    title: texts.acDashboard,
    icon: <DashboardIcon />,
    path: 'dashboard',
  },
  [pages.COMMISSIONS]: {
    title: texts.commissions,
    icon: <CommissionsIcon />,
    path: 'commissions',
  },
};

export default pages;

export {
  pagesConfig,
};
