import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SaveIcon } from '../../../assets/icons/v2/save.svg';

import PrimaryButton from './PrimaryButton';

const SaveButton = ({
  onClick,
  children,
  disabled,
  type,
  icon,
}) => (
  <PrimaryButton
    onClick={onClick}
    icon={icon}
    disabled={disabled}
    type={type}
    variant="light"
  >
    {children}
  </PrimaryButton>
);

SaveButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  type: PropTypes.oneOf(['submit', 'button']),
};

SaveButton.defaultProps = {
  children: [],
  onClick: null,
  disabled: false,
  type: 'button',
  icon: <SaveIcon />,
};

export default SaveButton;
