import { useContext } from 'react';
import ActivityFeed from '../pages/ActivityFeed';
import Chat from '../pages/Chat';
import pages, { pagesConfig } from '../config/pages';
import LeadsPage from '../pages/Leads';
import ISLeads from '../pages/InsideSalesDashboard/ISLeads';
import CRXDashboardPage from '../pages/CRXDashboard';
import CRXCoachOnboardingPage from '../pages/CRXCoachOnboarding';
import RecipeEditor from '../pages/MealsPlanner/RecipeEditor/RecipeEditor';
import Analytics from '../pages/Analytics/Analytics';
import EmailTemplates from '../pages/EmailTemplates';
import NavigationContext, { NavigationRouteType } from '../context/NavigationContext';

import CrxCommissions from '../pages/CRXDashboard/CrxCommissions';
import InsideSales from '../pages/InsideSalesDashboard/InsideSales';
import InsideSalesCommissions from '../pages/InsideSalesDashboard/InsideSalesCommissions';
import MessageTemplates from '../pages/MessageTemplates';
import ContractsManager from '../components/ContractsManager/ContractsManager';
import ReusableContracts from '../pages/ReusableContracts';
import ACDashboardPage from '../pages/ACDashboard/CoachingDashboard';
import CommissionsPage from '../pages/InsideSalesDashboard/CommissionsPage';
import MealPlannerRoutes from './mealPlanner/mealPlannerRoutes';
import WorkoutsManagerRoutes from './workoutsManager/workoutsManagerRoutes';
import RecipesRoutes from './recipes';
import FormsRoutes from './forms';
import ConfigurationRoutes from './configurations/ConfigurationRoutes';

import ManageClientsRoutes from './manageClients/ManageClientsRoutes';

const COACH_ROUTES = [
  {
    pageId: pages.MEAL_PLANNER,
    path: pagesConfig[pages.MEAL_PLANNER].path,
    component: MealPlannerRoutes,
  },
  {
    pageId: pages.ACTIVITY_FEED,
    path: pagesConfig[pages.ACTIVITY_FEED].path,
    component: ActivityFeed,
    defaultRoute: true,
  },
  {
    pageId: pages.CHAT,
    path: pagesConfig[pages.CHAT].path,
    component: Chat,
  },
  {
    pageId: pages.WORKOUTS_MANAGER,
    path: pagesConfig[pages.WORKOUTS_MANAGER].path,
    component: WorkoutsManagerRoutes,
  },
  {
    pageId: pages.LEADS,
    path: pagesConfig[pages.LEADS].path,
    component: LeadsPage,
  },
  {
    pageId: pages.CONFIG,
    path: pagesConfig[pages.CONFIG].path,
    component: ConfigurationRoutes,
  },
  {
    pageId: pages.MANAGE_CLIENTS,
    path: pagesConfig[pages.MANAGE_CLIENTS].path,
    component: ManageClientsRoutes,
  },
];

const ADMIN_ROUTES = [
  {
    pageId: pages.CRX_DASHBOARD,
    path: pagesConfig[pages.CRX_DASHBOARD].path,
    component: CRXDashboardPage,
    defaultRoute: true,
  },
  {
    pageId: pages.CRX_COACH_ONBOARDING,
    path: pagesConfig[pages.CRX_COACH_ONBOARDING].path,
    component: CRXCoachOnboardingPage,
  },
  {
    pageId: pages.ANALYTICS,
    path: pagesConfig[pages.ANALYTICS].path,
    component: Analytics,
  },
  {
    pageId: pages.CRX_COMMISSIONS,
    path: pagesConfig[pages.CRX_COMMISSIONS].path,
    component: CrxCommissions,
  },
  {
    pageId: pages.MSG_TEMPLATES,
    path: pagesConfig[pages.MSG_TEMPLATES].path,
    component: MessageTemplates,
  },
  {
    pageId: pages.FORMS,
    path: pagesConfig[pages.FORMS].path,
    component: FormsRoutes,
  },
];

const SUPPORT_ROUTES = [
  {
    pageId: pages.RECIPE_EDITOR,
    path: pagesConfig[pages.RECIPE_EDITOR].path,
    component: RecipeEditor,
  },
  {
    pageId: pages.RECIPES,
    path: pagesConfig[pages.RECIPES].path,
    component: RecipesRoutes,
    defaultRoute: true,
  },
  {
    pageId: pages.ANALYTICS,
    path: pagesConfig[pages.ANALYTICS].path,
    component: Analytics,
  },
  {
    pageId: pages.REUSABLE_CONTRACTS,
    path: pagesConfig[pages.REUSABLE_CONTRACTS].path,
    component: ReusableContracts,
  },
];

const INSIDE_SALES_ROUTES = [
  {
    pageId: pages.INSIDE_SALES_LEADS,
    path: pagesConfig[pages.INSIDE_SALES_LEADS].path,
    component: ISLeads,
    defaultRoute: true,
  },
  {
    pageId: pages.INSIDE_SALES,
    path: pagesConfig[pages.INSIDE_SALES].path,
    component: InsideSales,
  },
  {
    pageId: pages.INSIDE_SALES_COMMISSIONS,
    path: pagesConfig[pages.INSIDE_SALES_COMMISSIONS].path,
    component: InsideSalesCommissions,
  },
  {
    pageId: pages.MSG_TEMPLATES,
    path: pagesConfig[pages.MSG_TEMPLATES].path,
    component: MessageTemplates,
  },
  {
    pageId: pages.ANALYTICS,
    path: pagesConfig[pages.ANALYTICS].path,
    component: Analytics,
  },
  {
    pageId: pages.INSIDE_SALES_CONTRACTS_MANAGER,
    path: pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].path,
    component: ContractsManager,
  },
  // TODO: Move this to an admin-only route. We will limit access to this app for now.
  {
    pageId: pages.COMMISSIONS,
    path: pagesConfig[pages.COMMISSIONS].path,
    component: CommissionsPage,
  },
  {
    pageId: pages.EMAIL_TEMPLATES,
    path: pagesConfig[pages.EMAIL_TEMPLATES].path,
    component: EmailTemplates,
  },
];

const ASSISTANT_COACH_ROUTES = [
  {
    pageId: pages.AC_DASHBOARD,
    path: pagesConfig[pages.AC_DASHBOARD].path,
    component: ACDashboardPage,
    defaultRoute: true,
  },
];

const RoutesMap = {
  [NavigationRouteType.INSIDE_SALES]: INSIDE_SALES_ROUTES,
  [NavigationRouteType.ADMIN]: ADMIN_ROUTES,
  [NavigationRouteType.SUPPORT]: SUPPORT_ROUTES,
  [NavigationRouteType.ASSISTANT_COACH]: ASSISTANT_COACH_ROUTES,
};

const useRoutesConfig = () => {
  const { routeType } = useContext(NavigationContext);

  // If we don't find a route mapped, then we need to redirect the user to the coaches dashboard.
  return RoutesMap[routeType] || COACH_ROUTES;
};

export default useRoutesConfig;
