import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import format from 'string-template';
import * as Sentry from '@sentry/browser';

import { ReactComponent as RevertIcon } from '../../../../../assets/icons/v2/clock-rewind.svg';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import UserContext from '../../../../../context/UserContext';
import FirebaseContext from '../../../../../context/FirebaseContext';
import FormikInput from '../../../../../components/v2/FormikInput';
import {
  HeaderRow,
  TitleContainer,
  Title,
} from '../../../../../components/v2/Header';
import {
  SectionContainer,
  SectionHeaderContainer,
  SectionTitle,
  SectionFooterContainer,
} from '../../../../../components/v2/Section';
import TagsSelector from '../../../../../components/v2/MultiSelectAutoComplete';
import {
  PrimaryButton,
  SaveButton,
  CancelButton,
} from '../../../../../components/Button/ActionButtons';
import { WarningTag } from '../../../../../components/Tags';
import useLogger from '../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../utils/log';
import Exercise, { ExerciseType } from '../../../../Model/Exercise';
import ExerciseTag, {
  TagCategory,
} from '../../../../Model/ExerciseTag';
import ExerciseTagContext from '../../../../context/ExerciseTagContext';
import ExerciseContext from '../../../../context/ExerciseContext';
import useToast from '../../../../hooks/useToast';
import { uploadVideoToVimeo } from '../../../../utils/vimeo';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import TagsModal from '../../Tags/components/TagsModal';
import VideoHandler from './VideoHandler';
import {
  initialValues as startValues,
  validationSchema,
} from './validation';
import fieldName from './formFields';
import {
  listboxStyles,
  Container,
  StyledForm,
  StyledSectionCompartment,
  FormVideoContainer,
  FormFieldsContainer,
  TagsSection,
  TagContainer,
  Label,
  ButtonContainer,
  GuideLineContainer,
  GuidelineTitle,
  GuidelineItem,
  StyledList,
} from './styles';
import texts from './texts';

const ExerciseEditor = ({
  selectedExercise,
  isEditView,
  onClose,
}) => {
  const [isExerciseSubmitting, setIsExerciseSubmitting] = useState(false);
  const [video, setVideo] = useState(null);
  const [isVideoUpdated, setIsVideoUpdated] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const { firebase: { remote } } = useContext(FirebaseContext);
  const { tags } = useContext(ExerciseTagContext);
  const { exercises } = useContext(ExerciseContext);
  const {
    userDoc: {
      id: coachId,
      name: coachName,
    },
  } = useContext(UserContext);
  const { logCoachingActivity } = useLogger();

  const {
    data: docData,
    videoUrl,
    videoPreviewUrl,
    type: exerciseType,
    tags: exerciseTags = [],
  } = selectedExercise || {};

  const [selectedTags, setSelectedTags] = useState(exerciseTags.map(
    ({ id, tag, category }) => ({ id, label: tag, category }),
  ));

  const isBaseExercise = exerciseType === ExerciseType.BASE;

  const tagOptions = useMemo(() => tags.map((doc) => ({
    id: doc.id,
    label: doc.tag.toLowerCase(),
    disabled: doc.category === TagCategory.PLATFORM,
  })), [tags]);

  const onTagSave = async ({ tagName, tagCategory }) => {
    try {
      await ExerciseTag.addCustomTag(tagName, tagCategory, coachId);
      if (isComponentMountedRef.current) {
        showToast(texts.createdNewTag);
        setIsTagModalOpen(false);
      }
    } catch (error) {
      showToast(format(texts.errorCreatingNewTag, { message: error.message }), { error: true });
    }
  };

  const handleVideoChange = useCallback((videoObj) => {
    setVideo(videoObj);
    setIsVideoUpdated(true);
  }, []);

  const isDuplicateExerciseName = useCallback((exerciseName) => {
    /*
     * Ignore the duplication validation if it's being edited and has the same name.
     * This also goes for editing BASE exercises, which makes it possible to create an EDITED exercise
     * with same name as a BASE exercise. This is fine as we filter out the original exercise when an
     * EDITED one is created based off of it.
     */
    if (!!selectedExercise && exerciseName.toLowerCase() === selectedExercise.name.toLowerCase()) {
      return false;
    }
    return exercises.some((exercise) => exercise.name.toLowerCase() === exerciseName.toLowerCase());
  }, [
    exercises,
    selectedExercise,
  ]);

  const handleSubmit = useCallback(async (values, actions) => {
    setIsExerciseSubmitting(true);

    if (isDuplicateExerciseName(values[fieldName.NAME])) {
      showToast(format(texts.duplicateNameError, { exerciseName: values[fieldName.NAME] }), { warning: true });
      setIsExerciseSubmitting(false);
      return;
    }

    // Map the selected tags back to (id, tag) objects except platform tags
    const selectedTagOptions = selectedTags
      .filter(({ category }) => category !== TagCategory.PLATFORM)
      .map(({ id, label }) => ({
        id,
        tag: label,
      }));
    // Create tag ids array
    const selectedTagIds = selectedTagOptions.map(({ id }) => id);

    const newData = {
      ...values,
      /*
       * TODO: Update the created by value to store the ID of the user creating the exercise
       * once the exercise query doesn't use this attribute to filter exercises for a coach
       */
      [fieldName.TAGS]: selectedTagOptions,
      [fieldName.TAG_IDS]: selectedTagIds,
      // We will create a new exercise if we are not editing an existing one, or if we are editing a 'BASE' exercise
      ...(!selectedExercise || isBaseExercise ? {
        [fieldName.CREATED_BY]: coachId,
        [fieldName.CREATED_AT]: new Date(),
      } : {
        [fieldName.MODIFIED_AT]: new Date(),
      }),
    };

    // If a video is present then we need to upload it to vimeo
    if (video) {
      const {
        uploadedLink,
        thumbnailImage,
        errMsg,
      } = await uploadVideoToVimeo(video, coachName, coachId, remote);

      // Capture any error and log to Sentry, and show a warning to the user if the upload link is not received.
      if (errMsg) {
        Sentry.captureMessage(errMsg, {
          extra: {
            coachId,
            exercise: values.name,
          },
        });
      }
      if (!uploadedLink) {
        showToast(texts.vimeoUploadError, { warning: true });
      } else {
        // Add new video link to the doc if successfully uploaded
        newData[fieldName.VIDEO_URL] = uploadedLink;
        newData[fieldName.VIDEO_PREVIEW_URL] = uploadedLink;
        newData[fieldName.VIDEO_PREVIEW_THUMBNAIL] = thumbnailImage;
      }
    }
    // If there is no video but the video was updated, it means the video was removed
    if (!video && isVideoUpdated) {
      newData[fieldName.VIDEO_URL] = '';
      newData[fieldName.VIDEO_PREVIEW_URL] = '';
      newData[fieldName.VIDEO_PREVIEW_THUMBNAIL] = '';
    }

    if (selectedExercise) {
      // If the exercise is updated from a BASE exercise we need to create a new one
      let exerciseId = selectedExercise.id;
      if (isBaseExercise) {
        newData[fieldName.TYPE] = ExerciseType.EDITED;
        newData[fieldName.ORIGINAL_EXERCISE] = selectedExercise.id;

        exerciseId = await Exercise.addDoc(newData);
      } else {
        await selectedExercise.updateFields(newData);
      }
      logCoachingActivity(CoachingActivity.UPDATED_EXERCISE, { exerciseId });
    } else {
      const exerciseId = await Exercise.addDoc(newData);
      logCoachingActivity(CoachingActivity.CREATED_EXERCISE, { exerciseId });
    }
    showToast(format(texts.exerciseUpdated, { exerciseName: values.name }));

    if (isComponentMountedRef.current) {
      setIsExerciseSubmitting(false);
      actions.setSubmitting(false);
      onClose();
    }
  }, [
    isComponentMountedRef,
    isVideoUpdated,
    isBaseExercise,
    isDuplicateExerciseName,
    selectedExercise,
    selectedTags,
    onClose,
    video,
    coachId,
    coachName,
    showToast,
    remote,
    logCoachingActivity,
  ]);

  return (
    <Container>
      <HeaderRow>
        <TitleContainer>
          <Title>
            {isEditView
              ? format(texts.editExerciseTitle, { exerciseType: exerciseType.toLowerCase() })
              : texts.newExerciseTitle}
          </Title>
        </TitleContainer>
      </HeaderRow>
      <Formik
        initialValues={docData || startValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          isSubmitting,
          resetForm,
        }) => (
          <StyledForm>
            <SectionContainer>
              <SectionHeaderContainer>
                <SectionTitle>{isEditView ? texts.editExercise : texts.addExercise}</SectionTitle>
              </SectionHeaderContainer>
              <StyledSectionCompartment>
                <FormVideoContainer>
                  <Label>{`${texts.labels.video}:`}</Label>
                  <VideoHandler
                    videoUrl={videoUrl || videoPreviewUrl || ''}
                    onVideoChange={handleVideoChange}
                    showRevertOption={isBaseExercise}
                  />
                  <GuideLineContainer>
                    <GuidelineTitle>{texts.videoUploadGuidelinesTitle}</GuidelineTitle>
                    <StyledList>
                      {texts.videoUploadGuidelines.map((guideline) => (
                        <GuidelineItem>{guideline}</GuidelineItem>
                      ))}
                    </StyledList>
                  </GuideLineContainer>
                </FormVideoContainer>
                <FormFieldsContainer>
                  <FormikInput
                    name={fieldName.NAME}
                    label={`${texts.field[fieldName.NAME].label}:`}
                  />
                  <FormikInput
                    name={fieldName.DESCRIPTION}
                    label={`${texts.field[fieldName.DESCRIPTION].label}:`}
                    multiline
                    minRows={3}
                    maxRows={3}
                  />
                  <TagsSection>
                    <Label>{`${texts.labels.tags}:`}</Label>
                    <TagsSelector
                      tagOptions={tagOptions}
                      initialValues={selectedTags}
                      onChange={(options) => setSelectedTags(options)}
                      buttonLabel={texts.createTag}
                      onButtonClick={() => setIsTagModalOpen(true)}
                      listboxStyle={listboxStyles}
                    />
                    <TagContainer>
                      {selectedTags.map(({ label }) => (
                        <WarningTag>{label}</WarningTag>
                      ))}
                    </TagContainer>
                  </TagsSection>
                </FormFieldsContainer>
                <ButtonContainer>
                  {isBaseExercise && (
                    <PrimaryButton
                      icon={<RevertIcon />}
                      variant="light"
                      size="medium"
                      type="button"
                      onClick={resetForm}
                    >
                      {texts.revert}
                    </PrimaryButton>
                  )}
                </ButtonContainer>
              </StyledSectionCompartment>
              <SectionFooterContainer>
                <SaveButton
                  disabled={isSubmitting}
                  type="submit"
                >
                  {isEditView ? texts.submitButton : texts.addNewButton}
                </SaveButton>
                <CancelButton onClick={onClose}>
                  {texts.cancelButton}
                </CancelButton>
              </SectionFooterContainer>
            </SectionContainer>
          </StyledForm>
        )}
      </Formik>
      <LoadingOverlay
        isLoading={isExerciseSubmitting}
        loadingText={texts.uploading}
      />
      {isTagModalOpen && (
        <TagsModal
          open={isTagModalOpen}
          onClose={() => setIsTagModalOpen(false)}
          onSave={onTagSave}
        />
      )}
    </Container>
  );
};

ExerciseEditor.propTypes = {
  selectedExercise: PropTypes.object,
  isEditView: PropTypes.bool,
  onClose: PropTypes.func,
};

ExerciseEditor.defaultProps = {
  selectedExercise: {},
  isEditView: false,
  onClose: () => { },
};

export default ExerciseEditor;
