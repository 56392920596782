import styled from 'styled-components';
import Button from '../../../../../components/Button';
import colors from '../../../../../styles/colors';

import QuickChatContainer from '../../../Chat/pages/QuickChat/QuickChatContainer';

const ActionBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 280px);
`;

const StyledChatView = styled(QuickChatContainer)`
  width: 100%;
  height: 100%;
  border: none;
  .str-chat-channel .str-chat__container {
    height: 100vh;
    display: flex;
  }
`;

const Error = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  padding: 20px;
`;

const StyledButton = styled(Button)`
  background-color: ${colors.base.secondary2};
  color: ${colors.base.alpha};
  border-radius: 50%;
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 8px;
  right: 15px;
  top: 74px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.shades.secondary4};
    color: ${colors.base.secondary2};
    opacity: 1;
  }
`;

export {
  ActionBarContainer,
  StyledChatView,
  Error,
  StyledButton,
};
