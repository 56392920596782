import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
import format from 'string-template';

import { DateFormat } from '../../../../utils/date';
import DialogRoundedModal from '../../../../components/DialogRoundedModal';
import RadioButtonGroup from '../../RadioButtonGroup';
import { SaveButton, CancelButton } from '../../../../components/Button/ActionButtons';

import {
  DisableType,
} from '../NotificationSettings/utils';
import {
  ModalContainer,
  ButtonContainer,
  DateInputContainer,
} from './styles';
import texts from './texts';

const NotificationDisableModal = ({
  disabledNotificationType,
  onClose,
  handleSave,
}) => {
  const [disableType, setDisableType] = useState(DisableType.RESUME);
  const [untilDate, setUntilDate] = useState(null);

  const resumeDate = useCallback((type) => {
    switch (type) {
      case DisableType.RESUME:
        return moment(untilDate).toDate();
      case DisableType.INDEFINITELY:
        return moment('3000').toDate();
      default:
        return moment().toDate();
    }
  }, [untilDate]);

  return (
    <DialogRoundedModal
      open={!!disabledNotificationType}
      title={format(texts.disableNotificationTitle, { type: texts.notificationType[disabledNotificationType] })}
      onClose={onClose}
      actionButtons={(
        <>
          <SaveButton
            onClick={() => handleSave(disabledNotificationType, resumeDate(disableType))}
            disabled={disableType === DisableType.RESUME && !untilDate}
          >
            {texts.button.save}
          </SaveButton>
          <CancelButton
            onClick={onClose}
          >
            {texts.button.cancel}
          </CancelButton>
        </>
      )}
    >
      <ModalContainer>
        <ButtonContainer>
          <RadioButtonGroup
            options={[
              {
                label: texts.disableType[DisableType.RESUME],
                value: DisableType.RESUME,
              }, {
                label: texts.disableType[DisableType.INDEFINITELY],
                value: DisableType.INDEFINITELY,
              },
            ]}
            selectedOption={disableType}
            onOptionChange={(value) => setDisableType(value)}
          />
        </ButtonContainer>
        {disableType === DisableType.RESUME && (
          <DateInputContainer>
            <DatePicker
              label={texts.selectADate}
              value={untilDate}
              onChange={(selectDate) => setUntilDate(moment.utc(selectDate).startOf('day'))}
              inputFormat={DateFormat.DEFAULT_DATE_FORMAT}
              minDate={moment().add(1, 'days')}
              mask="____-__-__"
              renderInput={(params) => (
                <TextField
                  {...params}
                />
              )}
            />
          </DateInputContainer>
        )}
      </ModalContainer>
    </DialogRoundedModal>
  );
};

NotificationDisableModal.propTypes = {
  disabledNotificationType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

NotificationDisableModal.defaultProps = {
  disabledNotificationType: null,
};

export default NotificationDisableModal;
