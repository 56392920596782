import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Stack } from '@mui/material';

import useComponentMounted from '../../../hooks/useComponentMounted';
import useSessionStore from '../../../hooks/useSessionStore';
import CoachSelect from '../../components/Leads/components/CoachSelect';
import LoadingOverlay from '../../components/LoadingOverlay';
import ColoredHeader from '../../components/ColoredHeader';
import TemplateList from '../../components/Templates/components/TemplateList';
import TemplateView from '../../components/Templates/components/TemplateView';
import { AssignmentRole } from '../../../utils/internalAssignments';
import EmailTemplate from '../../../Model/EmailTemplate';

import {
  Container,
  Title,
  TemplateContainer,
} from './styles';
import texts from './texts.json';

const EmailTemplates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [coachTemplateCol, setCoachTemplateCol] = useState({});
  const [template, setTemplate] = useState({});

  const isComponentMountedRef = useComponentMounted();
  const { authUser } = useSessionStore();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      let coachTemplateListCollection = await EmailTemplate.getTemplatesByCoach(selectedCoach.id);
      if (!coachTemplateListCollection.hasDocs) {
        coachTemplateListCollection = await EmailTemplate.getDefaultTemplates();
      }
      if (isComponentMountedRef.current) {
        setCoachTemplateCol(coachTemplateListCollection);
        setSelectedTemplateName('');
        setIsLoading(false);
      }
    };
    if (selectedCoach) {
      init();
    }
  }, [
    selectedCoach,
    isComponentMountedRef,
  ]);

  const onTemplateSelect = useCallback((templateName) => {
    setSelectedTemplateName(templateName);
    setTemplate(coachTemplateCol.docs[0].data);
  }, [coachTemplateCol]);

  const saveTemplate = useCallback(async () => {
    const previousTemplate = coachTemplateCol.docs[0];
    setIsLoading(true);
    const newTemplateData = {
      ...template,
      isDefault: false,
      createdAt: new Date(),
      coach: selectedCoach.id,
      createdByUserName: authUser.displayName,
      createdByUser: authUser.uid,
      name: selectedTemplateName,
    };
    if (template.isDefault) {
      await EmailTemplate.addNewTemplate(newTemplateData);
    } else if ((template.emailBody !== previousTemplate.emailBody
        || template.isEnabled !== previousTemplate.isEnabled
        || template.subject !== previousTemplate.subject)) {
      await previousTemplate.update({
        ...template,
        updatedAt: new Date(),
        updatedByUser: authUser.uid,
        updatedByUserName: authUser.displayName,
      });
    }
    if (isComponentMountedRef.current) {
      setIsLoading(false);
    }
  }, [
    selectedCoach,
    authUser,
    coachTemplateCol,
    template,
    isComponentMountedRef,
    selectedTemplateName,
  ]);

  return (
    <Container>
      <ColoredHeader>
        <Stack spacing={5}>
          <Title>{texts.emailTemplates}</Title>
          <CoachSelect role={AssignmentRole.INSIDE_SALES} onChange={setSelectedCoach} />
        </Stack>
      </ColoredHeader>
      {!!selectedCoach && coachTemplateCol.hasDocs && (
        <TemplateContainer>
          <TemplateList
            selectedTemplateName={selectedTemplateName}
            templates={coachTemplateCol.docs}
            onTemplateSelect={onTemplateSelect}
          />
          {!!selectedTemplateName && (
            <TemplateView
              template={coachTemplateCol.docs[0]}
              msgBody={template.emailBody}
              subject={template.subject}
              onSubjectChange={(subject) => setTemplate((prev) => ({ ...prev, subject }))}
              onMsgBodyChange={(emailBody) => setTemplate((prev) => ({ ...prev, emailBody }))}
              onSaveTemplate={saveTemplate}
              isMessageTemplateEnabled={template.isEnabled}
              onChangeMessageTemplateState={(isEnabled) => setTemplate((prev) => ({ ...prev, isEnabled }))}
              isEmailTemplateView
            />
          )}
        </TemplateContainer>
      )}
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

export default EmailTemplates;
