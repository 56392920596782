import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { useRouteMatch } from 'react-router-dom';

import useComponentMounted from '../../../hooks/useComponentMounted';
import Exercise from '../../Model/Exercise';
import ExerciseContext, { initialValues } from './ExerciseContext';

/**
 * @namespace ExerciseContextProvider
 *
 * @description Provides a context for managing and accessing exercise data for a coach.
 *
 * **Data Accessible from this Context:**
 * - **isReady**: The readiness of the context.
 * - **exercises**: The collection of base exercises and coach specific exercises.
 */
const ExerciseContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [baseExercisesCollection, setBaseExercisesCollection] = useState(initialValues.baseExercisesCollection);
  const [coachExercisesCollection, setCoachExercisesCollection] = useState(initialValues.coachExercisesCollection);
  const [exercises, setExercises] = useState(initialValues.exercises);
  const [showArchivedExercises, setShowArchivedExercises] = useState(initialValues.showArchivedExercises);
  const [isLoading, setIsLoading] = useState(initialValues.isLoading);

  const isComponentMountedRef = useComponentMounted();

  const {
    params: {
      userId: coachId,
    },
  } = useRouteMatch();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const baseExercisesCol = await Exercise.getBaseExercises();
      const coachExercisesCol = await Exercise.getExercisesforCoach(coachId, initialValues.showArchivedExercises);

      if (isComponentMountedRef.current) {
        setIsLoading(false);
        setBaseExercisesCollection(baseExercisesCol);
        setCoachExercisesCollection(coachExercisesCol);
      }
    };
    init();
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  useEffect(() => {
    const disposer = autorun(() => {
      const baseExercisesDocs = baseExercisesCollection.docs.slice();
      const coachExercisesDocs = coachExercisesCollection.docs.slice();

      // Remove base exercises if there are edited exercises created from them
      coachExercisesDocs.forEach((coachExercise) => {
        const { originalExercise } = coachExercise;
        if (originalExercise) {
          const originalExerciseIndex = baseExercisesDocs.findIndex(
            (baseExercise) => baseExercise.id === originalExercise,
          );
          baseExercisesDocs.splice(originalExerciseIndex, 1);
        }
      });

      const exerciseDocs = [
        ...baseExercisesDocs,
        ...coachExercisesDocs,
      ];

      // Sort the exercises by name
      exerciseDocs.sort((a, b) => a.name.localeCompare(b.name));

      if (isComponentMountedRef.current) {
        setExercises(exerciseDocs);
        setIsReady(true);
      }
    });

    return disposer;
  }, [
    baseExercisesCollection.docs,
    coachExercisesCollection.docs,
    isComponentMountedRef,
  ]);

  const refetchExercises = useCallback(async (fetchArchivedPlans = false) => {
    setIsLoading(true);
    setShowArchivedExercises(fetchArchivedPlans);
    const coachExercisesCol = await Exercise.getExercisesforCoach(coachId, fetchArchivedPlans);
    if (isComponentMountedRef.current) {
      setCoachExercisesCollection(coachExercisesCol);
      setIsLoading(false);
    }
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    exercises,
    showArchivedExercises,
    refetchExercises,
    isLoading,
  }), [
    isReady,
    exercises,
    showArchivedExercises,
    refetchExercises,
    isLoading,
  ]);

  return (
    <ExerciseContext.Provider value={contextValue}>
      {children}
    </ExerciseContext.Provider>
  );
};

ExerciseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ExerciseContextProvider);
