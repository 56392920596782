import { Collection } from 'firestorter';
import Collections from '../../utils/collections';
import { MeasurementUnit } from '../../utils/measurement';
import BaseDocument from '../../Model/BaseDocument';
import { ActivityLevel } from './UserActivityProfile';

/**
 * User weight goal.
 */
const WeeklyWeightGoal = {
  GAIN_WEIGHT: 'GAIN_WEIGHT',
  MAINTAIN_WEIGHT: 'MAINTAIN_WEIGHT',
  LOSE_LITTLE_WEIGHT: 'LOSE_LITTLE_WEIGHT',
  LOSE_MORE_WEIGHT: 'LOSE_MORE_WEIGHT',
};

/**
 * How often does the user exercise.
 */
const WorkoutDaysType = {
  NONE: 'NONE', // 0 per week
  SOME: 'SOME', // 1-3x per week
  OFTEN: 'OFTEN', // 3+ per week
};

/**
 * User level of activity.
 */
const WorkoutDaysToActivityLevel = {
  [WorkoutDaysType.NONE]: ActivityLevel.SEDENTARY,
  [WorkoutDaysType.SOME]: ActivityLevel.MODERATE,
  [WorkoutDaysType.OFTEN]: ActivityLevel.ACTIVE,
};

/**
 * User dietary restrictions.
 */
const DietaryRestriction = {
  VEGAN: 'VEGAN',
  VEGETARIAN: 'VEGETARIAN',
  GLUTEN_FREE: 'GLUTEN_FREE',
  DAIRY_FREE: 'DAIRY_FREE',
  SOY_FREE: 'SOY_FREE',
  SHELLFISH_FREE: 'SHELLFISH_FREE',
  EGG_FREE: 'EGG_FREE',
  FISH_FREE: 'FISH_FREE',
  PEANUT_FREE: 'PEANUT_FREE',
  SUGAR_FREE: 'SUGAR_FREE',
  TREE_NUTS_FREE: 'TREE_NUTS_FREE',
  RED_MEAT_FREE: 'RED_MEAT_FREE',
  PORK_FREE: 'PORK_FREE',
  BEEF_FREE: 'BEEF_FREE',
  NUTS_FREE: 'NUTS_FREE',
};

/**
 * User dietary restrictions as text.
 */
const DietaryRestrictionText = {
  [DietaryRestriction.VEGAN]: 'Vegan',
  [DietaryRestriction.VEGETARIAN]: 'Vegetarian',
  [DietaryRestriction.GLUTEN_FREE]: 'Gluten free',
  [DietaryRestriction.DAIRY_FREE]: 'Dairy free',
  [DietaryRestriction.SOY_FREE]: 'Soy free',
  [DietaryRestriction.SHELLFISH_FREE]: 'Shellfish free',
  [DietaryRestriction.EGG_FREE]: 'Egg free',
  [DietaryRestriction.FISH_FREE]: 'Fish free',
  [DietaryRestriction.PEANUT_FREE]: 'Peanut free',
  [DietaryRestriction.SUGAR_FREE]: 'Sugar free',
  [DietaryRestriction.TREE_NUTS_FREE]: 'Tree nuts free',
  [DietaryRestriction.RED_MEAT_FREE]: 'Red meat free',
  [DietaryRestriction.PORK_FREE]: 'Pork free',
  [DietaryRestriction.BEEF_FREE]: 'Beef free',
  [DietaryRestriction.NUTS_FREE]: 'Nuts free',
};

/**
 * Class representing a user nutrition profile.
 * This information is gathered when the user completes the post-payment form after they subscribe.
 * It is used to access dietary and physical information of the user to track their progress.
 *
 * @class UserNutritionProfile
 * @extends BaseDocument
 */
class UserNutritionProfile extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.USER_NUTRITION_PROFILE}/${id}`, opts);
  }

  /**
   * Array of dietary restrictions
   * @return {string[]}
  */
  get dietaryRestrictions() {
    return this.data.dietaryRestrictions || [];
  }

  /**
   * Weekly weight goal.
   * @return {string}
  */
  get weeklyWeightGoal() {
    return this.data.weeklyWeightGoal || '';
  }

  /**
   * User biological sex.
   * @return {string}
  */
  get biologicalSex() {
    return this.data.biologicalSex;
  }

  /**
   * Height measurement unit depending on the user selected system.
   * @return {string}
  */
  get heightMeasurementUnit() {
    return this.data.heightFt
      ? MeasurementUnit.IMPERIAL
      : MeasurementUnit.METRIC;
  }

  /**
   * Weight measurement unit depending on the user selected system.
   * @return {string}
  */
  get weightMeasurementUnit() {
    return this.data.currentWeightLbs
      ? MeasurementUnit.IMPERIAL
      : MeasurementUnit.METRIC;
  }

  /**
   * Current user weight.
   * @return {number}
  */
  get currentWeight() {
    return this.weightMeasurementUnit === MeasurementUnit.METRIC
      ? this.data.currentWeightKgs
      : this.data.currentWeightLbs;
  }

  /**
   * Long term weight goal measurement unit depending on the user selected system.
   * @return {string|void}
  */
  get longTermWeightGoalMeasurementUnit() {
    if (this.data.longTermWeightGoalLbs || this.data.longTermWeightGoalKgs) {
      return this.data.longTermWeightGoalLbs ? MeasurementUnit.IMPERIAL : MeasurementUnit.METRIC;
    }
    return null;
  }

  /**
   * Long term weight goal.
   * @return {number|void}
  */
  get longTermWeightGoal() {
    if (this.longTermWeightGoalMeasurementUnit) {
      return this.longTermWeightGoalMeasurementUnit === MeasurementUnit.METRIC
        ? this.data.longTermWeightGoalKgs
        : this.data.longTermWeightGoalLbs;
    }
    return null;
  }

  /**
   * User height.
   * @return {number}
  */
  get height() {
    return this.heightMeasurementUnit === MeasurementUnit.METRIC
      ? this.data.heightCm
      : { ft: this.data.heightFt, in: this.data.heightIn };
  }

  /**
   * Timestamp of when the info was last updated.
   * @return {number}
  */
  get lastUpdated() {
    return this.data.lastUpdated;
  }

  /**
   * Returns a UserNutritionProfile by a given user id.

   * @param {string} id - The id of the user associated to the UserNutritionProfile.
   * @return {Promise<UserNutritionProfile|void>} A promise that resolves to a
   * UserNutritionProfile, or null if it's not found.
   */
  static async getById(id) {
    const nutritionProfileDoc = new UserNutritionProfile(id);
    await nutritionProfileDoc.init();
    return nutritionProfileDoc.exists ? nutritionProfileDoc : null;
  }

  /**
   * Returns the collection of UserNutritionProfiles by a given coach id.

   * @param {string} coachId - The id of the coach associated to the UserNutritionProfiles.
   * @return {Promise<Collection>} A promise that resolves to a collection of UserNutritionProfiles.
   */
  static async getProfilesByCoach(coachId) {
    const collection = new Collection(Collections.USER_NUTRITION_PROFILE, {
      createDocument: ({ id }, opts) => new UserNutritionProfile(id, opts),
      query: (ref) => ref.where('coach', '==', coachId),
    });
    await collection.fetch();
    return collection;
  }
}

export default UserNutritionProfile;
export {
  WeeklyWeightGoal,
  DietaryRestriction,
  DietaryRestrictionText,
  WorkoutDaysToActivityLevel,
};
