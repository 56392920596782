import { ActionType } from '../utils';
import {
  TagCategory,
} from '../../../../Model/ExerciseTag';

const texts = {
  title: 'All Exercises',
  newExercise: 'New Exercise',
  searchPlaceholder: 'Search by exercise name',
  moreTags: '+{amount} More',
  emptyText: '-',
  archivedCheckbox: 'Show archived Exercises',
  customACtivity: 'Custom Activity',
  action: {
    [ActionType.ARCHIVE]: 'archive',
    [ActionType.UNARCHIVE]: 'unarchive',
    [ActionType.UPDATE]: 'update',
  },
  actionText: 'Are you sure you want to {action} exercise: {exercise}',
  successfullyProcessed: 'Exercise {action}d successfully',
  columns: {
    name: 'Exercise Name',
    type: 'Source Type',
    description: 'Description',
    tags: 'Tags',
    actions: 'Actions',
    equipments: 'Equipments',
  },
  tagCategoryOption: {
    [TagCategory.EXERCISE_TYPE]: 'Exercise Type',
    [TagCategory.EQUIPMENT]: 'Equipment',
    [TagCategory.GENERIC]: 'Generic',
    [TagCategory.BODY_REGION_ADVANCED]: 'Body Region Advanced',
    [TagCategory.BODY_REGION_BASIC]: 'Body Region Basic',
    [TagCategory.PLATFORM]: 'Platform',
  },
};

export default texts;
