import fieldName from './formFields';

const texts = {
  title: 'Workout Designer',
  addWorkout: 'Add New Workout',
  editWorkout: 'Edit Workout',
  editWorkoutAssignment: 'Edit Workout Assignment',
  workoutUpdated: 'The workout {workoutName} has been saved successfully',
  workoutAssignmentUpdated: 'The workout Assignment {workoutName} has been updated successfully',
  validation: {
    requiredField: 'Required',
  },
  saveNewButton: 'Save as New Workout',
  submitButton: {
    saveWorkout: 'Save Workout',
    saveWorkoutAssignment: 'Save Workout Assignment',
  },

  cancelButton: 'Cancel',
  field: {
    [fieldName.NAME]: {
      label: 'Workout Name',
    },
    [fieldName.NOTE]: {
      label: 'Workout Description',
    },
  },
};

export default texts;
