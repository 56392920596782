import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ReviewIcon } from '../../../../../../assets/icons/v2/arrow-right.svg';
import { ReactComponent as ArchiveIcon } from '../../../../../../assets/icons/v2/archive.svg';
import { ReactComponent as AddIcon } from '../../../../../../assets/icons/v2/add.svg';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import { ExerciseType } from '../../../../../Model/Exercise';
import { ActionType } from '../../utils';

import {
  Container,
} from './styles';
import texts from './texts.json';

const ActionButtons = ({
  row,
  handleActionClick,
  isWorkoutDesignerView,
}) => (
  <Container>
    {!isWorkoutDesignerView && (
      <>
        <PrimaryButton
          onClick={() => handleActionClick(row, row.isArchived ? ActionType.UNARCHIVE : ActionType.ARCHIVE)}
          icon={<ArchiveIcon />}
          disabled={row.type === ExerciseType.BASE}
          variant="info"
          size="medium"
        >
          {row.isArchived ? texts.unarchive : texts.archive}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => handleActionClick(row, ActionType.UPDATE)}
          endIcon={<ReviewIcon />}
          variant="info"
          size="medium"
        >
          {texts.update}
        </PrimaryButton>
      </>
    )}
    {isWorkoutDesignerView && (
      <PrimaryButton
        onClick={() => handleActionClick(row)}
        icon={<AddIcon />}
        variant="info"
        size="medium"
      >
        {texts.add}
      </PrimaryButton>
    )}

  </Container>
);

ActionButtons.propTypes = {
  row: PropTypes.object.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  isWorkoutDesignerView: PropTypes.bool.isRequired,
};

export default ActionButtons;
