import React, {
  useCallback,
  useMemo,
  useContext,
  useState,
} from 'react';
import format from 'string-template';
import {
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { compose } from 'recompose';

import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/v2/check-circle.svg';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';
import { getQueryVariable } from '../../../../../../utils/queryParams';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import UserContext from '../../../../../../context/UserContext';
import { CoachingActivity } from '../../../../../../utils/log';
import useLogger from '../../../../../../hooks/useLogger';
import { MealPlannerType, getTabPath } from '../../../../../routes/mealPlanner/mealPlannerPages';
import useMealPlanNavigation from '../../../../../hooks/useMealPlanNavigation';
import useUpdateInteraction from '../../../../../hooks/useUpdateInteraction';
import MealPlanAssignment from '../../../../../Model/MealPlanAssignment';
import { withMealPlanContextReady } from '../../../../../context/MealPlanContext';
import MealPlanAssignmentContext, {
  withMealPlanAssignmentContextProvider,
  withMealPlanAssignmentContextReady,
} from '../../../../../context/MealPlanAssignmentContext';
import ChatButton from '../../../../../components/ChatButton';
import ClientQuickInfoButton from '../../../../../components/ClientQuickInfoButton';
import UserPhysicalAttributes from '../../../components/UserPhysicalAttributes';
import UserNutritionalGoals from '../../../components/UserNutritionalGoals';
import UserMealPlan, { MealPlanAssignOption } from '../../../components/UserMealPlan';
import { TitleIconsContainer } from '../../styles';

import {
  Container,
  UserInfoContainer,
  StyledAvatar,
  UserInfoTitle,
  SectionContainer,
} from './styles';
import texts from './texts.json';

const MealPlanRefresh = () => {
  // Get refresh user id, redirect to meal plan dashboard if not present
  const user = getQueryVariable('user');

  // Access state from react router to know from where the component was initialized
  const location = useLocation();
  const {
    state: {
      isRoutedFromFeed = false,
    } = {},
  } = location;

  const history = useHistory();

  const isComponentMountedRef = useComponentMounted();

  const {
    mealPlanAssignmentDoc = {},
    userDoc = {},
    nutritionProfileDoc = {},
    macroGoalsDoc = {},
    nutritionalGoals = {},
    setNutritionalGoals,
    newMealPlanData = {},
    selectedWeightGoal,
    setSelectedWeightGoal,
    assignmentNote,
  } = useContext(MealPlanAssignmentContext);

  const {
    navigateToAssignments,
  } = useMealPlanNavigation();

  const {
    userId: coachId,
  } = useContext(UserContext);

  const {
    id: userId,
    name: userName,
    avatarUrl: userAvatar,
  } = userDoc;

  const { updateLastInteraction } = useUpdateInteraction(userId);

  const [isSaving, setIsSaving] = useState(false);
  const { logCoachingActivity } = useLogger();

  const renderAvatar = useCallback(() => {
    if (userAvatar) {
      return <StyledAvatar src={userAvatar} />;
    }
    return <StyledAvatar>{userName?.substring(0, 1)}</StyledAvatar>;
  }, [
    userAvatar,
    userName,
  ]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    // Update weight goal
    await nutritionProfileDoc?.updateFields({ weeklyWeightGoal: selectedWeightGoal });
    // Save previous goals
    await macroGoalsDoc?.saveHistory(
      mealPlanAssignmentDoc?.mealPlanId,
      mealPlanAssignmentDoc?.name,
      mealPlanAssignmentDoc?.macroAverages,
    );

    // If its recommended, remove macroAverages property as it is calculated later using calories.
    let newNutritionalGoals = nutritionalGoals;
    if (nutritionalGoals?.isRecommended) {
      const {
        isRecommended,
        macroAverages,
        ...rest
      } = nutritionalGoals;
      newNutritionalGoals = rest;
    }

    // Update nutritional goals
    await macroGoalsDoc?.updateMacroGoals(newNutritionalGoals);

    // Update meal plan assignment and assignment note, create doc when assigning for the first time
    if (!mealPlanAssignmentDoc) {
      const newDoc = await MealPlanAssignment.addNewAssignment({
        user,
        mealPlanData: newMealPlanData,
        coach: coachId,
      });
      await newDoc.setCustomMealPlanIntroMessage(assignmentNote);
      logCoachingActivity(CoachingActivity.ASSIGNED_MEAL_PLAN, {
        mealPlanId: newMealPlanData.mealPlanId,
        clientId: user,
      });
    } else {
      await mealPlanAssignmentDoc.updateMealPlan(newMealPlanData);
      await mealPlanAssignmentDoc.setCustomMealPlanIntroMessage(assignmentNote);
      logCoachingActivity(CoachingActivity.UPDATED_ASSIGNED_MEAL_PLAN, {
        mealPlanId: newMealPlanData.mealPlanId,
        clientId: user,
      });
    }

    // Update user's last interaction with the logged in user info
    await updateLastInteraction();

    if (isComponentMountedRef.current) {
      // Redirect to feed if the assignment was triggered from there
      if (isRoutedFromFeed) {
        history.goBack();
      } else {
        navigateToAssignments({
          clearSearch: true,
          replace: true,
        });
      }
    }
  }, [
    selectedWeightGoal,
    nutritionalGoals,
    newMealPlanData,
    assignmentNote,
    nutritionProfileDoc,
    macroGoalsDoc,
    mealPlanAssignmentDoc,
    isComponentMountedRef,
    navigateToAssignments,
    user,
    coachId,
    history,
    isRoutedFromFeed,
    updateLastInteraction,
    logCoachingActivity,
  ]);

  const isSaveDisabled = useMemo(() => {
    // Meal times are valid if all meal times are not empty
    const isMealTimesValid = newMealPlanData.mealTimes && !newMealPlanData.mealTimes.some((mealTime) => (
      mealTime.meals && mealTime.meals.length === 0
    ));
    return !selectedWeightGoal || !nutritionalGoals?.updatedMacroAveragesAt || !isMealTimesValid;
  }, [
    selectedWeightGoal,
    nutritionalGoals,
    newMealPlanData,
  ]);

  if (!user) {
    return <Redirect to={getTabPath(MealPlannerType.CLIENT_MEAL_ASSIGNMENTS)} />;
  }

  return (
    <Container>
      <UserInfoContainer>
        {renderAvatar()}
        <UserInfoTitle>
          {format(texts.title, { userName })}
          <TitleIconsContainer>
            <ChatButton
              userId={userId}
              popperPlacement="right-start"
              iconVariant="secondary"
              smallButton
            />
            <ClientQuickInfoButton userId={userId} />
          </TitleIconsContainer>
        </UserInfoTitle>
      </UserInfoContainer>
      <SectionContainer>
        <UserPhysicalAttributes
          onSelectWeightGoal={setSelectedWeightGoal}
        />
      </SectionContainer>
      <SectionContainer>
        <UserNutritionalGoals
          onSelectNutritionalGoals={setNutritionalGoals}
        />
      </SectionContainer>
      <SectionContainer>
        <UserMealPlan
          userId={user}
          scaleMeals={!!nutritionalGoals?.totalDailyCalories}
          totalDailyCalories={nutritionalGoals?.totalDailyCalories}
          macrosPercentages={nutritionalGoals?.macroAverages?.percentages
            || macroGoalsDoc?.macroAverages?.percentages
            || newMealPlanData?.macroAverages?.percentages}
          defaultAction={!mealPlanAssignmentDoc
            ? MealPlanAssignOption.NEW_MEAL_PLAN
            : MealPlanAssignOption.USE_PREVIOUS_MEAL_PLAN}
        />
      </SectionContainer>
      <SectionContainer>
        <PrimaryButton
          icon={<CheckIcon />}
          onClick={handleSave}
          disabled={isSaving || isSaveDisabled}
        >
          {texts.approveMealPlan}
        </PrimaryButton>
      </SectionContainer>
    </Container>
  );
};

export default compose(
  withMealPlanContextReady,
  withMealPlanAssignmentContextProvider,
  withMealPlanAssignmentContextReady,
)(MealPlanRefresh);
