import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { computed } from 'mobx';
import { CircularProgress } from '@mui/material';

import useSessionStore from '../../../../../hooks/useSessionStore';
import LoadingPage from '../../../../../components/LoadingPage';
import FeedContext from '../../../../context/FeedContext';
import FeedState from '../../../../context/FeedContext/states';
import FeedType from '../../../../context/FeedContext/config/feedTypes';
import FeedActivity from '../FeedActivity';
import FeedEmptyView from '../FeedEmptyView';
import { quickAccessTabs } from '../../../../components/ClientQuickInfoModal/utils';
import QAPContext from '../../../../../context/QAPContext';
import { feedOrderTypes } from '../../../../../utils/feed';

import { FeedContainer, StyledLoaderContainer } from './styles';

const DEFAULT_ACTIVITIES_PER_PAGE = 10;
const CHECK_IN_ACTIVITIES_PER_PAGE = 4;

const Feed = () => {
  const {
    authUser: {
      uid: authUserId,
    } = {},
    isCoachAssistant,
  } = useSessionStore();
  const {
    selectedFeedType,
    feedActivityList,
    archivedActivityList,
    feedState,
  } = useContext(FeedContext);

  const listRef = useRef();
  const [page, setPage] = useState(1);
  const [listItems, setListItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { openQAP } = useContext(QAPContext);

  /*
    we need to handle action click here because most of the time when we complete an action,
    feed item will be removed from the list and what ever we do in FeedActivity will be unmounted
  */
  const onActivityActionClick = useCallback((feedType, userId) => {
    if (feedType === FeedType.NEED_NEW_WORKOUTS) {
      openQAP(userId, quickAccessTabs.WORKOUT_CALENDAR);
    }
  }, [openQAP]);

  /*
   * Sorts feed items into buckets and orders them based on the criteria.
   */
  const sortFeedItems = useCallback((feedItems) => {
    const buckets = {
      selfClaimed: [],
      selfAssigned: [],
      unassigned: [],
      assigned: [],
      claimed: [],
    };

    // Bucket the feed items
    feedItems.forEach((feedItem) => {
      const {
        lastInteractionBy,
        claimedBy,
      } = feedItem;
      if (claimedBy === authUserId) {
        // Item claimed by the logged in user
        buckets.selfClaimed.push({ ...feedItem, orderType: feedOrderTypes.SELF_CLAIMED });
      } else if (!claimedBy && lastInteractionBy === authUserId) {
        // Item unclaimed, but attached to the logged in user due to last interaction
        buckets.selfAssigned.push({ ...feedItem, orderType: feedOrderTypes.SELF_ASSIGNED });
      } else if (!claimedBy && !lastInteractionBy) {
        // Item unclaimed and unassigned to any user
        buckets.unassigned.push(feedItem);
        // TODO: replace above line with the code line below after a couple of days of releasing work item ordering
        // buckets.unassigned.push({ ...feedItem, orderType: feedOrderTypes.UNASSIGNED });
      } else if (!claimedBy && !!lastInteractionBy) {
        // Item unclaimed, but assigned to a different user
        buckets.assigned.push({ ...feedItem, orderType: feedOrderTypes.ASSIGNED });
      } else if (claimedBy) {
        // Item claimed by a different user
        buckets.claimed.push({ ...feedItem, orderType: feedOrderTypes.CLAIMED });
      }
    });

    // Concatenate buckets in the specified order
    return [
      ...buckets.selfClaimed,
      ...buckets.selfAssigned,
      ...buckets.unassigned,
      ...buckets.assigned,
      ...buckets.claimed,
    ];
  }, [authUserId]);

  const currentFeedList = useMemo(() => computed(() => {
    const feedList = selectedFeedType === FeedType.ARCHIVED ? archivedActivityList : feedActivityList;
    const filteredFeedList = feedList.reduce((arr, current) => {
      if (current.type !== selectedFeedType) {
        return arr;
      }
      const found = arr.find((activity) => current.workoutAssignmentId
        && activity.workoutAssignmentId === current.workoutAssignmentId);
      const curr = {
        ...current.data,
        id: current.id,
        claimActivity: current.claimActivity,
        unclaimActivity: current.unclaimActivity,
      };
      if (!found) {
        curr.id = [curr.id];
        arr.push(curr);
      } else {
        found.content = `${curr.content}\n\n${found.content}`;
        found.id = [...found.id, curr.id];
        if (curr.workoutVideoAvailable) {
          found.workoutVideoAvailable = curr.workoutVideoAvailable;
          found.user = curr.user;
          found.gameplaySessionId = curr.gameplaySessionId;
        }
      }
      return arr;
    }, []);

    // If the user is an AC, sort the feed items accordingly
    if (isCoachAssistant) {
      return sortFeedItems(filteredFeedList);
    }
    // If not, return the feed list as it is
    return filteredFeedList;
  }), [
    feedActivityList,
    selectedFeedType,
    archivedActivityList,
    sortFeedItems,
    isCoachAssistant,
  ]).get();

  useEffect(() => {
    setListItems([]);
    setPage(1);
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [
    selectedFeedType,
  ]);

  useEffect(() => {
    const activitiesPerPage = (selectedFeedType === FeedType.CHECK_IN)
      ? CHECK_IN_ACTIVITIES_PER_PAGE
      : DEFAULT_ACTIVITIES_PER_PAGE;
    const endIndex = activitiesPerPage * page;
    const items = currentFeedList.slice(0, endIndex);
    setListItems(items);
  }, [
    currentFeedList,
    page,
    selectedFeedType,
  ]);

  const handleScroll = useCallback((e) => {
    const element = e.target;
    // check if user has scrolled to the bottom of the current page
    // we subtract 30px to load the next page before user reaches the bottom for smoother UX
    if (Math.floor(element.scrollHeight - element.scrollTop) - 30 < element.clientHeight) {
      // if we have already loaded all the activities, do nothing
      if (currentFeedList.length === listItems.length) {
        return;
      }
      setIsLoading(true);
      const nextPage = page + 1;
      setPage(nextPage);
      setIsLoading(false);
    }
  }, [
    page,
    currentFeedList,
    listItems,
  ]);

  if (feedState !== FeedState.FEED_INITIALIZED) {
    return <LoadingPage />;
  }

  if (currentFeedList.length === 0) {
    return (
      <FeedContainer>
        <FeedEmptyView
          activityType={selectedFeedType}
        />
      </FeedContainer>
    );
  }

  return (
    <FeedContainer onScroll={handleScroll} ref={listRef}>
      {listItems.map((activity) => (
        <div key={activity.id}>
          <FeedActivity
            activity={activity}
            userId={activity.user}
            onActivityActionClick={onActivityActionClick}
          />
        </div>
      ))}
      {isLoading && <StyledLoaderContainer><CircularProgress /></StyledLoaderContainer>}
    </FeedContainer>
  );
};

export default compose(
  observer,
)(Feed);
