import {
  DurationType,
  SideType,
} from '../utils';
import { TimeUnit } from '../../../../../utils/time';
import FormField from './formFields';

const texts = {
  title: {
    addRootActivity: 'Add "{activityName}" as a Root Activity',
    editRootActivity: 'Edit "{activityName}" Root Activity',
    addCircuitActivity: 'Add "{activityName}" as a Circuit Activity',
    editCircuitActivity: 'Edit "{activityName}" Circuit Activity',
  },
  button: {
    add: 'Add Activity',
    update: 'Update Activity',
  },
  exerciseVideoPreview: 'Exercise Video Preview',
  none: 'None',
  validation: {
    requiredField: 'Required',
    positiveNumber: 'Only positive numbers',
    integerNumber: 'Only integer numbers',
  },
  fieldLabel: {
    [FormField.NOTE]: 'Note',
    [FormField.SIDE]: 'Side',
    [FormField.SET]: 'Set',
    [FormField.DURATION]: 'Duration',
    [FormField.SET_TIME]: 'Time',
    [FormField.REST_TIME]: 'Rest',
    [FormField.REPETITION]: 'Repetition',
  },
  options: {
    side: {
      [SideType.RIGHT_SIDE]: 'Right Side',
      [SideType.LEFT_SIDE]: 'Left Side',
      [SideType.ALTERNATE_SIDES]: 'Alternate Sides',
      [SideType.LEFT_THEN_RIGHT_SIDE]: 'First left then right',
    },
    duration: {
      [DurationType.TIMED]: 'Timed',
      [DurationType.REPETITIONS]: 'Repetitions',
    },
    time: {
      [TimeUnit.SECONDS]: 'Seconds',
      [TimeUnit.MINUTES]: 'Minutes',
    },
  },
};

export default texts;
