const texts = {
  title: 'Days',
  addNewDay: 'Add New Day',
  day: 'Day',
  noOptionText: 'No workout found...',
  estDuration: 'Est: {minutes} mins',
  equipment: 'Equipment: {equipment}',
  none: 'None',
  moreEquipment: 'More...',
  textFieldLabel: 'Select Workout',
  viewWorkout: 'View Workout',
};

export default texts;
