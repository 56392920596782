import React from 'react';
import { MealPlanAssignmentStatus } from '../../utils/mealPlan';

const initialValues = {
  isReady: false,
  isLoading: false,
  recipesCollection: null,
  recipesDocs: [],
  selectedRecipe: null,
  mealPlanAssignments: null,
  assignmentViewsCollection: { docs: [] },
  mealPlanViewsCollection: null,
  mealPlansStatusCount: {
    [MealPlanAssignmentStatus.ACTIVE]: 0,
    [MealPlanAssignmentStatus.PENDING]: 0,
    [MealPlanAssignmentStatus.NEEDS_REFRESH]: 0,
    [MealPlanAssignmentStatus.MANUALLY_REFRESHABLE]: 0,
  },
  showArchivedMealPlans: false,
  mealPlanConfig: null,
};

const MealPlanContext = React.createContext(initialValues);

export default MealPlanContext;
export {
  initialValues,
};
