import React, {
  useMemo,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import format from 'string-template';
import { ContractStatus } from '../../../../utils/userContract';
import QuickSearchToolbar from '../../../QuickSearchToolbar';
import useQuickSearch from '../../../../../hooks/useQuickSearch';
import { getOnboardingLinkFromLead } from '../../../../utils/onboardingLink';
import useToast from '../../../../hooks/useToast';

import {
  DataGridContainer,
  StyledDataGrid,
  ActionButtonsContainer,
  StyledActionButton,
  StyledUserName,
} from './styles';
import texts from './texts';

const ContractsTable = ({
  rows,
  productsList,
  onClickCancel,
  onClickRetryPayment,
  handleOpenContractModal,
}) => {
  const { showToast } = useToast();

  const handleCopy = useCallback(async (leadId) => {
    const onBoardingLink = getOnboardingLinkFromLead(leadId);
    await navigator.clipboard.writeText(onBoardingLink);
    showToast(texts.copiedSuccessfully, { type: 'success' });
  }, [showToast]);

  const columns = useMemo(() => (
    [
      {
        field: 'userName',
        quickSearch: true,
        headerName: texts.headers.clientName,
        flex: 20,
        renderCell: ({ row }) => (
          <StyledUserName onClick={() => handleOpenContractModal(row)}>
            {row.userName}
          </StyledUserName>
        ),
      },
      {
        field: 'coachName',
        quickSearch: true,
        headerName: texts.headers.coach,
        flex: 15,
      },
      {
        field: 'product',
        headerName: texts.headers.product,
        flex: 15,
        valueGetter: ({ row }) => (
          productsList[row.productId] || texts.defaultRowValue
        ),
      },
      {
        field: 'startDate',
        headerName: texts.headers.startDate,
        flex: 15,
      },
      {
        field: 'initialPaymentAndTerm',
        headerName: texts.headers.initialPaymentAndTerm,
        flex: 20,
        valueGetter: ({ row }) => (
          `${format(texts.paymentAndTerm, {
            initialPayment: row.initialPaymentInCentsStr,
            initialTerm: row.initialTerm,
          })}`
        ),
      },
      {
        field: 'totalPriceInCentsStr',
        headerName: texts.headers.monthly,
        flex: 10,
      },
      {
        field: 'status',
        headerName: texts.headers.status,
        flex: 10,
      },
      {
        field: 'action',
        headerName: texts.headers.contractAction,
        flex: 25,
        minWidth: 420,
        renderCell: ({
          row,
        }) => (
          <ActionButtonsContainer>
            <StyledActionButton
              variant="primary"
              onClick={() => handleCopy(row.leadId)}
              disabled={row.status !== ContractStatus.PENDING}
            >
              {texts.copy}
            </StyledActionButton>
            <StyledActionButton
              variant="primary"
              onClick={() => onClickCancel(row)}
              disabled={row.status !== ContractStatus.PENDING}
            >
              {texts.cancel}
            </StyledActionButton>
            <StyledActionButton
              variant="primary"
              onClick={() => handleOpenContractModal(row)}
              disabled={row.status !== ContractStatus.PENDING}
            >
              {texts.update}
            </StyledActionButton>
            <StyledActionButton
              variant="primary"
              onClick={() => onClickRetryPayment(row)}
              disabled={row.status !== ContractStatus.INCOMPLETE}
            >
              {texts.retryPayment}
            </StyledActionButton>
          </ActionButtonsContainer>
        ),
      },
    ]
  ), [
    productsList,
    handleOpenContractModal,
    handleCopy,
    onClickCancel,
    onClickRetryPayment,
  ]);

  const {
    filteredRows,
    toolbarProps,
  } = useQuickSearch(rows, columns);

  return (
    <DataGridContainer>
      <StyledDataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: { ...toolbarProps, autoFocus: true },
        }}
      />
    </DataGridContainer>
  );
};

ContractsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  productsList: PropTypes.object.isRequired,
  handleOpenContractModal: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickRetryPayment: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(ContractsTable);
