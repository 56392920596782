import pages, { pagesConfig } from '../../config/pages';

import useToolsNavigation from '../../hooks/useToolsNavigation';

const useInsideSalesSidebarConfig = () => {
  const { navigateTo } = useToolsNavigation();

  const entries = [
    {
      pageId: pages.INSIDE_SALES_LEADS,
      icon: pagesConfig[pages.INSIDE_SALES_LEADS].icon,
      onClick: () => navigateTo(pagesConfig[pages.INSIDE_SALES_LEADS].path),
      title: pagesConfig[pages.INSIDE_SALES_LEADS].title,
    },
    {
      pageId: pages.INSIDE_SALES,
      icon: pagesConfig[pages.INSIDE_SALES].icon,
      onClick: () => navigateTo(pagesConfig[pages.INSIDE_SALES].path),
      title: pagesConfig[pages.INSIDE_SALES].title,
    },
    {
      pageId: pages.INSIDE_SALES_COMMISSIONS,
      icon: pagesConfig[pages.INSIDE_SALES_COMMISSIONS].icon,
      onClick: () => navigateTo(pagesConfig[pages.INSIDE_SALES_COMMISSIONS].path),
      title: pagesConfig[pages.INSIDE_SALES_COMMISSIONS].title,
    },
    {
      pageId: pages.MSG_TEMPLATES,
      icon: pagesConfig[pages.MSG_TEMPLATES].icon,
      onClick: () => navigateTo(pagesConfig[pages.MSG_TEMPLATES].path),
      title: pagesConfig[pages.MSG_TEMPLATES].title,
    },
    {
      pageId: pages.EMAIL_TEMPLATES,
      icon: pagesConfig[pages.EMAIL_TEMPLATES].icon,
      onClick: () => navigateTo(pagesConfig[pages.EMAIL_TEMPLATES].path),
      title: pagesConfig[pages.EMAIL_TEMPLATES].title,
    },
    {
      pageId: pages.ANALYTICS,
      icon: pagesConfig[pages.ANALYTICS].icon,
      onClick: () => navigateTo(pagesConfig[pages.ANALYTICS].path),
      title: pagesConfig[pages.ANALYTICS].title,
    },
    {
      pageId: pages.INSIDE_SALES_CONTRACTS_MANAGER,
      icon: pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].icon,
      onClick: () => navigateTo(pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].path),
      title: pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].title,
    },
    {
      pageId: pages.COMMISSIONS,
      icon: pagesConfig[pages.COMMISSIONS].icon,
      onClick: () => navigateTo(pagesConfig[pages.COMMISSIONS].path),
      title: pagesConfig[pages.COMMISSIONS].title,
    },
  ];

  return {
    entries,
  };
};

export default useInsideSalesSidebarConfig;
