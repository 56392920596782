import { Avatar } from '@mui/material';
import styled from 'styled-components';
import {
  MailOutline as EmailIcon,
  PhoneInTalk as PhoneIcon,
  PersonPinCircle as LocationIcon,
  AccessTime as TimeZoneIcon,
} from '@mui/icons-material';

import colors from '../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  width: 60%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  flex: 1;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const InfoWrapper = styled.div`
  margin: 10px 25px 5px;
  display: flex;
  justify-content: space-between;
`;

const ContactInfo = styled.div`
  margin: 5px 25px;
  display: flex;
  color: ${colors.shades.gamma2};
  border-top: 1px solid ${colors.shades.gamma3};
  border-bottom: 1px solid ${colors.shades.gamma3};
`;

const Name = styled.div`
  margin: 5px 25px;
  font-weight: bold;
  font-size: 24px;
`;

const StyledAvatar = styled(Avatar)`
  display: flex;
  width: 140px;
  height: 140px;
  min-height: 100px;
  min-width: 100px;
  align-self: center;
`;

const StyledEmailIcon = styled(EmailIcon)`
  height: 16px;
  width: 16px;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  height: 16px;
  width: 16px;
`;

const StyledTimeZoneIcon = styled(TimeZoneIcon)`
  height: 16px;
  width: 16px;
`;

const StyledLocationIcon = styled(LocationIcon)`
  height: 16px;
  width: 16px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const TagsSection = styled.div`
  margin-left: 15px;
  display:flex;
  flex-direction: row;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 25px 15px;
  column-gap: 10px;
`;

export {
  Container,
  InfoContainer,
  InfoWrapper,
  Name,
  StyledAvatar,
  ImageContainer,
  ContactInfo,
  StyledEmailIcon,
  StyledPhoneIcon,
  StyledLocationIcon,
  NameContainer,
  StyledTimeZoneIcon,
  TagsSection,
  ChipContainer,
};
