import { ChannelActions } from '../../../../context/ChatContext/buckets';

const texts = {
  defaultPreviewMessage: 'Send a message',
  actionsPopover: {
    [`${ChannelActions.ARCHIVE}`]: 'Archive',
    [`${ChannelActions.UNARCHIVE}`]: 'Unarchive',
    [`${ChannelActions.HIDE}`]: 'Hide',
    [`${ChannelActions.SHOW}`]: 'Show',
  },
  alert: {
    [`${ChannelActions.ARCHIVE}`]: {
      header: 'Archive Chat',
      message: 'Are you sure you want to archive your chat with {channelName}?',
    },
    [`${ChannelActions.UNARCHIVE}`]: {
      header: 'Unarchive Chat',
      message: 'Are you sure you want to unarchive your chat with {channelName}?',
    },
    [`${ChannelActions.HIDE}`]: {
      header: 'Hide Chat',
      message: "Are you sure you want to hide your chat with {channelName}? It will be available in the 'All' tab",
    },
    [`${ChannelActions.SHOW}`]: {
      header: 'Show Chat',
      message: "Are you sure you want to show your chat with {channelName}? It will be available in the 'Active' tab",
    },
    confirmButton: 'Confirm',
    cancelButton: 'Cancel',
  },
};

export default texts;
