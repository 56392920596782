import texts from './texts';

const getColumns = ({
  actionsRenderCell,
}) => [
  {
    field: 'tag',
    type: 'string',
    headerName: texts.columns.name,
    flex: 1,
    quickSearch: true,
  },
  {
    field: 'type',
    type: 'string',
    headerName: texts.columns.type,
    flex: 1,
  },
  {
    field: 'category',
    type: 'string',
    headerName: texts.columns.category,
    valueGetter: ({ row }) => texts.tagCategory[row.category] || row.category || '',
    flex: 1,
  },
  {
    field: 'actions',
    headerName: texts.columns.actions,
    flex: 1,
    renderCell: actionsRenderCell,
  },
];

export default getColumns;
