import React, {
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import moment from 'moment';
import format from 'string-template';
import PropTypes from 'prop-types';

import useUserDoc from '../../../hooks/useUserDoc';
import useToast from '../../../hooks/useToast';
import RadioButtonGroup from '../../RadioButtonGroup';
import NotificationDisableModal from '../NotificationDisableModal';
import LoadingOverlay from '../../LoadingOverlay';

import {
  NotificationType,
} from './utils';
import {
  ButtonContainer,
  Container,
  SettingContainer,
  HeaderContainer,
  SubTitle,
  Title,
  TitleContainer,
} from './styles';
import texts from './texts';

const NotificationSettings = ({ clientId }) => {
  const {
    userDoc,
    isReady,
  } = useUserDoc(clientId);

  const {
    workoutNotificationsResumeDate,
    mealPlanNotificationsResumeDate,
  } = userDoc || {};

  const [workoutNotificationEnabled, setWorkoutNotificationEnabled] = useState(true);
  const [mealPlanNotificationEnabled, setMealPlanNotificationEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(isReady);
  const [disabledNotificationType, setDisabledNotificationType] = useState(null);
  const { showToast } = useToast();

  useEffect(() => {
    if (workoutNotificationsResumeDate) {
      setWorkoutNotificationEnabled(moment(workoutNotificationsResumeDate).isBefore(moment()));
    }
    if (mealPlanNotificationsResumeDate) {
      setMealPlanNotificationEnabled(moment(mealPlanNotificationsResumeDate).isBefore(moment()));
    }
  }, [
    mealPlanNotificationsResumeDate,
    workoutNotificationsResumeDate,
  ]);

  const handleSave = async (notificationType, resumeDate) => {
    setIsLoading(true);
    try {
      if (notificationType === NotificationType.WORKOUT) {
        await userDoc.updateWorkoutNotificationsResumeDate(resumeDate);
        setWorkoutNotificationEnabled(moment(resumeDate).isBefore(moment()));
      } else if (notificationType === NotificationType.MEAL_PLAN) {
        await userDoc.updateMealPlanNotificationsResumeDate(resumeDate);
        setMealPlanNotificationEnabled(moment(resumeDate).isBefore(moment()));
      }
      showToast(texts.notificationUpdateSuccessMessage);
    } catch (error) {
      showToast(format(texts.notificationUpdateFailureMessage, { error }), { error: true });
    } finally {
      setIsLoading(false);
      setDisabledNotificationType(null);
    }
  };

  /*
   * If user is going to disable the notification then show modal to configure the disable type.
   * Otherwise directly call the save function
   */
  const onChangeState = (notificationType, shouldEnableNotification) => {
    if (!shouldEnableNotification) {
      setDisabledNotificationType(notificationType);
    } else {
      handleSave(notificationType, moment().toDate());
    }
  };

  return (
    <Container>
      {!isLoading && (
        <>
          <SettingContainer>
            <HeaderContainer>
              <TitleContainer>
                <Title>{texts.workoutNotificationTitle}</Title>
                <SubTitle>{texts.workoutNotificationDescription}</SubTitle>
              </TitleContainer>
            </HeaderContainer>
            <ButtonContainer>
              <RadioButtonGroup
                options={[
                  {
                    label: texts.enable,
                    value: texts.enable,
                  }, {
                    label: texts.disable,
                    value: texts.disable,
                  },
                ]}
                selectedOption={workoutNotificationEnabled ? texts.enable : texts.disable}
                onOptionChange={(value) => onChangeState(NotificationType.WORKOUT, value === texts.enable)}
              />
            </ButtonContainer>
          </SettingContainer>
          <SettingContainer>
            <HeaderContainer>
              <TitleContainer>
                <Title>{texts.mealPlanNotificationTitle}</Title>
                <SubTitle>{texts.mealPlanNotificationDescription}</SubTitle>
              </TitleContainer>
            </HeaderContainer>
            <ButtonContainer>
              <RadioButtonGroup
                options={[
                  {
                    label: texts.enable,
                    value: texts.enable,
                  }, {
                    label: texts.disable,
                    value: texts.disable,
                  },
                ]}
                selectedOption={mealPlanNotificationEnabled ? texts.enable : texts.disable}
                onOptionChange={(value) => onChangeState(NotificationType.MEAL_PLAN, value === texts.enable)}
              />
            </ButtonContainer>
          </SettingContainer>
        </>
      )}
      {!!disabledNotificationType && (
        <NotificationDisableModal
          disabledNotificationType={disabledNotificationType}
          onClose={() => setDisabledNotificationType(null)}
          handleSave={handleSave}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

NotificationSettings.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(NotificationSettings);
