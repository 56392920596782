import styled, { css } from 'styled-components';
import Button from '../index';
import colors from '../../../styles/colors';

const GeneralButton = styled(Button)`
  border: 1px solid ${colors.shades.secondary6};
  border-radius: 5px;
  color: ${colors.shades.secondary8};
  background: ${colors.shades.secondary4};
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: none;
  padding: 14px 20px;
  box-shadow: none;
  white-space: nowrap;

  &:hover {
    background: ${colors.shades.secondary6};
    box-shadow: none;
  }

  &:active {
    transform: scale(0.98) translateY(1px);
  }

  &:disabled {
    span svg {
      opacity: 0.3;
    }
  }
`;

const StyledPrimaryButton = styled(GeneralButton)`
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 22px;
          width: auto;
          min-width: auto;
          font-size: 11px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0;
          text-transform: none;
          padding: 12px 7px 12px 12px;
          box-shadow: none;
        `;
      case 'medium':
        return css`
          height: 36px;
          width: auto;
          min-width: auto;
          box-shadow: none;
          padding: 9px 12px;
          border-radius: 73px;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0;
          text-transform: none;
        `;
      case 'large':
      default:
        return css`
          height: 48px;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0;
          padding: 10px 24px;
        `;
    }
  }}

  ${({ $variant }) => {
    switch ($variant) {
      case 'warning':
        return css`
          border: 1px solid ${colors.shades.primary3};
          color: ${colors.shades.secondary8};
          background: ${colors.shades.primary7};
          &:hover {
            background-color: ${colors.shades.primary3};
          };
        `;
      case 'info':
        return css`
          border: 1px solid ${colors.shades.secondary7};
          color: ${colors.shades.secondary8};
          background: ${colors.shades.secondary5};
          &:hover {
            background-color: ${colors.shades.secondary6};
          };
        `;
      case 'light':
        return css`
          border: 1px solid ${colors.base.secondary3};
          color: ${colors.base.secondary2};
          background: ${colors.base.secondary3};
          &:hover {
            background-color: ${colors.shades.secondary6};
          };
        `;
      case 'dimmed':
        return css`
          border: 1px solid ${colors.shades.gamma8};
          color: ${colors.base.secondary2};
          background: ${colors.shades.gamma5};
          &:hover {
            background-color: ${colors.shades.gamma1};
          };
        `;
      case 'dark':
      default:
        return css`
          border: 1px solid ${colors.base.beta};
          color: ${colors.shades.secondary5};
          background: ${colors.base.secondary2};
          &:hover {
            background-color: ${colors.shades.secondary8};
          };
          &:disabled {
            color: ${colors.addAlpha(colors.shades.secondary5, 0.6)};
          }
        `;
    }
  }}
`;

const StyledAddEditButton = styled(GeneralButton)`
  padding: 10px 12px 10px 15px;
  border-radius: ${({ $variant }) => (
    $variant === 'square' ? '4px' : '73px'
  )};
  color: ${colors.shades.secondary8};
  border: 1px solid ${({ $variant }) => (
    $variant === 'muted' ? colors.shades.secondary6 : colors.shades.primary2
  )};
  background: ${({ $variant }) => (
    $variant === 'muted' ? colors.shades.secondary4 : colors.base.primary
  )};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;

  &:hover {
    background: ${({ $variant }) => (
    $variant === 'muted' ? colors.shades.secondary6 : colors.shades.primary3
  )};
  }
`;

export {
  GeneralButton,
  StyledAddEditButton,
  StyledPrimaryButton,
};
