import texts from './texts.json';

const getColumns = ({
  actionsRenderCell,
  statusRenderCell,
  macroBreakDownCell,
  restrictionsRenderCell,
  mealTimesCell,
}) => [
  {
    field: ' ',
    renderCell: statusRenderCell,
    width: 20,
    align: 'center',
  },
  {
    field: 'name',
    type: 'string',
    headerName: texts.columns.name,
    flex: 2,
    minWidth: 110,
    quickSearch: true,
  },
  {
    field: 'mealTimes',
    headerName: texts.columns.mealTimes,
    width: 80,
    renderCell: mealTimesCell,
  },
  {
    field: 'meals',
    headerName: texts.columns.meals,
    width: 50,
  },
  {
    field: 'macroBreakdown',
    type: 'string',
    headerName: texts.columns.macroBreakdown,
    minWidth: 220,
    renderCell: macroBreakDownCell,
  },
  {
    field: 'allergens',
    type: 'string',
    headerName: texts.columns.allergens,
    renderCell: restrictionsRenderCell,
    minWidth: 185,
  },
  {
    field: 'lastUpdated',
    type: 'string',
    headerName: texts.columns.lastUpdated,
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: texts.columns.actions,
    width: 300,
    renderCell: actionsRenderCell,
  },
];

export default getColumns;
