import texts from './texts.json';

const getColumns = ({
  actionsRenderCell,
}) => [
  {
    field: 'name',
    type: 'string',
    headerName: texts.columns.coachName,
    quickSearch: true,
    flex: 2,
  },
  {
    field: 'leads',
    type: 'string',
    headerName: texts.columns.leads,
    flex: 1,
  },
  {
    field: 'checkins',
    type: 'string',
    headerName: texts.columns.checkins,
    flex: 1,
  },
  {
    field: 'workouts',
    type: 'string',
    headerName: texts.columns.workouts,
    flex: 1,
  },
  {
    field: 'mealPlans',
    type: 'string',
    headerName: texts.columns.mealPlans,
    flex: 1,
  },
  {
    field: 'videoFeedback',
    type: 'string',
    headerName: texts.columns.videoFeedback,
    flex: 1,
  },
  {
    field: 'reminders',
    type: 'string',
    headerName: texts.columns.reminders,
    flex: 1,
  },
  {
    field: 'actions',
    headerName: texts.columns.actions,
    width: 150,
    renderCell: actionsRenderCell,
  },
];

export default getColumns;
