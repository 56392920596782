import styled from 'styled-components';
import { Form } from 'formik';
import { Chip } from '@mui/material';

import colors from '../../../../../styles/colors';
import {
  SectionCompartment,
} from '../../../../../components/v2/Section';
import FormikInput from '../../../../../components/v2/FormikInput';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledForm = styled(Form)`
  display: flex;
  width: 700px;
  flex: 0 0 auto;
  flex-direction: column;
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
`;

const StyledSectionCompartment = styled(SectionCompartment)`
  width: unset;
  row-gap: 5px;
  padding: 10px;
  flex-grow: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: stretch;
  height: 100%;
  position: relative;
  max-height: calc(100% - 76px);
  overflow-y: auto;
  align-items: flex-start;
  padding-bottom: 20px;
  gap: 20px;
  margin-top: 0;
  > :last-child {
    min-width: 332px;
  }
`;

const InfoChip = styled(Chip)`
  background-color: ${({ $isRest }) => ($isRest ? colors.shades.secondary9 : colors.shades.primary3)};
  height: 25px;
  margin-right: 15px;
  width: fit-content;
`;

const ExercisesTableContainer = styled.div`
  flex: 1;
  max-height: 100%;
  position: sticky;
  top: 0;
  right: 0;
  overflow-y: auto;
`;

const StyledFormikInput = styled(FormikInput)`
  .MuiInputBase-root {
    height: fit-content;
  }
`;

export {
  Container,
  StyledForm,
  StyledSectionCompartment,
  ContentContainer,
  InfoChip,
  ExercisesTableContainer,
  StyledFormikInput,
};
