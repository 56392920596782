import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import { StyledPrimaryButton } from './styles';

const PrimaryButton = ({
  onClick,
  children,
  disabled,
  type,
  icon,
  endIcon,
  variant,
  size,
}) => (
  <StyledPrimaryButton
    onClick={onClick}
    startIcon={icon}
    endIcon={endIcon}
    component={Button}
    disabled={disabled}
    type={type}
    $variant={variant}
    $size={size}
  >
    {children}
  </StyledPrimaryButton>
);

PrimaryButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  endIcon: PropTypes.element,
  type: PropTypes.oneOf(['submit', 'button']),
  variant: PropTypes.oneOf(['dark', 'light', 'info', 'warning', 'dimmed']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

PrimaryButton.defaultProps = {
  children: [],
  onClick: null,
  disabled: false,
  type: 'button',
  icon: null,
  endIcon: null,
  variant: 'dark',
  size: 'large',
};

export default PrimaryButton;
