import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';

const InputLabel = styled(Typography)`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  color: ${colors.shades.primary1};
  margin-bottom: 5px;
`;

export default InputLabel;
