import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { Stack } from '@mui/material';
import format from 'string-template';

import useComponentMounted from '../../../hooks/useComponentMounted';
import FirebaseContext from '../../../context/FirebaseContext';
import MsgTemplate from '../../../Model/MsgTemplate';
import { pathPlaceholder } from '../../../utils/paths';
import ColoredHeader from '../../components/ColoredHeader';
import CoachSelect from '../../components/Leads/components/CoachSelect';
import LoadingOverlay from '../../components/LoadingOverlay';
import TemplateList from '../../components/Templates/components/TemplateList';
import TemplateView from '../../components/Templates/components/TemplateView';
import useStorage from '../../../hooks/useStorage';
import useSessionStore from '../../../hooks/useSessionStore';
import { storagePaths } from '../../../utils/firebasePaths';
import { AssignmentRole } from '../../../utils/internalAssignments';
import {
  getFileCategory,
  getFileExtension,
  getFileName,
  getFileUrl,
} from '../../../utils/file';
import {
  Container,
  TemplatesContainer,
  Title,
} from './styles';
import texts from './texts.json';
import { getTemplateConversionRates } from './utils';

const MessageTemplates = () => {
  const {
    firebase: {
      remote,
    },
  } = useContext(FirebaseContext);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [defaultTemplates, setDefaultTemplates] = useState();
  const [coachMsgTemplates, setCoachMsgTemplates] = useState();
  const [selectedTemplateName, setSelectedTemplateName] = useState();
  const [templateConversionRates, setTemplateConversionRates] = useState({});
  const [selectedTemplateList, setSelectedTemplateList] = useState();
  const [msgBody, setMsgBody] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [messageTemplateEnabled, setMessageTemplateEnabled] = useState();

  const isComponentMountedRef = useComponentMounted();

  const { uploadAttachment } = useStorage();
  const { authUser } = useSessionStore();

  // Load default message templates
  useEffect(() => {
    const loadDefaultTemplates = async () => {
      const defaultTemplatesCollection = await MsgTemplate.getDefaultTemplates();
      if (isComponentMountedRef.current) {
        setDefaultTemplates(defaultTemplatesCollection.docs);
      }
    };
    loadDefaultTemplates();
  }, [isComponentMountedRef]);

  // Load coach's message templates when selecting a coach
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      setSelectedTemplateName('');
      const coachTemplateListCollection = await MsgTemplate.getTemplatesByCoach(selectedCoach.id);
      if (isComponentMountedRef.current) {
        setIsLoading(false);
        setCoachMsgTemplates(coachTemplateListCollection.docs);
      }
    };
    if (selectedCoach && isComponentMountedRef.current) {
      init();
    }
  }, [
    isComponentMountedRef,
    selectedCoach,
  ]);

  // Current Template of selected template name
  const currentTemplate = useMemo(
    () => selectedTemplateList?.find((template) => template.current === true || template.isDefault === true),
    [selectedTemplateList],
  );

  // Template history of selected template name
  const templateHistory = useMemo(
    () => selectedTemplateList?.sort((first, second) => second.createdAt - first.createdAt),
    [selectedTemplateList],
  );

  const loadConversionRates = useCallback(async () => {
    setIsLoading(true);
    const conversionRates = await getTemplateConversionRates(remote, selectedCoach.id, selectedTemplateName);
    if (isComponentMountedRef.current) {
      setIsLoading(false);
      setTemplateConversionRates(conversionRates);
    }
  }, [
    isComponentMountedRef,
    remote,
    selectedCoach,
    selectedTemplateName,
  ]);

  /**
   * When selecting a template name, set templates list that's belongs to selected template name.
   * If coach does't have template for that name, default template set as the template list
   */
  useEffect(() => {
    if (selectedTemplateName) {
      let selectedTemplates = coachMsgTemplates.filter((template) => template.name === selectedTemplateName);
      if (!selectedTemplates.length) {
        selectedTemplates = defaultTemplates.filter((template) => template.name === selectedTemplateName);
      }
      setSelectedTemplateList(selectedTemplates);
      setFileToUpload(null);
      setTemplateConversionRates({});
    }
  }, [
    selectedTemplateName,
    coachMsgTemplates,
    defaultTemplates,
  ]);

  useEffect(() => {
    setMsgBody(currentTemplate?.msgBody);
    setMessageTemplateEnabled(currentTemplate?.isEnabled);
  }, [currentTemplate]);

  const onTemplateSelect = useCallback((templateName) => {
    setSelectedTemplateName(templateName);
    setIsFileRemoved(false);
  }, []);

  const saveTemplate = useCallback(async () => {
    setIsLoading(true);
    let attachmentUrl = null;
    let attachmentCategory = null;
    const { version } = currentTemplate;
    if (fileToUpload) {
      const fileExt = getFileExtension(fileToUpload.name);
      const fileName = getFileName(fileToUpload.name);
      const fileNameWithVersion = `${fileName}_v${version}`;
      const fileType = fileToUpload.type;
      const storagePath = format(storagePaths.IS_MSG_ATTACHMENTS, {
        [pathPlaceholder.COACH_ID]: selectedCoach.id,
      });
      const { publicUrl } = await uploadAttachment(
        getFileUrl(fileToUpload),
        fileExt,
        storagePath,
        fileNameWithVersion,
        fileType,
      );
      attachmentUrl = publicUrl;
      attachmentCategory = getFileCategory(fileToUpload);
    }

    const newTemplateVersion = {
      ...currentTemplate.data,
      msgBody,
      ...(attachmentUrl && { attachmentUrl }),
      ...(attachmentCategory && { attachmentCategory }),
      ...(isFileRemoved && { attachmentUrl: null, attachmentCategory: null }),
      isDefault: false,
      createdAt: new Date(),
      coach: selectedCoach.id,
      createdByUserName: authUser.displayName,
      createdByUser: authUser.uid,
      isEnabled: messageTemplateEnabled,
      current: true,
    };

    if (currentTemplate.isDefault) {
      // if default template, create a new one for coach
      await MsgTemplate.addNewTemplate(newTemplateVersion, version);
    } else {
      // Create new message template with updated details, when user changes msg body or attachment or remove attachment
      const shouldCreateNewTemplate = fileToUpload || (currentTemplate.msgBody !== msgBody) || isFileRemoved;
      // Update current template, set current property to false if a new template is created
      await currentTemplate.update({
        updatedAt: new Date(),
        isEnabled: messageTemplateEnabled,
        current: !shouldCreateNewTemplate,
      });
      if (shouldCreateNewTemplate) {
        await MsgTemplate.addNewTemplate(newTemplateVersion, version + 1);
      }
    }

    const templateList = await MsgTemplate.getTemplatesByCoach(selectedCoach.id);
    if (isComponentMountedRef.current) {
      setIsLoading(false);
      setCoachMsgTemplates(templateList.docs);
      setIsFileRemoved(false);
    }
  }, [
    fileToUpload,
    isComponentMountedRef,
    msgBody,
    selectedCoach,
    uploadAttachment,
    messageTemplateEnabled,
    authUser,
    currentTemplate,
    isFileRemoved,
  ]);

  return (
    <Container>
      <ColoredHeader>
        <Stack spacing={5}>
          <Title>{texts.msgTemplates}</Title>
          <CoachSelect role={AssignmentRole.INSIDE_SALES} onChange={setSelectedCoach} />
        </Stack>
      </ColoredHeader>
      {!!selectedCoach && (
        <TemplatesContainer>
          <TemplateList
            selectedTemplateName={selectedTemplateName}
            templates={defaultTemplates}
            onTemplateSelect={onTemplateSelect}
          />
          {!!selectedTemplateName && (
            <TemplateView
              key={currentTemplate?.id}
              template={currentTemplate}
              templateHistory={templateHistory}
              templateConversionRates={templateConversionRates}
              loadConversionRates={loadConversionRates}
              msgBody={msgBody}
              onMsgBodyChange={setMsgBody}
              onSaveTemplate={saveTemplate}
              file={fileToUpload}
              onFileChange={setFileToUpload}
              isMessageTemplateEnabled={messageTemplateEnabled}
              onChangeMessageTemplateState={setMessageTemplateEnabled}
              isFileRemoved={isFileRemoved}
              onRemoveFile={setIsFileRemoved}
            />
          )}
        </TemplatesContainer>
      )}
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

export default MessageTemplates;
