import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';

import { ChatSetting } from '../../../../../Model/Coach';
import ExternalCoachContext, {
  withExternalCoachContextReady,
} from '../../../../context/ExternalCoachContext';
import FeedContext from '../../../../context/FeedContext';
import CoachConfig, {
  AutoArchiveDays,
  orderType,
} from '../../../../Model/CoachConfig';
import useToast from '../../../../hooks/useToast';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import { CoachingActivity } from '../../../../../utils/log';
import useLogger from '../../../../../hooks/useLogger';
import Select from '../../../../../components/Select/Select';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import { SettingOption } from './utils';
import {
  Container,
  SettingContainer,
  HeaderContainer,
  SettingsActionContainer,
  TitleContainer,
  Title,
  SubTitle,
  ActionLabel,
} from './styles';
import texts from './texts';

const archiveOptions = Object.entries(AutoArchiveDays).map(([key, value]) => ({
  value,
  label: `${value} Days`,
  key,
}));

const OtherSettings = () => {
  const {
    coachConfig,
    setCoachConfig,
  } = useContext(FeedContext);
  const {
    coachDoc,
    coachDoc: {
      notificationsConfig: chatBotConfig,
    },
    externalCoachDoc,
  } = useContext(ExternalCoachContext);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const { logCoachingActivity } = useLogger();

  const [archivalTime, setArchivalTime] = useState(coachConfig?.daysToAutoArchive || AutoArchiveDays.TWO_WEEKS);

  useEffect(() => {
    setArchivalTime(coachConfig ? coachConfig.daysToAutoArchive : AutoArchiveDays.TWO_WEEKS);
  }, [coachConfig]);

  const [chatSettings, setChatSettings] = useState({
    [ChatSetting.ENABLE_WORKOUT_NOTIFICATIONS]: chatBotConfig[ChatSetting.ENABLE_WORKOUT_NOTIFICATIONS] === false
      ? SettingOption.DISABLED : SettingOption.ENABLED,
    [ChatSetting.ENABLE_SUMMARY_NOTIFICATIONS]: chatBotConfig[ChatSetting.ENABLE_SUMMARY_NOTIFICATIONS] === false
      ? SettingOption.DISABLED : SettingOption.ENABLED,
    [ChatSetting.ENABLE_FEEDBACK_NOTIFICATIONS]: chatBotConfig[ChatSetting.ENABLE_FEEDBACK_NOTIFICATIONS] === false
      ? SettingOption.DISABLED : SettingOption.ENABLED,
  });

  const handleActivityOrderChange = useCallback(async (order) => {
    try {
      if (coachConfig) {
        await coachConfig.setDefaultOrder('time', order);
      } else {
        /* TODO : Remove instantiating the 'coachConfig' here.
         * All coaches have to have the doc at this point.
         */
        const coachConfigDoc = new CoachConfig(externalCoachDoc.id);
        await coachConfigDoc.init();
        await coachConfigDoc.setDefaultOrder('time', order);
        if (isComponentMountedRef.current) {
          setCoachConfig(coachConfigDoc);
        }
      }
      logCoachingActivity(CoachingActivity.UPDATED_ACTIVITY_FEED_SETTINGS);
      showToast(format(texts.activityFeedOrderSuccess, {
        order: texts.orderTypes[order],
      }));
    } catch (error) {
      showToast(format(texts.activityFeedOrderFailure, {
        order: texts.orderTypes[order],
      }), { error: true });
    }
  }, [
    coachConfig,
    externalCoachDoc.id,
    isComponentMountedRef,
    showToast,
    setCoachConfig,
    logCoachingActivity,
  ]);

  const handleArchivalTimeChange = async (event) => {
    try {
      if (coachConfig) {
        await coachConfig.setDaysToAutoArchive(event.target.value);
      } else {
        /* TODO : Remove instantiating the 'coachConfig' here.
         * All coaches have to have the doc at this point.
         */
        const coachConfigDoc = new CoachConfig(externalCoachDoc.id);
        await coachConfigDoc.init();
        if (isComponentMountedRef.current) {
          setCoachConfig(coachConfigDoc);
        }
        await coachConfigDoc.setDaysToAutoArchive(event.target.value);
      }
      logCoachingActivity(CoachingActivity.UPDATED_ACTIVITY_FEED_SETTINGS);
      if (isComponentMountedRef.current) {
        setArchivalTime(event.target.value);
      }
      showToast(format(texts.changeArchivalTimeSuccessful));
    } catch (error) {
      showToast(format(texts.changeArchivalTimeFailed), { error: true });
      Sentry.captureMessage(`Error changing archival time for coach ${externalCoachDoc.id}. ${error}`);
    }
  };

  const handleChatSettingsChange = useCallback(async (setting, value) => {
    try {
      setChatSettings((prevState) => ({
        ...prevState,
        [setting]: value,
      }));

      await coachDoc.updateNotificationsConfig(setting, value === SettingOption.ENABLED);
      logCoachingActivity(CoachingActivity.UPDATED_CHAT_SETTINGS);
      if (isComponentMountedRef.current) {
        showToast(texts.chatSettingSaveSuccessful);
      }
    } catch (error) {
      showToast(texts.chatSettingSaveFailed, { error: true });
    }
  }, [
    coachDoc,
    isComponentMountedRef,
    showToast,
    logCoachingActivity,
  ]);

  return (
    <Container>
      <SettingContainer>
        <HeaderContainer>
          <TitleContainer>
            <Title>{texts.activityFeed}</Title>
            <SubTitle>{texts.activityFeedDescription}</SubTitle>
          </TitleContainer>
        </HeaderContainer>
        <SettingsActionContainer>
          <ActionLabel>{texts.activityFeedOrder}</ActionLabel>
          <RadioButtonGroup
            options={[
              {
                label: texts.recentFirst,
                value: orderType.DESC,
              },
              {
                label: texts.oldestFirst,
                value: orderType.ASC,
              },
            ]}
            selectedOption={(coachConfig && coachConfig.activityFeedDefaultOrderValue === orderType.DESC)
              ? orderType.DESC : orderType.ASC}
            onOptionChange={(value) => handleActivityOrderChange(value)}
          />
        </SettingsActionContainer>
        <SettingsActionContainer>
          <ActionLabel>{texts.activityArchiveTitle}</ActionLabel>
          <Select
            options={archiveOptions}
            onChange={handleArchivalTimeChange}
            value={archivalTime}
          />
        </SettingsActionContainer>
      </SettingContainer>
      <SettingContainer>
        <HeaderContainer>
          <TitleContainer>
            <Title>{texts.chat}</Title>
            <SubTitle>{texts.chatDescription}</SubTitle>
          </TitleContainer>
        </HeaderContainer>
        {Object.values(ChatSetting).map((setting, index) => (
          <SettingsActionContainer key={setting} $top={index === 0}>
            <ActionLabel>{texts.chatSetting[setting]}</ActionLabel>
            <RadioButtonGroup
              options={[
                {
                  label: texts.enabled,
                  value: SettingOption.ENABLED,
                },
                {
                  label: texts.disabled,
                  value: SettingOption.DISABLED,
                },
              ]}
              selectedOption={chatSettings[setting]}
              onOptionChange={(value) => handleChatSettingsChange(setting, value)}
            />
          </SettingsActionContainer>
        ))}
      </SettingContainer>
    </Container>
  );
};

export default compose(
  withExternalCoachContextReady,
  observer,
)(OtherSettings);
