import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Checkbox, Tooltip } from '@mui/material';

import useLogger from '../../../hooks/useLogger';
import { ReactComponent as FilterIcon } from '../../../assets/icons/v2/filter.svg';
import defaultMealImage from '../../../assets/defaultMealImage.svg';
import useQuickSearch from '../../../hooks/useQuickSearch';
import {
  RecipeTag,
  RestrictionAllergens,
  AllergenTagText,
} from '../../utils/meals';
import { DietaryRestrictionText } from '../../Model/UserNutritionProfile';
import { AttentionTag } from '../../../components/Tags';
import GenericDataGrid from '../GenericDataGrid';

import RecipesTableToolbar from './RecipesTableToolbar';
import MacroFiltersModal from './MacroFiltersModal';
import {
  DataGridContainer,
  TagList,
  StyledImage,
  ImageContainer,
  CellContainer,
  StyledFilter,
  StyledRestrictionIcon,
  StyledClockIcon,
  AdvancedFiltersButton,
} from './styles';
import configs from './configs';

import texts from './texts';

const mealOptionValues = Object.values(RecipeTag).map((tag) => ({
  value: tag,
  label: texts.meal[tag],
}));

const restrictionOptionValues = Object.entries(DietaryRestrictionText).map(([value, label]) => ({
  value,
  label,
}));

const RecipesTable = ({
  rows,
  ActionsCellComponent,
  extraActionsCellProps,
  bulkActionComponent,
  bulkActionComponentProps,
  bulkActionsEnabled,
  actionCellWidth,
}) => {
  const [mealOptions, setMealOptions] = useState(mealOptionValues.map((option) => option.value));
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [restrictionOptions, setRestrictionOptions] = useState([]);
  const [excludedAllergens, setExcludedAllergens] = useState([]);
  const [macroFilters, setMacroFilters] = useState(null);
  const [mealTimesFilterResetFn, setMealTimesFilterResetFn] = useState(() => () => {});
  const [restrictionsFilterResetFn, setRestrictionsFilterResetFn] = useState(() => () => {});

  const [openMacroFiltersModal, setOpenMacroFiltersModal] = useState(false);

  const { logEvent } = useLogger();

  const renderRestrictions = useCallback(({ row: { allergenTags } = {} }) => {
    let restrictionsCell = texts.noAllergens;

    // If we have restrictions, then we render them with different styles.
    if (allergenTags.length > 0) {
      const firstRestrictions = allergenTags.slice(0, 2);
      const remainingRestrictions = allergenTags.slice(2, allergenTags.length);
      const tooltipText = remainingRestrictions.map((rst) => AllergenTagText[rst]).join(', ');

      restrictionsCell = (
        <TagList>
          {firstRestrictions.map((rst) => (
            <AttentionTag key={rst}>{AllergenTagText[rst]}</AttentionTag>
          ))}
          {!!remainingRestrictions.length && (
            <Tooltip title={tooltipText} placement="top" arrow>
              <AttentionTag>{format(texts.moreAllergens, { amount: remainingRestrictions.length })}</AttentionTag>
            </Tooltip>
          )}
        </TagList>
      );
    }

    return restrictionsCell;
  }, []);

  const handleSelectRecipe = (id, selected = false) => {
    setSelectedRecipes((prev) => (!selected ? [...prev, id] : prev.filter((prevId) => prevId !== id)));
  };

  const columns = useMemo(() => [
    {
      field: 'checkbox',
      headerName: '',
      width: 70,
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      renderCell: ({ row }) => {
        const selected = selectedRecipes.find((recipe) => recipe.id === row.id);
        return (
          <div>
            <Checkbox
              checked={selected}
              onClick={() => handleSelectRecipe(row, selected)}
            />
          </div>
        );
      },
    },
    {
      field: 'image',
      headerName: '',
      width: 70,
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      renderCell: ({ row: { image } }) => {
        const src = image || defaultMealImage;
        return (
          <ImageContainer>
            <StyledImage src={src} alt={texts.recipeImageAlt} />
          </ImageContainer>
        );
      },
    },
    {
      field: 'name',
      quickSearch: true,
      headerName: texts.headers.name,
      flex: 12,
      renderCell: ({ row: { name } }) => (
        <CellContainer>{name}</CellContainer>
      ),
    },
    {
      field: 'calories',
      headerName: texts.headers.calories,
      flex: 8,
      valueGetter: ({ row: { totalCalories } }) => (
        parseFloat(totalCalories)
      ),
      renderCell: ({ row: { totalCalories } }) => (
        <CellContainer>{`${totalCalories} ${texts.caloriesUnit}`}</CellContainer>
      ),
    },
    {
      field: 'protein',
      headerName: texts.headers.protein,
      flex: 8,
      renderCell: ({
        row: {
          protein,
          proteinPercentage,
        },
      }) => (
        <CellContainer>{`${protein}g (${proteinPercentage}%)`}</CellContainer>
      ),
    },
    {
      field: 'carbs',
      headerName: texts.headers.carbs,
      flex: 8,
      renderCell: ({
        row: {
          carbs,
          carbsPercentage,
        },
      }) => (
        <CellContainer>{`${carbs}g (${carbsPercentage}%)`}</CellContainer>
      ),
    },
    {
      field: 'fat',
      headerName: texts.headers.fat,
      flex: 8,
      renderCell: ({
        row: {
          fat,
          fatPercentage,
        },
      }) => (
        <CellContainer>{`${fat}g (${fatPercentage}%)`}</CellContainer>
      ),
    },
    {
      field: 'allergens',
      headerName: texts.headers.allergens,
      headerClassName: 'allergensHeader',
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      flex: 12,
      minWidth: 150,
      valueGetter: ({ row: { allergenTags } }) => (
        allergenTags.map((tag) => AllergenTagText[tag])
          .join(', ')
      ),
      renderCell: renderRestrictions,
    },
    {
      field: 'prepTime',
      headerName: texts.headers.prepTime,
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      flex: 8,
      renderCell: ({ row: { preparationTime } }) => (
        <CellContainer>{format(texts.prepTime, { preparationTime })}</CellContainer>
      ),
    },
    {
      field: 'cookTime',
      headerName: texts.headers.cookTime,
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      flex: 8,
      renderCell: ({ row: { cookingTime } }) => (
        <CellContainer>{format(texts.cookTime, { cookingTime })}</CellContainer>
      ),
    },
    {
      field: 'tags',
      headerName: texts.headers.tags,
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      flex: 10,
      renderCell: ({ row: { tags } }) => (
        <CellContainer>
          {tags
            .map((tag) => {
              const meal = mealOptionValues.find((option) => option.value === tag);
              return meal ? meal.label : '';
            })
            .join(', ')}
        </CellContainer>
      ),
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      flex: actionCellWidth,
      minWidth: 120,
      renderCell: ({ row }) => (<ActionsCellComponent data={row} {...extraActionsCellProps} />),
    },
  ], [
    selectedRecipes,
    extraActionsCellProps,
    actionCellWidth,
    renderRestrictions,
  ]);

  const handleRestrictionFilter = useCallback((restrictions) => {
    setRestrictionOptions(restrictions);
    const allergensList = restrictions.reduce((prev, tag) => ([...prev, ...RestrictionAllergens[tag]]), []);
    setExcludedAllergens([...new Set(allergensList)]);
  }, []);

  const handleClearMealFilters = () => {
    setMealOptions(mealOptionValues.map((option) => option.value));
    if (typeof mealTimesFilterResetFn === 'function') {
      mealTimesFilterResetFn();
    }
  };

  const handleClearRestrictionFilters = () => {
    handleRestrictionFilter([]);
    if (typeof restrictionsFilterResetFn === 'function') {
      restrictionsFilterResetFn();
    }
  };

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(rows, columns);

  const isWithinRange = (value, range) => {
    const { from, to } = range;
    return value >= from && value <= to;
  };

  const filteredRows = useMemo(() => (
    quickSearchRows.filter((row) => {
      /*
        Show rows that include at least one of the selected meal tags and don't include
        any of the allergens set by the restrictions
      */
      const containsMealOption = row.tags.some((tag) => mealOptions.includes(tag));
      const containsAllergen = row.allergenTags.some((tag) => excludedAllergens.includes(tag));

      if (containsMealOption && !containsAllergen) {
        // If macro filters were selected, check if recipe is between the selected ranges
        if (macroFilters) {
          const {
            proteinPercentage,
            carbsPercentage,
            fatPercentage,
          } = row;
          const {
            proteinRange,
            carbsRange,
            fatRange,
          } = macroFilters;

          return (
            isWithinRange(proteinPercentage, proteinRange)
            && isWithinRange(carbsPercentage, carbsRange)
            && isWithinRange(fatPercentage, fatRange)
          );
        }
        return true;
      }
      return false;
    })
  ), [
    quickSearchRows,
    mealOptions,
    excludedAllergens,
    macroFilters,
  ]);

  useEffect(() => {
    if (macroFilters) {
      // Log macro filters usage analytics to Segment
      logEvent('recipeMacroFiltersUsed', {
        ...macroFilters,
        numberOfResults: filteredRows.length,
        mealOptionsFilter: mealOptions,
        excludedAllergens,
      });
    }
  }, [
    filteredRows.length,
    macroFilters,
    mealOptions,
    excludedAllergens,
    logEvent,
  ]);

  const filterTools = [
    {
      Component: StyledFilter,
      id: 'meal-type-filter',
      props: {
        options: mealOptionValues,
        initialValues: mealOptions,
        description: texts.mealFilter,
        onValuesSelected: setMealOptions,
        className: configs.classNames.filter,
        filterIcon: <StyledClockIcon />,
        disableValueRender: true,
        setResetFunction: setMealTimesFilterResetFn,
      },
    }, {
      Component: StyledFilter,
      id: 'restriction-filter',
      props: {
        options: restrictionOptionValues,
        initialValues: restrictionOptions,
        description: texts.restrictionFilter,
        onValuesSelected: handleRestrictionFilter,
        className: configs.classNames.filter,
        filterIcon: <StyledRestrictionIcon />,
        disableValueRender: true,
        setResetFunction: setRestrictionsFilterResetFn,
      },
    }, {
      Component: AdvancedFiltersButton,
      id: 'advanced-filters-button',
      props: {
        startIcon: <FilterIcon />,
        onClick: () => setOpenMacroFiltersModal(true),
        children: texts.dietMacroRange,
      },
    },
  ];
  if (selectedRecipes.length > 0) {
    filterTools.push({
      Component: bulkActionComponent,
      id: 'bulk-action-button',
      props: {
        data: selectedRecipes,
        ...bulkActionComponentProps,
      },
    });
  }

  return (
    <DataGridContainer>
      <GenericDataGrid
        rows={filteredRows}
        columnVisibilityModel={{
          // Hide column with checkbox for view where it is not needed
          checkbox: !!bulkActionsEnabled,
        }}
        columns={columns}
        rowHeight={60}
        pagination
        pageSize={20}
        rowsPerPageOptions={[20]}
        disableSelectionOnClick
        components={{
          Toolbar: RecipesTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            macroFilters,
            mealFilters: {
              mealOptionValues,
              mealOptions,
            },
            restrictionFilters: {
              restrictionOptionValues,
              restrictionOptions,
            },
            onClearMacroFilters: () => setMacroFilters(null),
            onClearMealFilters: handleClearMealFilters,
            onClearRestrictionFilters: handleClearRestrictionFilters,
            placeholder: texts.toolbarPlaceholder,
            filterTools,
          },
        }}
      />
      <MacroFiltersModal
        open={openMacroFiltersModal}
        onClose={() => setOpenMacroFiltersModal(false)}
        onConfirm={setMacroFilters}
      />
    </DataGridContainer>
  );
};

RecipesTable.propTypes = {
  rows: PropTypes.array.isRequired,
  ActionsCellComponent: PropTypes.elementType.isRequired,
  extraActionsCellProps: PropTypes.object,
  bulkActionComponent: PropTypes.elementType,
  bulkActionComponentProps: PropTypes.object,
  actionHeader: PropTypes.string.isRequired,
  actionCellWidth: PropTypes.number.isRequired,
  bulkActionsEnabled: PropTypes.bool,
};

RecipesTable.defaultProps = {
  extraActionsCellProps: {},
  bulkActionComponent: null,
  bulkActionComponentProps: {},
  bulkActionsEnabled: false,
};

export default compose(
  observer,
)(RecipesTable);
