import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';

import { AddButton } from '../../../../../../../components/Button/ActionButtons';

import texts from './texts.json';

const AddRecipeAction = ({
  data,
  addRecipe,
  mealTimes,
  selectedBucketId,
  variant,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AddButton
        variant={variant}
        onClick={selectedBucketId ? () => addRecipe(selectedBucketId, data) : handleMenuOpen}
      >
        {texts.addRecipe}
      </AddButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
      >
        {
          !!mealTimes
          && mealTimes.map((mealTime) => (
            <MenuItem key={mealTime.name} onClick={() => addRecipe(mealTime.name, data)}>{mealTime.name}</MenuItem>
          ))
        }
      </Menu>
    </div>
  );
};

AddRecipeAction.propTypes = {
  data: PropTypes.oneOfType(
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ),
  addRecipe: PropTypes.func.isRequired,
  mealTimes: PropTypes.array.isRequired,
  selectedBucketId: PropTypes.string,
  variant: PropTypes.string,
};
AddRecipeAction.defaultProps = {
  selectedBucketId: null,
  variant: 'muted',
  data: {},
};
export default AddRecipeAction;
