import texts from './texts.json';

const getColumns = ({
  actionsRenderCell,
  restrictionsRenderCell,
  userNameRenderCell,
  caloricGoalRenderCell,
  lastUpdatedRenderCell,
}) => (
  [
    {
      field: 'userName',
      type: 'string',
      headerName: texts.columns.userName,
      quickSearch: true,
      minWidth: 250,
      renderCell: userNameRenderCell,
    },
    {
      field: 'productName',
      headerName: texts.columns.productName,
      flex: 1,
    },
    {
      field: 'caloricGoal',
      headerName: texts.columns.caloricGoal,
      flex: 1,
      renderCell: caloricGoalRenderCell,
    },
    {
      field: 'restrictions',
      type: 'string',
      headerName: texts.columns.restrictions,
      flex: 1,
      renderCell: restrictionsRenderCell,
      minWidth: 185,
    },
    {
      field: 'assignmentName',
      type: 'string',
      headerName: texts.columns.assignmentName,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'approvalDate',
      type: 'string',
      headerName: texts.columns.lastUpdated,
      flex: 1,
      renderCell: lastUpdatedRenderCell,
      minWidth: 120,
    },
    {
      field: 'actions',
      headerName: texts.columns.action,
      renderCell: actionsRenderCell,
      minWidth: 300,
    },
  ]
);

export default getColumns;
