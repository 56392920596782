import { NutritionalGoalsAction } from './utils';

const texts = {
  nutritionalGoals: 'NUTRITIONAL GOALS',
  headers: {
    dailyCalories: 'DAILY CALORIES',
    protein: 'PROTEIN',
    carb: 'CARB',
    fat: 'FAT',
  },
  currentGoals: 'Current goals',
  viewHistory: 'View History',
  recommendedGoals: 'Recommended goals',
  manuallySetGoals: 'Manually set goals',
  manuallySet: 'Manually set: {date}',
  kCal: 'kcal',
  loading: 'Loading...',
  noData: 'No data found for this user',
  emptyCell: '-',
  nutritionalGoalsFooter: 'Nutritional Goals',
  newAssignmentMacros: 'Macros will be based on the selected meal plan',
  button: {
    [NutritionalGoalsAction.USE_RECOMMENDED_GOALS]: 'Use Recommended Goals',
    [NutritionalGoalsAction.SET_MANUAL_GOALS]: 'Update Manual Goals',
    [NutritionalGoalsAction.KEEP_PREVIOUS_GOALS]: 'Keep Previous Goals',
  },
};

export default texts;
