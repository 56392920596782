import { MeasurementUnit } from '../../../../../utils/measurement';

const texts = {
  heightValues: {
    [MeasurementUnit.METRIC]: '{height} cm',
    [MeasurementUnit.IMPERIAL]: '{ft}\'{in}"',
  },
  weightValues: {
    [MeasurementUnit.METRIC]: '{weight} kg',
    [MeasurementUnit.IMPERIAL]: '{weight} lb',
  },
  weight: 'Weight',
  height: 'Height',
  gender: 'Gender',
  age: 'Age',
  online: 'Online',
  offline: 'Offline',
  birthdate: 'Birthday',
  status: 'Status',
  checkInDay: 'Check-in day',
  tagClient: 'Tag Client',
};

export default texts;
