import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import { useRouteMatch } from 'react-router-dom';

import useComponentMounted from '../../../hooks/useComponentMounted';
import Coach from '../../../Model/Coach';
import InternalAssignment from '../../../Model/InternalAssignment';
import CoachesListContext, { initialValues } from './CoachesListContext';

const CoachesListContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [coachesCollection, setCoachesCollection] = useState(initialValues.coachesCollection);
  const [coachesByRole, setCoachesByRole] = useState(initialValues.coachesByRole);

  const {
    params: {
      userId,
    },
  } = useRouteMatch();
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const coachesCol = await Coach.getAllExternalCoaches();

      const assignmentsCollection = await InternalAssignment.getAllCurrentAssignments();

      const idsProcessed = {};
      // Process the assignments and build the mapping for the coaches we have in this list.
      const coachesByRoleMap = assignmentsCollection.docs.reduce((acc, assignment) => {
        const {
          coach,
          role,
          userId: assignee,
        } = assignment;
        const coachDoc = coachesCol.docs.find((coachDocument) => coachDocument.id === coach);

        /*
          We might have assignments for coaches that are no longer onboarded. In that case, we report the issue and
          continue processing the rest of the assignments.
        */
        if (!coachDoc) {
          Sentry.captureException(new Error(`Coach ${coach} has a stale assignment.`));
          return acc;
        }

        if (!acc[role]) {
          acc[role] = [];
        }

        if (!idsProcessed[role]) {
          idsProcessed[role] = {};
        }

        /*
          Check that we haven't already added this coach to the list. If we did, we priorityze the one assigned to the
          current user, if available.
        */
        const processedId = idsProcessed[role][coachDoc.id];
        if (!processedId) {
          idsProcessed[role][coachDoc.id] = assignee;
          acc[role].push({
            assignee,
            coachDoc,
          });
        } else if (processedId !== userId && assignee === userId) {
          // Update the assignee for the coach that was already added to the list.
          acc[role].find((coachItem) => coachItem.assignee === processedId).assignee = assignee;
          idsProcessed[role][coachDoc.id] = assignee;
        }
        return acc;
      }, {});

      if (isComponentMountedRef.current) {
        setCoachesCollection(coachesCol);
        setCoachesByRole(coachesByRoleMap);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isComponentMountedRef,
    isReady,
    userId,
  ]);

  const context = useMemo(() => ({
    coachesCollection,
    coachesByRole,
    isReady,
  }), [
    coachesCollection,
    coachesByRole,
    isReady,
  ]);

  return (
    <CoachesListContext.Provider value={context}>
      {children}
    </CoachesListContext.Provider>
  );
};

CoachesListContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(CoachesListContextProvider);
