import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import moment from 'moment';
import ExternalCoachContext from '../ExternalCoachContext';
import NavigationContext, {
  NavigationRouteType,
} from '../NavigationContext';
import UserContext from '../../../context/UserContext';
import useComponentMounted from '../../../hooks/useComponentMounted';
import Recipe from '../../Model/Recipe';
import { RefreshInterval } from '../../Model/MealPlanAssignment';
import ClientMealAssignmentView from '../../Model/ClientMealAssignmentView';
import {
  DaysForRefreshInterval,
  DEFAULT_MEAL_REFRESH_DAYS,
  MealPlanAssignmentStatus,
} from '../../utils/mealPlan';
import MealPlanView from '../../Model/MealPlanView';
import MealPlanContext, { initialValues } from './MealPlanContext';

const MealPlanContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [isLoading, setIsLoading] = useState(initialValues.isLoading);
  const [publicRecipesCollection, setPublicRecipesCollection] = useState(initialValues.publicRecipesCollection);
  const [coachRecipesCollection, setCoachRecipesCollection] = useState(initialValues.coachRecipesCollection);
  const [recipesCollection, setRecipesCollection] = useState(initialValues.recipesCollection);
  const [recipesDocs, setRecipesDocs] = useState(initialValues.recipesDocs);
  const [mealPlanAssignments, setMealPlanAssignments] = useState(initialValues.mealPlanAssignments);
  const [assignmentViewsCollection, setAssignmentViewsCollection] = useState(initialValues.assignmentViewsCollection);
  const [mealPlanViewsCollection, setMealPlanViewsCollection] = useState(initialValues.mealPlanViewsCollection);
  const [mealPlansStatusCount, setMealPlansStatusCount] = useState(initialValues.mealPlansStatusCount);
  const [showArchivedMealPlans, setShowArchivedMealPlans] = useState(initialValues.showArchivedMealPlans);
  const [mealPlanConfig, setMealPlanConfig] = useState(initialValues.mealPlanConfig);
  const [areCollectionsReady, setAreCollectionsReady] = useState(false);

  // TODO: Move this to a separate context. Maybe MealPlanEditorContext, or to the container component directly.
  const [selectedRecipe, setSelectedRecipe] = useState(initialValues.selectedRecipe);

  const {
    isAdminGroupRoute,
    routeType,
  } = useContext(NavigationContext);

  const {
    userId: coachId,
    userDoc: coachUserDoc,
  } = useContext(UserContext);

  const defaultMealPlan = coachUserDoc?.defaultMealPlan;

  const {
    coachDoc = {},
  } = useContext(ExternalCoachContext);

  useEffect(() => {
    const disposer = autorun(() => {
      if (coachDoc) {
        setMealPlanConfig(coachDoc?.mealPlanConfig);
      }
    });
    return disposer;
  }, [coachDoc]);

  const isComponentMountedRef = useComponentMounted();

  // Init states that won't prevent the context to be ready
  useEffect(() => {
    const init = async () => {
      let recipes;
      let publicRecipes;
      let coachRecipes;
      switch (routeType) {
        case NavigationRouteType.SUPPORT:
          recipes = await Recipe.getAllRecipes();
          break;
        case NavigationRouteType.COACH:
        default:
          // Retrieve the two collections here so that we can observe changes on them.
          publicRecipes = await Recipe.getPublicRecipes();
          coachRecipes = await Recipe.getRecipesByCreator(coachId);
          break;
      }

      if (isComponentMountedRef.current) {
        if (publicRecipes && coachRecipes) {
          setPublicRecipesCollection(publicRecipes);
          setCoachRecipesCollection(coachRecipes);
        } else {
          setRecipesCollection(recipes);
        }
      }
    };

    init();
  }, [
    isComponentMountedRef,
    routeType,
    coachId,
  ]);

  useEffect(() => (
    autorun(() => {
      if (publicRecipesCollection && coachRecipesCollection) {
        const recipes = [...publicRecipesCollection.docs];

        // Filter deleted recipes
        const filteredCoachRecipes = coachRecipesCollection.docs.filter(({ deleted }) => !deleted);
        const filteredRecipes = recipes.filter(({ deleted }) => !deleted);

        // Replace the edited recipes. Only keep the edited one (hide the original public recipe)
        filteredCoachRecipes.forEach((coachRecipe) => {
          const index = filteredRecipes.findIndex((publicRecipe) => publicRecipe.id === coachRecipe.originalRecipe);

          // If we found the original recipe, remove it from the list.
          if (index >= 0) {
            filteredRecipes.splice(index, 1);
          }
        });

        // Since this is not an actual collection, we need to run it inside an autorun block.
        setRecipesDocs([...filteredCoachRecipes, ...filteredRecipes]);
      } else if (recipesCollection) {
        // Filter deleted recipes
        const recipes = [...recipesCollection.docs];
        const filteredRecipes = recipes.filter(({ deleted }) => !deleted);
        setRecipesDocs([...filteredRecipes]);
      }
    })
  ), [
    coachRecipesCollection,
    publicRecipesCollection,
    recipesCollection,
  ]);

  useEffect(() => {
    const init = async () => {
      const [
        mealPlanViewsCol,
        assignmentViewsCol,
      ] = await Promise.all([
        MealPlanView.getMealPlanViewsByCoach(coachId),
        ClientMealAssignmentView.getActiveClientMealAssignmentViewsByCoach(coachId),
      ]);

      if (isComponentMountedRef.current) {
        setMealPlanViewsCollection(mealPlanViewsCol);
        setAssignmentViewsCollection(assignmentViewsCol);
        setAreCollectionsReady(true);
      }
    };

    if (!areCollectionsReady && !isAdminGroupRoute) {
      init();
    }
  }, [
    areCollectionsReady,
    coachId,
    isComponentMountedRef,
    isAdminGroupRoute,
  ]);

  // Function to determine the status of the meal plan assignment
  const getAssignmentStatus = useCallback((assignmentDoc) => {
    const {
      lastUpdated,
      assignmentId,
      totalDailyCalories,
      breakEndDate,
      serviceStartAt,
    } = assignmentDoc;

    // If the user is on break we will not add any action notification
    if (!!breakEndDate && moment(breakEndDate.toDate()).isAfter(moment())
      && (!serviceStartAt || moment(serviceStartAt.toDate()).isBefore(moment()))) {
      return MealPlanAssignmentStatus.USER_ON_BREAK;
    }

    // If no meal plan is present and we have a caloric goal, a new assignment is required
    if (!assignmentId && !!totalDailyCalories) {
      return MealPlanAssignmentStatus.PENDING;
    }

    // If the refresh interval is set to never, we don't need to have a notification
    if (!!assignmentId && mealPlanConfig?.refreshInterval === RefreshInterval.NEVER) {
      return MealPlanAssignmentStatus.MANUALLY_REFRESHABLE;
    }

    // If the assignment has expired beyond the refresh interval time, a refresh is required
    if (!!lastUpdated
      && moment.utc().isAfter(
        moment.utc(lastUpdated.toDate()).add(
          DaysForRefreshInterval[mealPlanConfig?.refreshInterval] || DEFAULT_MEAL_REFRESH_DAYS,
          'days',
        ),
      )
    ) {
      return MealPlanAssignmentStatus.NEEDS_REFRESH;
    }

    return MealPlanAssignmentStatus.ACTIVE;
  }, [mealPlanConfig]);

  /*
    Gets an array of assignments and sets their status based on the refresh interval setting.
    Returns a new array of assignments with updated status.
  */
  const getAssignmentsWithStatus = useCallback((assignments) => {
    // This will hold the info about the users that don't have an assignment.
    const usersWithoutAssignment = [];
    // This is the list of assignments that are already in the DB.
    const usersWithAssignment = [];

    assignments.forEach((assignmentDoc) => {
      const updatedAssignmentDoc = assignmentDoc.data ? assignmentDoc.data : assignmentDoc;
      updatedAssignmentDoc.status = getAssignmentStatus(updatedAssignmentDoc);

      const targetArray = updatedAssignmentDoc.mealPlanId ? usersWithAssignment : usersWithoutAssignment;
      targetArray.push({ id: assignmentDoc.id, ...updatedAssignmentDoc });
    });

    return [
      ...usersWithoutAssignment,
      ...usersWithAssignment,
    ];
  }, [getAssignmentStatus]);

  // Init States that need to be ready for the context to be ready
  useEffect(() => {
    const init = () => {
      autorun(() => {
        // Updates meal plan assignments status based on meal plan refresh interval
        const assignmentsInfo = getAssignmentsWithStatus(assignmentViewsCollection.docs);

        if (isComponentMountedRef.current) {
          setMealPlanAssignments(assignmentsInfo);
          setIsReady(true);
        }
      });
    };
    // Skip collection initialization if it's a support user
    if (isAdminGroupRoute) {
      setIsReady(true);
    } else if (!isReady && areCollectionsReady) {
      init();
    }
  }, [
    isReady,
    isComponentMountedRef,
    areCollectionsReady,
    isAdminGroupRoute,
    assignmentViewsCollection,
    getAssignmentsWithStatus,
  ]);

  // This useEffect updates assignment statuses when the refresh interval setting changes
  useEffect(() => {
    // Assignment collection will not have been initialized if it's a support user
    if (isReady && !isAdminGroupRoute) {
      setMealPlanAssignments((currentAssignments) => (
        getAssignmentsWithStatus(currentAssignments)
      ));
    }
  }, [
    isReady,
    isAdminGroupRoute,
    mealPlanConfig,
    getAssignmentsWithStatus,
  ]);

  // Update the meal plan status count
  useEffect(() => {
    if (mealPlanAssignments?.length) {
      const assignmentHighlights = { ...initialValues.mealPlansStatusCount };

      mealPlanAssignments.forEach((assignmentDoc) => {
        const { status } = assignmentDoc;

        // Only update assignmentHighlights if the status is defined
        if (status) {
          assignmentHighlights[status] += 1;
        }
      });

      setMealPlansStatusCount(assignmentHighlights);
    }
  }, [mealPlanAssignments]);

  const saveDefaultMealPlan = useCallback((newMealPlanId) => (
    coachUserDoc.updateFields({
      defaultMealPlan: newMealPlanId,
    })
  ), [coachUserDoc]);

  const saveMealPlanConfig = useCallback((config) => {
    const newConfigValue = {
      ...mealPlanConfig,
      ...config,
    };
    coachDoc.updateFields({
      mealPlanConfig: newConfigValue,
    });
  }, [
    mealPlanConfig,
    coachDoc,
  ]);

  const refetchMealPlanViews = useCallback(async (fetchArchivedPlans = false) => {
    setIsLoading(true);
    setShowArchivedMealPlans(fetchArchivedPlans);
    const mealPlanViews = await MealPlanView.getMealPlanViewsByCoach(coachId, fetchArchivedPlans);
    if (isComponentMountedRef.current) {
      setMealPlanViewsCollection(mealPlanViews);
      setIsLoading(false);
    }
  }, [
    coachId,
    isComponentMountedRef,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    isLoading,
    recipesDocs,
    selectedRecipe,
    onSelectRecipe: setSelectedRecipe,
    mealPlanAssignments,
    mealPlanViewsCollection,
    defaultMealPlan,
    mealPlanConfig,
    mealPlansStatusCount,
    saveMealPlanConfig,
    saveDefaultMealPlan,
    refetchMealPlanViews,
    showArchivedMealPlans,
  }), [
    isReady,
    isLoading,
    recipesDocs,
    selectedRecipe,
    mealPlanAssignments,
    mealPlanViewsCollection,
    defaultMealPlan,
    mealPlanConfig,
    mealPlansStatusCount,
    saveMealPlanConfig,
    saveDefaultMealPlan,
    refetchMealPlanViews,
    showArchivedMealPlans,
  ]);

  return (
    <MealPlanContext.Provider value={contextValue}>
      {children}
    </MealPlanContext.Provider>
  );
};

MealPlanContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(MealPlanContextProvider);
